import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const scrollNavStyles = css`
    position: fixed; // sticky does not work with IE11
    top: 6em;
    padding-top: 8.5em;

    @media (max-width: 991px) {
        padding-top: 11.5em;
        
        span.name {
            display: none;
        }
    }

    .nav-item {
        padding-bottom: 0.5em;

        &:hover {
            cursor: pointer;
        }
    }

    .name {
        display: inline-block;
        padding-left: 0.5em;
        width: 13em;
        vertical-align: middle;
    }

    .indicator {
        height: 12px;
        width: 12px;
        background-color: ${theme['$color-white']};
        border: 1px solid ${theme['$color-black']};
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
    }

    .active {
        .name {
            font-weight: 600;
        }

        .indicator {
            background-color: ${theme['$color-black']};
        }
    }
`;

export default scrollNavStyles;
