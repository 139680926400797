import { css } from '@emotion/core';

export const investmentOverviewStyles = css`
    .account-total {
        display: flex;
        justify-content: flex-end;
        break-after: avoid;

        .amount, .percent {
            text-align: center;
            width: 18%;
        }
    }

    .section-title {
        margin-bottom: 0.4em;
    }

    .table-container {
        table {
            margin-bottom: 1em;
            break-after: avoid;
            break-before: avoid;
        }
    }

    .tc-extra-spacing{
        margin: 2em 0 3em 0;
    }

    .annotations {
        margin-top: 1.5em;

        p {
            margin-top: 0.5em;
            margin-bottom: 0;
        }
    }
    .bold.total{
        text-align: right;
    }
`;

export default investmentOverviewStyles;
