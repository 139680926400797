// eslint-disable-next-line max-len
// https://stackoverflow.com/questions/52627559/in-field-from-react-final-form-how-to-convert-input-value-to-float-only-on-blur

// TODO: convert to typescript

import React from 'react';

export default class StatefulInput extends React.PureComponent {
    state = {
        value: this.props.initialValue,
        editing: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.input.value !== prevState.value && !prevState.editing) {
            return { value: nextProps.input.value };
        }

        return null;
    }

    handleChange = e => {
        this.setState({
            value: e.target.value,
            editing: true,
        });
    };

    handleBlur = e => {
        this.setState({ editing: false });

        if (this.props.input.value !== this.state.value) {
            this.props.input.onChange(this.state.value);
        }

        this.props.input.onBlur(e);
    };

    render() {
        return (
            <input
                id={this.props.id}
                {...this.props.input}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={this.state.value}
            />
        );
    }
}
