import Resource, * as api from '../api/api';
import * as repr from '../api/types';
import makeRequest, { RestSideEffect } from './utils';
import { Action, ActionWithPayload, ActionWithError } from './Action';

export const FETCH_USER = 'FETCH_USER';
export type FETCH_USER = typeof FETCH_USER;

export const RECEIVE_USER = 'RECEIVE_USER';
export type RECEIVE_USER = typeof RECEIVE_USER;

export const FETCH_USER_FAILED = 'FETCH_USER_FAILED';
export type FETCH_USER_FAILED = typeof FETCH_USER_FAILED;

type FetchUserAction = Action<FETCH_USER>;
type ReceiveUserAction = ActionWithPayload<RECEIVE_USER, Resource<repr.User>>;
type FetchUserFailedAction = ActionWithError<FETCH_USER_FAILED, Error>;

export type UserActionTypes =
    | FetchUserAction
    | ReceiveUserAction
    | FetchUserFailedAction

export function fetchCurrentUser(): RestSideEffect<repr.User> {
    return (
        dispatch => dispatch(
            makeRequest(
                FETCH_USER,
                RECEIVE_USER,
                FETCH_USER_FAILED,
                ({ authToken }) => api.getCurrentUser(authToken)
            )
        )
    );
}
