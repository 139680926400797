export const baseFormats = {
    // formatting dates guide:
    // (make sure version selected is the same one installed)
    // https://date-fns.org/v2.0.1/docs/format

    dateLong: {
        type: 'date',
        dateFormat: 'MMMM d, yyyy',
    },
    dateLongWithTime: {
        type: 'date',
        dateFormat: 'MMMM d, yyyy - p',
    },
    cur: {
        type: 'currency',
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
    curMax2: {
        type: 'currency',
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    },
    curMax0: {
        type: 'currency',
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
    dec: {
        type: 'decimal',
        style: 'decimal',
    },
    decMax1: {
        // todo: fix misleading names, not max 1 but only 1
        type: 'decimal',
        style: 'decimal',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    },
    decMax2: {
        type: 'decimal',
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    },
    pctMax1: {
        type: 'percent',
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    },
    pctMax2: {
        type: 'percent',
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    },
    pct3: {
        type: 'percent',
        style: 'percent',
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
    },
};

export const frenchFormats = {
    ...baseFormats,
    dateLong: {
        type: 'date',
        dateFormat: 'd MMMM yyyy',
    },
    dateLongWithTime: {
        type: 'date',
        dateFormat: 'd MMMM yyyy - p',
    },
    cur: {
        type: 'currency',
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
    curMax2: {
        type: 'currency',
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    },
    curMax0: {
        type: 'currency',
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
    dec: {
        type: 'decimal',
        style: 'decimal',
    },
};
