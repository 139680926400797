import { produce } from 'immer';
import { ProposalActionTypes, RECEIVE_PROPOSALS, RECEIVE_PROPOSAL } from '../actions/proposals';
import { FundActionTypes, RECEIVE_FUNDS, RECEIVE_ETF_FUNDS, RECEIVE_GIA_FUNDS } from '../actions/funds';
import { ReportActionTypes, RECEIVE_REPORT, CLEAR_REPORT } from '../actions/reports';
import { UserActionTypes, RECEIVE_USER } from '../actions/users';
import {
    AppActionTypes,
    SET_INVESTMENT_TYPE_TO_MMF,
    SET_INVESTMENT_TYPE_TO_SEG
} from '../actions/app';
import * as repr from '../api/types';

export interface StateShape {
    proposals: repr.ProposalBare[]
    proposal: repr.ProposalDetailed | null
    funds: repr.Fund[]
    etfFunds: repr.Fund[]
    giaFunds: repr.Fund[]
    report: repr.Report | null
    investmentType: string
    currentUser: repr.User | null
}

const defaultState = {
    proposals: [],
    proposal: null,
    funds: [],
    etfFunds: [],
    giaFunds: [],
    report: null,
    investmentType: 'MMF',
    currentUser: null
};

type Action =
    | ProposalActionTypes
    | FundActionTypes
    | ReportActionTypes
    | AppActionTypes
    | UserActionTypes;

export function appReducer(state: StateShape = defaultState, action: Action) {
    switch (action.type) {
        case RECEIVE_PROPOSALS:
            return produce(state, draft => {
                draft.proposals = action.payload.data.proposals;
            });
        case RECEIVE_PROPOSAL:
            return produce(state, draft => {
                draft.proposal = action.payload.data;
            });
        case CLEAR_REPORT:
            return produce(state, draft => {
                draft.report = null;
            })
        case RECEIVE_FUNDS:
            return produce(state, draft => {
                draft.funds = action.payload.data.funds;
            });
        case RECEIVE_ETF_FUNDS:
                return produce(state, draft => {
                    draft.etfFunds = action.payload.data.funds;
            });    
        case RECEIVE_GIA_FUNDS:
            return produce(state, draft => {
                draft.giaFunds = action.payload.data.funds;
            });
        case RECEIVE_REPORT:
            return produce(state, draft => {
                draft.report = action.payload.data;
            });
        case SET_INVESTMENT_TYPE_TO_MMF:
            return produce(state, draft => {
                draft.investmentType = 'MMF';
            });
        case SET_INVESTMENT_TYPE_TO_SEG:
            return produce(state, draft => {
                draft.investmentType = 'Seg';
            });
        case RECEIVE_USER:
            return produce(state, draft => {
                draft.currentUser = action.payload.data;
            });
    }

    return state;
}

export default appReducer;
