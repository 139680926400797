import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const benefitsStyles = css`
    p {
        margin-bottom: 1em;
        line-height: 1.2;
    }

    .statistics-container {
        font-size: ${theme['$font-size-small']};
        border: 1px solid ${theme['$color-grey']};
        margin-top: 1.5em;
        margin-bottom: 1.5em;

        .stats-row {
            display: flex;
        }

        .statistic {
            padding: 1.5em 1em;
            width: 50%;

            p {
                margin-bottom: 0.25em;
                line-height: 1.4;
            }

            .secondary {
                font-weight: 600;
            }
        }

        .statistic + .statistic {
            border-left: 1px solid ${theme['$color-grey']};
        }
    }
`;

export default benefitsStyles;
