/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import _ from 'lodash';
import * as H from 'history';
import { connect } from 'react-redux';
import { I18n } from 'react-i18nify';
import * as repr from '../../api/types';
import { fetchProposals, saveProposal, deleteProposal } from '../../actions/proposals';
import { setInvestmentType } from '../../actions/app';
import { StateShape as ReduxStateShape } from '../../reducers';
import t from '../../localization/i18n';
import { ThunkDispatch } from '../../actions/utils';
import Button from '../Button';
import ClientDetailsForm from './ClientDetailsForm';
import ClientTableRow from './ClientTableRow';
import clientListStyles from './ClientList.styles';

interface PropsShape {
    dispatch: ThunkDispatch;
    history: H.History
    proposals: repr.ProposalBare[];
    investmentType: repr.investmentTypes
}

interface StateShape {
    isCreateClientModalVisible: boolean
    searchPhrase: string
    sortedBy: {
        columnName?: string
        isAscending?: boolean
    }
}

class ClientList extends Component<PropsShape, StateShape> {
    state: StateShape = {
        isCreateClientModalVisible: false,
        searchPhrase: '',
        sortedBy: {},
    };

    componentDidMount() {
        const { investmentType } = this.props;
        this.props.dispatch(fetchProposals(investmentType));
    }

    componentDidUpdate(prevProps: PropsShape) {
        const { investmentType } = this.props;

        if (prevProps.investmentType !== investmentType) {
            this.props.dispatch(fetchProposals(investmentType));
        }
    }

    // filter the proposal list - only return proposals that contain the search term
    private filterBySearchValue = (
        proposals: repr.ProposalBare[],
        searchTerm: string
    ) => _.filter(proposals, p => { // go throught every proposal
        let proposalContainsTerm: boolean;

        // check every top level key in the proposal
        // if search term is in any of the top level key values, set flag to true
        proposalContainsTerm = _.some(p,
            v => !!(typeof v === 'string' &&
                v.toLowerCase().includes(searchTerm.toLowerCase())),
        );

        // if no top level value includes the search term, then check client summaries
        if (!proposalContainsTerm && p.clientSummaries) {
            // similar to what was done for the top level keys
            proposalContainsTerm = _.some(p.clientSummaries, c => _.some(
                c, v => typeof v === 'string' &&
                    v.toLowerCase().includes(searchTerm.toLowerCase()),
            ));
        }

        return proposalContainsTerm;
    })

    private updateSortState = (columnName: string) => {
        const { sortedBy } = this.state;
        let sortingProperties = {};

        if (!sortedBy.columnName || sortedBy.columnName !== columnName) {
            sortingProperties = { columnName, isAscending: true };
        } else if (sortedBy.isAscending) {
            sortingProperties = { columnName, isAscending: false };
        }

        this.setState({ sortedBy: sortingProperties });
    };

    private sortByColumn = (proposals: repr.ProposalBare[]) => {
        const { sortedBy } = this.state;
        const { columnName, isAscending } = sortedBy;

        // helper function for sorting by name
        const getProposalNameForComparison = (proposal: repr.ProposalBare) => {
            if (proposal.clientSummaries.length > 1) {
                return proposal.proposalName;
            } else {
                return proposal.clientSummaries[0].lastName + proposal.clientSummaries[0].firstName;
            }
        };

        let sortedProposals = [...proposals];

        switch (columnName) {
            case 'name': {
                sortedProposals = sortedProposals.sort((a, b) => {
                    const aName = getProposalNameForComparison(a);
                    const bName = getProposalNameForComparison(b);

                    return aName.toLowerCase() > bName.toLowerCase() ? 1 : -1;
                });
                break;
            }
            case 'advisorName': {
                sortedProposals = sortedProposals.sort((a, b) => {
                    const aAdvisorName = a.advisorLastName + a.advisorFirstName;
                    const bAdvisorName = b.advisorLastName + b.advisorFirstName;

                    return aAdvisorName.toLowerCase() > bAdvisorName.toLowerCase() ? 1 : -1;
                });
                break;
            }
            case 'lastModified': {
                sortedProposals = sortedProposals.sort((a, b) => {
                    const aDateModified = new Date(a.dateModified);
                    const bDateModified = new Date(b.dateModified);

                    return aDateModified > bDateModified ? 1 : -1;
                });
                break;
            }
            case 'assets': {
                sortedProposals = sortedProposals.sort((a, b) => a.amount > b.amount ? 1 : -1);
                break;
            }
            default:
                break;
        }

        if (isAscending === false) {
            sortedProposals = sortedProposals.reverse();
        }

        return sortedProposals;
    };

    private get tableHeaders() {
        const { sortedBy } = this.state;

        const headers = [
            { name: '', isSortable: false }, // column for the collapse arrow icons for households
            { name: 'name', isSortable: true },
            { name: 'advisorName', isSortable: true },
            { name: 'lastModified', isSortable: true },
            { name: 'assets', isSortable: true },
            { name: 'actions', isSortable: false },
        ];

        const sortIcons = {
            // the alt text is the sorting that will take place when you click the button
            sort: {
                name: 'arrow-sort-white',
                alt: 'clientList.alt.sortAscending',
            },
            sortUp: {
                name: 'arrow-up-white',
                alt: 'clientList.alt.sortDescending',
            },
            sortDown: {
                name: 'arrow-down-white',
                alt: 'clientList.alt.sortClear',
            },
        };

        return headers.map((header, index) => {
            if (!header.name) {
                return (<td className='empty-header' key={`empty-header-${index}`}/>);
            }

            const columnName = header.name;
            let sortIconType = sortIcons.sort;

            if (sortedBy.columnName === columnName) {
                sortIconType = sortedBy.isAscending ? sortIcons.sortUp : sortIcons.sortDown;
            }

            const sortLabel =
                t({
                    key: sortIconType.alt,
                    values: { property: t(`clientList.tableHeaders.${header.name}`) }
                });

            const sortButton = (
                <Button
                    variant="icon"
                    onClick={() => this.updateSortState(columnName)}
                    tooltip={{ id: `toolTip--${index}`, text: sortLabel }}
                >
                    <span className={`sort-icon icon--${sortIconType.name}`} aria-hidden="true"/>
                    <span className="sr-only">
                        {sortLabel}
                    </span>
                </Button>
            );

            return (
                <th key={header.name}>
                    <span>{t(`clientList.tableHeaders.${header.name}`)}</span>
                    {header.isSortable && sortButton}
                </th>
            );
        });
    }

    private get tableRows() {
        let { proposals: visibleProposals, dispatch, history } = this.props;
        const { searchPhrase, sortedBy } = this.state;

        if (searchPhrase) { // filter proposals
            visibleProposals = this.filterBySearchValue(visibleProposals, searchPhrase);
        }

        if (sortedBy.columnName) { // sort proposals
            visibleProposals = this.sortByColumn(visibleProposals);
        }

        // todo: add a check to see if its still fetching/loading
        if (visibleProposals.length < 1) {
            return (<tr><td colSpan={6}>{t('clientList.noClientsFound')}</td></tr>);
        }

        return (
            visibleProposals.map(p => p.clientSummaries.length === 0
                ? null
                : (
                    <ClientTableRow
                        dispatch={dispatch}
                        history={history}
                        proposal={p}
                        key={p.id}
                        deleteProposal={this.deleteProposal}
                        saveProposal={this.saveProposal}
                    />
                ))
        );
    }

    private get pageHeading() {
        const { investmentType } = this.props;

        return (
            <h1 className='title'>
                {`${t('clientList.heading')} - `}
                <span className="bold">{t(`clientList.${investmentType.toLowerCase()}`)}</span>
            </h1>
        );
    }

    private toggleCreateClientModal = () => {
        const { isCreateClientModalVisible } = this.state;
        this.setState({ isCreateClientModalVisible: !isCreateClientModalVisible });
    }

    private saveProposal = (proposalData: Partial<repr.ProposalDetailed>) => {
        const { dispatch } = this.props;
        const { investmentType } = this.props;

        return dispatch(saveProposal(proposalData))
            .then(() => {
                dispatch(fetchProposals(investmentType));
            });
    };

    private deleteProposal = (proposalId: string) => {
        const { dispatch } = this.props;
        const { investmentType } = this.props;
        dispatch(deleteProposal(proposalId))
            .then(() => {
                dispatch(fetchProposals(investmentType));
            });
    }

    render() {
        const { investmentType, dispatch } = this.props;
        const { isCreateClientModalVisible } = this.state;
        return (
            <I18n
                render={() => (
                    <div css={clientListStyles}>
                        <ClientDetailsForm
                            isVisible={isCreateClientModalVisible}
                            toggleModal={this.toggleCreateClientModal}
                            investmentType={investmentType}
                            saveProposal={this.saveProposal}
                        />

                        {this.pageHeading}
                        <hr />

                        <div className="top-bar">
                            <div className='search-bar-container'>
                                <label htmlFor='search-bar-input' className='sr-only'>
                                    {t('application.search')}
                                </label>
                                <input
                                    id='search-bar-input'
                                    type='text'
                                    className='search-bar'
                                    placeholder={t('clientList.search')}
                                    onChange={
                                        e => this.setState(
                                            { searchPhrase: e.currentTarget.value }
                                        )
                                    }
                                />
                            </div>

                            <div className='top-bar-options'>
                                <fieldset>
                                    <legend className='sr-only'>
                                        {t('application.investmentType')}
                                    </legend>
                                    <input
                                        type='radio'
                                        id='MMF'
                                        name="investmentType"
                                        onChange={() => dispatch(setInvestmentType('MMF'))}
                                        checked={investmentType === 'MMF'}
                                    />
                                    <label htmlFor="MMF">
                                        {t('clientList.mmf')}
                                    </label>

                                    <input
                                        type='radio'
                                        id='Seg'
                                        name="investmentType"
                                        onChange={() => dispatch(setInvestmentType('Seg'))}
                                        checked={investmentType === 'Seg'}
                                    />
                                    <label htmlFor="Seg">
                                        {t('clientList.seg')}
                                    </label>
                                </fieldset>

                                <Button
                                    onClick={
                                        () => this.setState(
                                            { isCreateClientModalVisible: true }
                                        )
                                    }
                                    variant="secondary"
                                >
                                    <span
                                        className="btn-icon icon icon--plus-circle-white"
                                        aria-hidden="true"
                                    />
                                    {t('clientList.newClient')}
                                </Button>
                            </div>
                        </div>

                        <div className="client-list-container">
                            <table className='client-list-table'>
                                <thead>
                                    <tr>
                                        {this.tableHeaders}
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.tableRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            />
        );
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const proposals = state.app.proposals;
    const investmentType = state.app.investmentType as 'Seg' | 'MMF';

    return { proposals, investmentType };
};

export default connect(mapStateToProps)(ClientList);
