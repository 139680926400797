import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch as _ThunkDispatch } from 'redux-thunk';
import { StateShape as ReduxStateShape } from '../reducers';
import Resource from '../api/api';
import authService from '../components/api-authorization/AuthorizeService';
import * as proposalTypes from './proposals';
import * as fundTypes from './funds';
import * as localizationTypes from './localization';
import * as reportTypes from './reports';
import * as userTypes from './users';

type RequestActionType =
    | proposalTypes.FETCH_PROPOSALS
    | proposalTypes.SAVE_PROPOSAL
    | proposalTypes.FETCH_PROPOSAL
    | proposalTypes.DELETE_PROPOSAL
    | fundTypes.FETCH_FUNDS
    | fundTypes.FETCH_ETF_FUNDS
    | fundTypes.FETCH_GIA_FUNDS
    | localizationTypes.SET_LOCALE
    | reportTypes.FETCH_REPORT
    | reportTypes.GENERATE_PDF_REPORT
    | reportTypes.FETCH_REPORT_URL
    | userTypes.FETCH_USER;

type ReceiveActionType =
    | proposalTypes.RECEIVE_PROPOSALS
    | proposalTypes.SAVE_PROPOSAL_SUCCESS
    | proposalTypes.RECEIVE_PROPOSAL
    | proposalTypes.DELETE_PROPOSAL_SUCCESS
    | fundTypes.RECEIVE_FUNDS
    | fundTypes.RECEIVE_ETF_FUNDS
    | fundTypes.RECEIVE_GIA_FUNDS
    | localizationTypes.SET_LOCALE_SUCCESS
    | reportTypes.RECEIVE_REPORT
    | reportTypes.GENERATE_PDF_REPORT_SUCCESS
    | reportTypes.RECEIVE_REPORT_URL
    | userTypes.RECEIVE_USER;

type RequestFailedActionType =
    | proposalTypes.FETCH_PROPOSALS_FAILED
    | proposalTypes.SAVE_PROPOSAL_FAILED
    | proposalTypes.FETCH_PROPOSAL_FAILED
    | proposalTypes.DELETE_PROPOSAL_FAILED
    | fundTypes.FETCH_FUNDS_FAILED
    | fundTypes.FETCH_ETF_FUNDS_FAILED
    | fundTypes.FETCH_GIA_FUNDS_FAILED
    | localizationTypes.SET_LOCALE_FAILED
    | reportTypes.FETCH_REPORT_FAILED
    | reportTypes.GENERATE_PDF_REPORT_FAILED
    | reportTypes.FETCH_REPORT_URL_FAILED
    | userTypes.FETCH_USER_FAILED;

export type ThunkDispatch = _ThunkDispatch<ReduxStateShape, never, AnyAction>;
export type SideEffect<T> = ThunkAction<T, ReduxStateShape, {}, AnyAction>;
export type RestSideEffect<T> = SideEffect<Promise<Resource<T>>>;

export default function makeRequest<R>(
    requestAction: RequestActionType,
    receiveAction: ReceiveActionType,
    requestFailedAction: RequestFailedActionType,
    requestFunction: (
        opts: { authToken: string | null, userId: string | null }
    ) => Promise<Resource<R>>
): SideEffect<Promise<Resource<R>>> {
    return async(dispatch, getState): Promise<Resource<R>> => {
        const authToken = await authService.getAccessToken();
        const user = await authService.getUser();
        const userId = user ? user.sub : null;

        dispatch({ type: requestAction });
        return (
            requestFunction({ authToken, userId })
                .then((r: Resource<R>) => {
                    dispatch({ type: receiveAction, payload: r });
                    return r;
                })
                .catch(e => {
                    dispatch({ type: requestFailedAction, error: e });
                    throw e;
                })
        );
    };
}
