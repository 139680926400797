/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import React from 'react';
import * as repr from '../../../api/types';
import t from '../../../localization/i18n';
import Button from '../../Button';
import { PredictiveSearchComponent } from '../../shared/PredictiveSearchSelect';
import poolSelectorStyles from './PoolSelector.styles';

interface PropsShape {
    client: repr.ClientDetailed
    clientIndex: number
    funds: repr.Fund[]
    etfFunds: repr.Fund[]
    serviceFee: number | null
    changeFormValue: (name: string, value?: any) => void
    investmentType: repr.investmentTypes
    productType: repr.productType
    isFeeBased: boolean
}

interface StateShape {
    poolTypes: { name: string, type: string }[] // series (MMF) or load (Seg) types
    poolTypeSelected: string
    fundId: string
    accountName: string
    amount: number
    percent: number
    successMessage: string,
    selectedValue: {value: string, label: string},
    selectedEtfFund: {value: string, label: string}
    productFunds: repr.Fund[]
}

const MAX_NUMBER_OF_POOLS_PER_ACCOUNT = 15;

export default class PoolSelector extends React.Component<PropsShape, StateShape> {
    state: StateShape = {
        poolTypes: [],
        poolTypeSelected: '',
        fundId: '',
        accountName: '',
        amount: 0,
        percent: 0,
        successMessage: '', 
        selectedValue: {value: '', label: ''},
        selectedEtfFund: {value: '', label: ''},
        productFunds: []
    };

    componentDidUpdate(prevProps: PropsShape, prevState: StateShape) {
        const { funds, client, investmentType, productType } = this.props;
        const { amount, percent, accountName } = this.state;

        if ((prevProps.funds !== funds) || (prevProps.productType !== productType)) {
            let poolTypes = [];
            
            if (investmentType === 'MMF') {
                // MMF: Extract unique className, __seriesClass pairs from fund list
                this.setState({productFunds : funds});
                poolTypes = _.sortBy(_.uniqWith(
                    _.map(funds, fund => ({
                        name: fund.className,
                        type: fund.__seriesClass as string,
                    })),
                    _.isEqual,
                ), obj => obj.name);
            }
            // Remove following code if backend only sends F-class pooltype  
            // else if(productType === 'GIF' && isFeeBased){
            //     poolTypes = [{name: "F-Class", type: "FCL"}]
            // } 
            else {
                // Seg: Extract unique load, __loadType pairs from fund list
                let productCategoryType = "";

                switch (productType) {
                    case 'MPIP75':
                        productCategoryType = 'mpip75';
                        break;
                    case 'MPIP100':
                        productCategoryType = 'mpip100';
                        break;
                    case 'GIF75':
                        productCategoryType = 'gif75';
                        break;
                    case 'GIF100':
                        productCategoryType = 'gif100';
                        break;
                }
                const productFunds =  productType === "MPIP" ? 
                    _.filter(funds, ['productCategoryType', productCategoryType])
                    : _.filter(funds, ['productCategoryType', productCategoryType]);
                
                this.setState({productFunds});   
                poolTypes = _.uniqWith(
                    _.map(productFunds, fund => ({
                        name: fund.load,
                        type: fund.__loadType,
                    })),
                    _.isEqual,
                );
            }

            if (poolTypes.length > 0) {
                this.setState({ poolTypes, poolTypeSelected: poolTypes[0].name || '' });
            } else {
                this.setState({ poolTypes: [], poolTypeSelected: '' });
            }
        }

        const selectedAccount = _.find(client.accounts, { nickname: accountName });

        if (!selectedAccount) {
            return;
        }

        // Must make sure only one changed and not the other to avoid looping
        if (prevState.amount !== amount && prevState.percent === percent) {
            this.setState({ percent: (amount / selectedAccount.amount) * 100 });
        } else if (prevState.percent !== percent && prevState.amount === amount) {
            const roundedAmount = Math.round(selectedAccount.amount * (percent / 100));
            this.setState({ amount: roundedAmount });
        }
    }

    private NaNToZero = (value: number) => isNaN(value) ? 0 : value;

    private addNewPool = (selectedAccount: repr.Account | undefined, accountIndex: number) => {
        const { changeFormValue, client, clientIndex, etfFunds, serviceFee } = this.props;
        const { amount, fundId, percent, productFunds } = this.state;

        // Must select correct client and correct account using respective indicies
        // so react-final-form knows which attribute to update
        const selectedAccountFormName =
            `clients[${clientIndex}].accounts[${accountIndex}].holdings`;

        const oldHoldings = client.accounts[accountIndex].holdings;
        const selectedFund = _.find(productFunds, { _id: fundId });
        const selectedEtfFund = _.find(etfFunds, {_id: fundId})

        if (!selectedAccount || (!selectedFund && !selectedEtfFund)) {
            return;
        }

        let data = oldHoldings;

        const fund = selectedFund ? selectedFund : selectedEtfFund;

        if (!fund) {
            return;
        }
            
        const existingHolding = _.find(oldHoldings, {fundUniqueId: fund._id});
        const holding: repr.Holding = {
            fundUniqueId: fund._id,
            profileId: fund.profileId,
            fundServCode: fund.fundServCode,
            webProfileId: fund.webProfileId,
            fundName: fund.fundName,
            className: fund.className,
            originalMer: fund.mer,
            serviceFee: serviceFee || 0,
            amount,
            percent: percent / 100,
            isGia: false,
            giaType: null,
            giaTerm: null,
            __GiaType: fund.__GiaType,
            holdingProductType: fund.productCategoryType
        }
        if (existingHolding) {
            holding.amount += existingHolding.amount;
            (holding.percent as number) += existingHolding.percent || 0;

            data = [..._.reject(oldHoldings, { fundUniqueId: fund._id }), holding];
        } else {
            data = [...oldHoldings, holding];
        }

        changeFormValue(selectedAccountFormName, data);

        const successMessage =
            t({
                key: 'proposalForm.poolSelector.successMessage',
                values: { name: client.accounts[accountIndex].nickname }
            });

        this.setState({
            accountName: '',
            amount: 0,
            percent: 0,
            successMessage,
            fundId: '',
            selectedValue: {value: '', label: ''},
            selectedEtfFund: {value: '', label: ''}
        });
    };

    private handleChange = (selectedOption: { value: string, label: string }) => {
        this.setState({
            fundId: (selectedOption !== null) ? selectedOption.value : '',
            successMessage: '',
            selectedValue: {
                value: (selectedOption !== null) ? selectedOption.value : '',
                label: (selectedOption !== null) ? selectedOption.label : ''
            }
        });        
    }

    private handleEtfChange = (selectedOption: { value: string, label: string }) => {
        this.setState({
            fundId: (selectedOption !== null) ? selectedOption.value : '',
            successMessage: '',
            selectedEtfFund: {
                value: (selectedOption !== null) ? selectedOption.value : '',
                label: (selectedOption !== null) ? selectedOption.label : ''
            }
        });        
    }

    render() {
        const { client, clientIndex, etfFunds, investmentType } = this.props;
        const {
            accountName,
            fundId,
            poolTypes,
            poolTypeSelected,
            amount,
            percent,
            successMessage,
            selectedValue,
            selectedEtfFund,
            productFunds
        } = this.state;

        // filter funds on className or load
        const filteredFunds = investmentType === 'MMF'
            ? _.filter(productFunds, { className: poolTypeSelected })
            : _.filter(productFunds, { load: poolTypeSelected });
        const fundCode = (fund : repr.Fund) => fund.fundServCode ? fund.fundServCode : fund.webProfileId; 
        const fundNames = filteredFunds.map(f => ({value: f._id, label: `${fundCode(f)} - ${f.fundName}`}));
        const etfFundNames = etfFunds.map(f => ({value: f._id, label: `${fundCode(f)} - ${f.fundName}`}));
        const selectedAccount = _.find(client.accounts, { nickname: accountName });
        const accountIndex = _.indexOf(client.accounts, selectedAccount);

        /* -- Check if account nicknames are unique and all are strings -- */
        // create list of all account nicknames
        const accountNamesList = _.map(client.accounts, 'nickname');

        // create a set using the names array which removes the duplicates
        const accountNamesSet = new Set(accountNamesList);

        const areAccountNamesValid =
            // if the set size is same as account names list, then there are no duplicates
            accountNamesSet.size === accountNamesList.length &&
            // check if account list has some element that is NOT a string
            !accountNamesList.some((name) => typeof name !== 'string');

        const maxNumberOfPoolsReached =
            selectedAccount && selectedAccount.holdings.length >= MAX_NUMBER_OF_POOLS_PER_ACCOUNT;

        const disableAddingPools =
            !accountName ||
            !selectedAccount ||
            !selectedAccount.amount ||
            !areAccountNamesValid ||
            maxNumberOfPoolsReached;

        return (
            <div css={poolSelectorStyles}>
                <h2 className='heading'>{t('proposalForm.poolSelector.heading')}</h2>

                <fieldset>
                    <legend className='sr-only'>
                        {t('proposalForm.poolSelector.heading')}
                    </legend>
                    <div className='input-row'>

                        <div className='label-input-pair load-type'>
                            <label htmlFor={`load-type-selector-${clientIndex}`}>
                                {t('proposalForm.poolSelector.selectLoadType')}
                            </label>
                            <select
                                id={`load-type-selector-${clientIndex}`}
                                value={poolTypeSelected}
                                onChange={v => this.setState({
                                    poolTypeSelected: v.currentTarget.value,
                                    fundId: '',
                                    successMessage: '',
                                })}
                            >
                                {poolTypes.length > 0 ? poolTypes.map(t => (
                                    <option key={t.name} value={t.name}>
                                        {t.name}
                                    </option>
                                )): <option>
                                        No Options
                                    </option>}
                            </select>
                        </div>

                        {investmentType === "MMF" ?
                            (<div className='label-input-pair'>
                                <PredictiveSearchComponent
                                    id={`pool-selector-${clientIndex}`}
                                    options={fundNames}
                                    handleChange={this.handleChange}
                                    labelName={t('proposalForm.poolSelector.mutualFundName')}
                                    selectedValue={selectedValue}
                                />
                                <PredictiveSearchComponent
                                    id={`pool-selector-${clientIndex}`}
                                    options={etfFundNames}
                                    handleChange={this.handleEtfChange}
                                    labelName={t('proposalForm.poolSelector.etfName')}
                                    selectedValue={selectedEtfFund}
                                />
                            </div>) : (
                                <div className='label-input-pair'>
                                    <PredictiveSearchComponent
                                        id={`pool-selector-${clientIndex}`}
                                        options={fundNames}
                                        handleChange={this.handleChange}
                                        labelName={t('proposalForm.poolSelector.segFundName')}
                                        selectedValue={selectedValue}
                                    />
                                </div>
                            )}
                    </div>

                    {/* Select Account */}
                    <div className='input-row'>
                        <div className='label-input-pair'>
                            <label htmlFor={`account-selector-${clientIndex}`}>
                                {t('proposalForm.poolSelector.selectAccount')}
                            </label>
                            <select
                                id={`account-selector-${clientIndex}`}
                                value={accountName}
                                disabled={client.accounts.length === 0 || !areAccountNamesValid}
                                onChange={v => this.setState({
                                    accountName: v.currentTarget.value,
                                    amount: 0,
                                    percent: 0,
                                    successMessage: '',
                                })}
                            >
                                <option value=''>-</option>
                                {client.accounts.map((a, index) => {
                                    const type = a.accountType.toLowerCase();
                                    const accountLabel = t(`proposalForm.accountTypes.${type}`);

                                    return (
                                        <option key={`${index} - ${a.nickname}`} value={a.nickname}>
                                            {`${a.nickname} - ${accountLabel}`}
                                        </option>
                                    );
                                })}
                            </select>
                            {!areAccountNamesValid &&
                            <span className='error-message'>
                                {t('errorMessages.accountNamesInvalidError')}
                            </span>
                            }
                            {maxNumberOfPoolsReached &&
                            <span className='error-message'>
                                {t('errorMessages.maxPoolsError')}
                            </span>
                            }
                        </div>

                        <div className='label-input-pair'>
                            <label htmlFor={`amount-input-${clientIndex}`}>
                                {t('proposalForm.amount')}
                            </label>
                            <input
                                id={`amount-input-${clientIndex}`}
                                type='number'
                                disabled={disableAddingPools}
                                value={amount === 0 ? '' : amount.toFixed(0)}
                                onChange={e => this.setState({
                                    amount: this.NaNToZero(parseInt(
                                        e.currentTarget.value, 10,
                                    )),
                                    successMessage: '',
                                })}
                            />
                        </div>

                        <div className='label-input-pair'>
                            <label htmlFor={`percent-input-${clientIndex}`}>
                                {t('proposalForm.percent')}
                            </label>
                            <input
                                id={`percent-input-${clientIndex}`}
                                type='number'
                                disabled={disableAddingPools}
                                value={percent === 0 ? '' : percent.toFixed(0)}
                                onChange={e => this.setState({
                                    percent: this.NaNToZero(parseInt(
                                        e.currentTarget.value, 10,
                                    )),
                                    successMessage: '',
                                })}
                            />
                        </div>

                        <Button
                            type='button'
                            variant="primary"
                            className='add-pool-button'
                            disabled={!fundId || !amount || disableAddingPools}
                            onClick={() => this.addNewPool(selectedAccount, accountIndex)}
                        >
                            {t('application.add')}
                        </Button>
                    </div>

                    {successMessage && <div className='success-msg'>{successMessage}</div>}
                </fieldset>
            </div>
        );
    }
}
