/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import t from '../../localization/i18n';
import * as repr from '../../api/types';
import importantInfoStyles from './ImportantInfo.styles';
import ImportantInfoText from '../shared/ImportantInfoText';

interface PropsShape {
    report: repr.Report;
}

export default class ImportantInfo extends React.Component<PropsShape> {
    render() {
        const { report } = this.props;

        return (
            <div id="important-info" css={importantInfoStyles} className="section">
                <h2 className="section-title">{t('report.importantInfo.heading')}</h2>

                <ImportantInfoText report={report} /> 
            </div>
        );
    }
}
