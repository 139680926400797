import Resource, * as api from '../api/api';
import * as repr from '../api/types';
import makeRequest, { RestSideEffect } from './utils';
import { Action, ActionWithPayload, ActionWithError } from './Action';

export const FETCH_PROPOSALS = 'FETCH_PROPOSALS';
export type FETCH_PROPOSALS = typeof FETCH_PROPOSALS;

export const RECEIVE_PROPOSALS = 'RECEIVE_PROPOSALS';
export type RECEIVE_PROPOSALS = typeof RECEIVE_PROPOSALS;

export const FETCH_PROPOSALS_FAILED = 'FETCH_PROPOSALS_FAILED';
export type FETCH_PROPOSALS_FAILED = typeof FETCH_PROPOSALS_FAILED;

export const FETCH_PROPOSAL = 'FETCH_PROPOSAL';
export type FETCH_PROPOSAL = typeof FETCH_PROPOSAL;

export const RECEIVE_PROPOSAL = 'RECEIVE_PROPOSAL';
export type RECEIVE_PROPOSAL = typeof RECEIVE_PROPOSAL;

export const FETCH_PROPOSAL_FAILED = 'FETCH_PROPOSAL_FAILED';
export type FETCH_PROPOSAL_FAILED = typeof FETCH_PROPOSAL_FAILED;

export const SAVE_PROPOSAL = 'SAVE_PROPOSAL';
export type SAVE_PROPOSAL = typeof SAVE_PROPOSAL;

export const SAVE_PROPOSAL_SUCCESS = 'SAVE_PROPOSAL_SUCCESS';
export type SAVE_PROPOSAL_SUCCESS = typeof SAVE_PROPOSAL_SUCCESS;

export const SAVE_PROPOSAL_FAILED = 'SAVE_PROPOSAL_FAILED';
export type SAVE_PROPOSAL_FAILED = typeof SAVE_PROPOSAL_FAILED;

export const DELETE_PROPOSAL = 'DELETE_PROPOSAL';
export type DELETE_PROPOSAL = typeof DELETE_PROPOSAL;

export const DELETE_PROPOSAL_SUCCESS = 'DELETE_PROPOSAL_SUCCESS';
export type DELETE_PROPOSAL_SUCCESS = typeof DELETE_PROPOSAL_SUCCESS;

export const DELETE_PROPOSAL_FAILED = 'DELETE_PROPOSAL_FAILED';
export type DELETE_PROPOSAL_FAILED = typeof DELETE_PROPOSAL_FAILED;

type FetchProposalsAction = Action<FETCH_PROPOSALS>;
type ReceiveProposalsAction =
    ActionWithPayload<RECEIVE_PROPOSALS, Resource<repr.ProposalsPayload>>;
type FetchProposalsFailedAction = ActionWithError<FETCH_PROPOSALS_FAILED, Error>;

type FetchProposalAction = Action<FETCH_PROPOSAL>;
type ReceiveProposalAction =
    ActionWithPayload<RECEIVE_PROPOSAL, Resource<repr.ProposalDetailed>>;
type FetchProposalFailedAction = ActionWithError<FETCH_PROPOSAL_FAILED, Error>;

type SaveProposalAction = Action<SAVE_PROPOSAL>;
type SaveProposalSuccessAction = ActionWithPayload<SAVE_PROPOSAL_SUCCESS, Resource<{}>>;
type SaveProposalFailedAction = ActionWithError<SAVE_PROPOSAL_FAILED, Error>;

type DeleteProposalAction = Action<DELETE_PROPOSAL>;
type DeleteProposalSuccessAction = ActionWithPayload<DELETE_PROPOSAL_SUCCESS, Resource<{}>>;
type DeleteProposalFailedAction = ActionWithError<DELETE_PROPOSAL_FAILED, Error>;

export type ProposalActionTypes =
    | FetchProposalsAction
    | ReceiveProposalsAction
    | FetchProposalsFailedAction
    | FetchProposalAction
    | ReceiveProposalAction
    | FetchProposalFailedAction
    | SaveProposalAction
    | SaveProposalSuccessAction
    | SaveProposalFailedAction
    | DeleteProposalAction
    | DeleteProposalSuccessAction
    | DeleteProposalFailedAction

export function fetchProposals(
    type?: repr.investmentTypes
): RestSideEffect<repr.ProposalsPayload> {
    return (
        dispatch => dispatch(
            makeRequest(
                FETCH_PROPOSALS,
                RECEIVE_PROPOSALS,
                FETCH_PROPOSALS_FAILED,
                ({ authToken }) => api.getProposals(authToken, type)
            )
        )
    );
}

export function fetchProposal(
    proposalId: string,
    locale: string
): RestSideEffect<repr.ProposalDetailed> {
    return (
        dispatch => dispatch(
            makeRequest(
                FETCH_PROPOSAL,
                RECEIVE_PROPOSAL,
                FETCH_PROPOSAL_FAILED,
                ({ authToken }) => api.getProposal(authToken, proposalId, locale)
            )
        )
    );
}

export function saveProposal(
    data: Partial<repr.ProposalDetailed>
): RestSideEffect<{}> {
    return (
        dispatch => dispatch(
            makeRequest(
                SAVE_PROPOSAL,
                SAVE_PROPOSAL_SUCCESS,
                SAVE_PROPOSAL_FAILED,
                ({ authToken, userId }) => api.postProposal(authToken, userId, data)
            )
        )
    );
}

export function deleteProposal(
    proposalId: string
): RestSideEffect<{}> {
    return (
        dispatch => dispatch(
            makeRequest(
                DELETE_PROPOSAL,
                DELETE_PROPOSAL_SUCCESS,
                DELETE_PROPOSAL_FAILED,
                ({ authToken }) => api.deleteProposal(authToken, proposalId)
            )
        )
    );
}
