/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import theme from '../../styles/theme.pdf.json';
import ImportantInfoText from '../shared/ImportantInfoText';

interface PropsShape {
    report: repr.Report;
}

// This is for the pdf reports
export default function ImportantInfoPage(props: PropsShape) {
    const { report } = props;
    return (
        <section
            className="page-full"
            css={css`
                page: runningHeadersOnly;
                color: ${theme['$color-black']};
                background-color: ${theme['$color-white']};
            `}
        >
            <div className="main-full">
                <h1
                    className="section-title"
                    css={css`
                        color: ${theme['$color-black']};
                    `}
                >
                    {t('report.importantInfo.heading')}
                </h1>
                <div
                    className="copy"
                    css={css`
                        font-size: ${theme['$font-size-small']};
                    `}
                >
                    <ImportantInfoText report={report} />
                </div>

                <footer
                    css={css`
                        position: absolute;
                        bottom: 14%;
                    `}
                >
                    {/* {report.includeGIA
                        ? t(`report.importantInfoFootnote.textInvestmentPlus`)
                        : report.investmentType === 'Seg'
                        ? t(`report.importantInfoFootnote.textSeg`)
                        : ''} */}
                </footer>
                {report.investmentType === 'MMF' && <div>
                    <div className="annotations">
                        {t(`report.endPage.footerText${report.investmentType}`)}
                    </div>
                </div>}
            </div>
        </section>
    );
}
