import { css } from '@emotion/core';
import theme from '../../../styles/theme.web.json';

const poolSelectorStyles = css`
    .heading {
        font-size: ${theme['$font-size-large']};
        margin-bottom: 1em;
        font-weight: 600;
    }

    .input-row {
        display: flex;
        margin-bottom: 1em;
        flex-wrap: wrap;
    }

    .label-input-pair {
        display: flex;
        flex-direction: column;
        margin-right: 4em;
    }

    input, select {
        height: 35px;
        margin-top: 5px;
        padding: 0 5px 0 5px;
        border: 1px solid ${theme['$color-navy-light-4']};
    }

    .add-pool-button {
        height: 35px;
        margin-top: 1.25em;
    }
`;

export default poolSelectorStyles;
