import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { setLocale as setGlobalLocale, getLocale } from 'react-i18nify';
import * as repr from '../api/types';
import { StateShape as ReduxStateShape } from '../reducers';
import { fetchCurrentUser } from '../actions/users';
import { ThunkDispatch } from '../actions/utils';
import { setLocale } from '../actions/localization';
import t from '../localization/i18n';
import { LoginMenu } from './api-authorization/LoginMenu';

interface PropsShape {
    dispatch: ThunkDispatch
    currentUser: repr.User | null
}

// named NavMenu to avoid conflict with the reactstrap Navbar
function NavMenu(props: PropsShape) {
    const dispatch = props.dispatch;
    const currentUser = props.currentUser;
    const [collapsed, setCollapse] = useState(true);
    const [currentLocale, setCurrentLocale] = useState(getLocale() || 'en-US');

    useEffect(() => {
        if (!currentUser) {
            dispatch(fetchCurrentUser());
        }
    }, [currentUser, dispatch]);

    const toggleNavbar = () => {
        setCollapse(!collapsed);
    };

    const toggleLocale = () => {
        if (currentLocale === 'en-US') {
            dispatch(setLocale('fr-CA')).then(() => {
                setGlobalLocale('fr-CA');
                setCurrentLocale('fr-CA');
            });
        } else {
            dispatch(setLocale('en-US')).then(() => {
                setGlobalLocale('en-US');
                setCurrentLocale('en-US');
            });
        }
    };

    const logoPath = `/proposal-builder/img/manulife-logo-${t('lang')}.svg`;

    return (
        <header>
            <Navbar
                className="navbar-expand-sm navbar-toggleable-sm mb-3 border-bottom"
            >
                <Container>
                    <NavbarBrand tag={Link} to="/proposals">
                        <img
                            src={logoPath}
                            alt={t('application.logoDescription')}
                            className="navbar-logo"
                        />
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2">
                        <span
                            className={`icon icon--arrow-right ${!collapsed ? 'expanded' : ''}`}
                            aria-hidden="true"
                        />
                        <span className="sr-only">{t('application.toggleNavbar')}</span>
                    </NavbarToggler>
                    <Collapse
                        className="d-sm-inline-flex flex-sm-row-reverse"
                        isOpen={!collapsed}
                        navbar
                    >
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} to="/proposals">
                                    {t('clientList.heading')}
                                </NavLink>
                            </NavItem>
                            <LoginMenu currentUser={currentUser} />
                            <NavItem>
                                <NavLink
                                    tag={Link}
                                    className="btn-link inline"
                                    onClick={toggleLocale}
                                    // to={`/proposals/${proposal.id}/edit${clientId ? `?clientId=${clientId}` : ''}`}
                                >
                                    {currentLocale === 'en-US' ? 'Français' : 'English'}
                                </NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

const mapStateToProps = (state: ReduxStateShape) => {
    const currentUser = state.app.currentUser;

    return { currentUser };
};

export default connect(mapStateToProps)(NavMenu);
