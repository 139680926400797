import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const importantInfoStyles = css`
    background-color: ${theme['$color-white']};
    color: ${theme['$color-black']};
    padding: 2em !important;
    margin-top: 3em;

    .section-title {
        color: ${theme['$color-black']};
    }
`;

export default importantInfoStyles;
