/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import Spinner from 'reactstrap/lib/Spinner';
import Stickyfill from 'stickyfilljs';
import { animateScroll as scroll } from 'react-scroll';
import * as H from 'history';
import t from '../../localization/i18n';
import Button from '../Button';
import * as repr from '../../api/types';
import fetchReportUrlAndOpenEmail from '../shared/EmailReport';
import { ThunkDispatch } from '../../actions/utils';
import { generatePDFReport } from '../../actions/reports';

interface PropsShape {
    dispatch: ThunkDispatch
    client: repr.ReportClient | undefined
    report: repr.Report
    history: H.History
}

interface StateShape {
    latestReportId: string
    pdfErrorMessage: string
    pdfReportStatus: 'pending' | 'generated' | 'noPDF'
}

const downloadPdfUrlBase = '/proposal-builder/api/Reports';
const downloadPDFUrls = {
    'en-US': '/en-US/download-pdf',
    'fr-CA': '/fr-CA/download-pdf',
};

export default class TopBar extends React.Component<PropsShape, StateShape> {
    state: StateShape = {
        latestReportId: '',
        pdfErrorMessage: '',
        pdfReportStatus: 'noPDF',
    };

    componentDidMount() {
        const topBarElement = document.querySelector('#top-bar');

        // Add position: sticky polyfill for ie to allow top-bar to
        // stick at the top
        if (topBarElement) {
            Stickyfill.addOne(topBarElement as HTMLElement);
        }
    }

    private renderPDFLinks = (latestReportId: string) => {
        const { report, client } = this.props;
        let emailSubjectText = '';

        if (report.proposalType === 'Household' && !client) {
            emailSubjectText = `${t('email.proposalForHousehold')} ${report.proposalName}`;
        } else {
            emailSubjectText = `${t('email.proposalForClient')} ${
                report.clients[0].firstName
            } ${report.clients[0].lastName}`;
        }

        return (
            <>
                <a
                    className='pdf-links'
                    href={`${downloadPdfUrlBase}/${latestReportId}${downloadPDFUrls['en-US']}`}
                    target='_blank'
                    rel="noopener noreferrer"
                >
                    <span className="icon icon--document" aria-hidden="true" />
                    <span className="sr-only">{t('application.viewEnPDF')}</span>
                    {t('application.en')}
                </a>
                <a
                    className='pdf-links'
                    href={`${downloadPdfUrlBase}/${latestReportId}${downloadPDFUrls['fr-CA']}`}
                    target='_blank'
                    rel="noopener noreferrer"
                >
                    <span className="icon icon--document" aria-hidden="true" />
                    <span className="sr-only">{t('application.viewFrPDF')}</span>
                    {t('application.fr')}
                </a>
                <Button
                    className='pdf-links'
                    variant='icon'
                    onClick={() => {
                        this.setState({
                            pdfErrorMessage: '',
                        });

                        fetchReportUrlAndOpenEmail(
                            this.props.dispatch,
                            latestReportId,
                            emailSubjectText
                        );
                    }}
                >
                    <span className="icon icon--email" aria-hidden="true" />
                    <span className="sr-only">{t('application.emailReport')}</span>
                </Button>
            </>
        );
    };

    private generatePDFReport = (proposalId: string, clientId: string) => {
        const { dispatch } = this.props;

        dispatch(generatePDFReport(proposalId, clientId || null))
            .then((resp) => this.setState({
                pdfReportStatus: 'generated',
                latestReportId: resp.data.reportId,
            }))
            .catch(() => this.setState({
                pdfErrorMessage: t('errorMessages.pdfGenerationError'),
                pdfReportStatus: 'noPDF',
            }));
    };

    render() {
        const { report, client, history } = this.props;
        const { pdfErrorMessage, pdfReportStatus, latestReportId } = this.state;

        let pageTitle = ` ${t('proposalForm.proposalFor')} `;

        if (report.proposalType === 'Household' && !client) {
            pageTitle += report.proposalName;
        } else {
            pageTitle +=
                `${report.clients[0].firstName} ${report.clients[0].lastName}`;
        }

        return (
            <div id="top-bar" className='tm-card top-bar'>
                <h1 className='title' onClick={() => scroll.scrollToTop()}>
                    <span className='bold'>
                        {t(`clientList.${report.investmentType.toLowerCase()}`)}
                    </span>{pageTitle}
                </h1>
                <div className='controls'>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            history.push(`/proposals/${report.proposalId}/edit${
                                client ? `?clientId=${client.clientId}` : ''
                            }`);
                        }}
                    >
                        {t('report.modifyProposal')}
                    </Button>

                    {
                        pdfReportStatus === 'generated' &&
                        latestReportId &&
                        this.renderPDFLinks(latestReportId)
                    }

                    {pdfReportStatus === 'noPDF' && (
                        <Button
                            className="button--dlPrintPdf"
                            variant="primary"
                            onClick={() => {
                                this.setState({
                                    pdfReportStatus: 'pending',
                                    pdfErrorMessage: '',
                                });

                                // if no client, then the household report is being generated
                                this.generatePDFReport(
                                    report.proposalId,
                                    client ? client.clientId : '',
                                );
                            }}
                        >
                            {t('report.downloadPdf')}
                        </Button>
                    )}

                    {pdfReportStatus === 'pending' && (
                        <Spinner className='loading-pdfs-spinner' color="dark" />
                    )}

                    {pdfErrorMessage && <div className='error-message'>{pdfErrorMessage}</div>}
                </div>
            </div>
        );
    }
}
