import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const greyDivider = `2px solid ${theme['$color-grey']}`;

const webReportStyles = css`
    display: flex;
    flex-direction: column;
    position: relative; // for the loading overlay

    .report-container {
        display: flex;
        border-top: none;

        .scroll-nav-container {
            padding-right: 2em;
        }
    }

    .benefits-section {
        ul {
            padding: 1em;

            li {
                list-style-type: disc;
                padding: 1em;
            }
        }
    }

    @media (max-width: 991px) {
        .report-container {
            padding-right: 0.5em;
        }

        .report {
            width: 90%;
        }

        .section {
            padding: 3em 0 6em 0;
        }
    }

    @media (min-width: 992px) {
        .scroll-nav-container {
            width: 25%;
        }

        .report {
            width: 75%;
        }

        .section {
            padding: 3em 0 6em 0;
        }
    }

    @media (min-width: 1200px) {
        .report {
            width: 80%;
        }

        .section {
            padding: 3em 2em 6em 2em;
        }
    }

    .bold {
        font-weight: 700;
    }

    p {
        margin-bottom: 1em;
        line-height: 1.2;
    }

    .divider {
        margin: 2em 0;
        border-bottom: ${greyDivider};
    }

    .top-bar.section {
        padding: 0;
    }

    .top-bar {
        border-bottom: ${greyDivider};
        width: 100%;

        /* for positioning of proposal title and action buttons */
        display: flex;

        /* for when scrolling the web report */
        position: sticky;
        top: 0;
        z-index: 2;

        &.section {
            border-bottom: none;
            padding-top: 0;
        }

        .title {
            width: 50%;
            font-size: ${theme['$font-size-xlarge']};

            .bold {
                font-weight: 700;
            }

            // title onClick scrolls up the web report
            &:hover {
                cursor: pointer;
            }
        }

        .controls {
            text-align: right;
            flex-grow: 1;

            button {
                margin-bottom: 0.5em;
            }
        }

        .button--dlPrintPdf {
            margin-left: 0.5em;
        }
    }

    .section {
        border-bottom: ${greyDivider};
    }

    .subsection {
        margin-bottom: 1.5em;
    }

    /* -- Titles --*/

    .section-title {
        font-size: 26px;
        color: ${theme['$color-black']};
        margin-bottom: 1em;

        // text in headers with special styling (bold or cursive)
        .accent {
            font-weight: 700;
        }

        .accent-italic{
            font-style: italic;
        }
    }

    .new-section-title{
        color: ${theme['$color-black']};
    }

    .as-of-date {
        display: block;
        margin-top: -1.5em;
        margin-bottom: 2em;
        font-size: ${theme['$font-size-small']};
    }

    .subtitle {
        color: ${theme['$color-black']};
        font-size: ${theme['$font-size-large']};
        font-weight: 600;
        margin-bottom: 1em;
    }

    th.subtitle {
        margin-bottom: 0;
        padding-bottom: 1em;
    }

    .account-title {
        color: ${theme['$color-black']};
        margin-bottom: 0.5em;
    }

    caption > .account-title {
        margin-bottom: 0;
        padding-bottom: 0.5em;
    }

    .table-title {
        color: ${theme['$color-black']};
        font-size: ${theme['$font-size-small']};
        font-weight: 600;
        margin: 1em 0 0.5em;
    }

    caption > .table-title {
        margin: 0;
        padding: 0.5em 0 0.5em;
    }

    /* ----- */

    .table-container {
        position: relative;
        margin-bottom: 2em;

        .footer {
            position: absolute;
            right: 0;
        }
    }

    .table {
        width: 100%;

        thead {
            color: ${theme['$color-black']};
            border-bottom: 1px solid ${theme['$color-grey']}
        }

        tr th {
            text-align: center;
        }

        tr th:first-child {
            text-align: left;
        }

        th,
        td {
            vertical-align: middle;
            padding: 0.5em;
            text-align: center;
            min-width: 55px;

            &.left {
                text-align: left;
            }

            &.right {
                text-align: right;
            }

            &.bold {
                font-weight: 600;
            }

            &.fund-name {
                width: 33%;
            }

            &.value {
                width: 18%;
            }

            &.allocation {
                width: 18%;
            }
        }

        tr td:first-child {
            text-align: left;
        }

        thead th {
            font-weight: 400;
            border: none;
            min-width: 50px;
        }

        td {
            font-weight: 300;
        }

        &.centered {
            table-layout: fixed;
        }

        &.acc-perf {
            tr th:first-child {
                text-align: center;
            }
    
            tr td:first-child {
                text-align: center;
            }
        }
    }

    .allocation-tile {
        display: flex;
        margin-top: 1.25em;
        margin-bottom: 1.5em;

        .allocation-table {
            width: 66%;

            .chart-title {
                font-size: ${theme['$font-size-large']};
                font-weight: 600;
                margin-top: 0.5em;
                margin-bottom: 1em;
            }

            .allocation-row {
                font-size: ${theme['$font-size-xsmall']};

                span {
                    display: inline-block;
                    margin-right: 5px;
                    margin-bottom: 2px;
                }

                .allocation-color {
                    height: 10px;
                    width: 10px;
                    vertical-align: middle;
                }

                .allocation-percent {
                    font-weight: 600;
                }
            }

            .chart-disclaimer {
                font-size: ${theme['$font-size-xsmall']};
                display: block;
                margin-top: 1.5em;
            }
        }
    }

    .disclaimers {
        font-size: ${theme['$font-size-small']};
    }
    
    .annotations {
        font-size: ${theme['$font-size-xsmall']};
    }

    .pdf-links {
        color: ${theme['$color-black']};
        margin-right: 4px;
        margin-left: 8px;

        .icon {
            margin-right: 2px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .loading-pdfs-spinner {
        margin: 0 2em;
        vertical-align: top;
    }

    .error-message {
        color: ${theme['$color-coral-dark-3']};
        margin-top: 5px;
    }

    .loading-report-overlay {
        background-color: rgba(0, 0, 0, 0.7);
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 3;

        .spinner-container {
            position: fixed;
            left: 50%;
            top: 50%;
        }
    }
`;

export default webReportStyles;
