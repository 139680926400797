import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const clientListStyles = css`
    height: 82vh;
    background-color: ${theme['$color-white']};
    margin-top: 2rem;
    padding: 30px;
    border-top: 2px solid ${theme['$color-green']};

    .title {
        font-size: ${theme['$font-size-xlarge']};
    }

    .top-bar {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
    }

    .search-bar-container {
        width: 25%;
        background: url('/proposal-builder/icons/search.svg') 165px 60%/60% 60% no-repeat;

        .search-bar {
            background-color: transparent;
            border: 1px solid ${theme['$color-navy-light-4']};
            border-radius: 1px;
            box-shadow: none;
            outline: none;
            width: 100%;
            height: 120%;
            padding: 6px 30px 6px 10px;
        }
    }

    .top-bar-options {
        display: flex;

        input {
            margin-right: 5px;
            vertical-align: middle;
        }

        label {
            margin-right: 15px;
            vertical-align: middle;
        }
    }

    hr {
        border-top: 1px solid rgba(0, 0, 0, 0);
    }

    .client-list-container {
        position: relative;
        margin-top: 1.5rem;
        overflow-y: auto;
        height: 75%;
    }

    .client-list-table {
        width: 100%;

        thead th, thead td {
            position: sticky;
            top: -1px;
            z-index: 1;
            color: ${theme['$color-white']};
            background-color: ${theme['$color-green-dark-1']};
            font-weight: 400;
            height: 40px;
            vertical-align: middle;
        }

        th span {
            font-weight: 600;
        }

        tr {
            line-height: 2;
        }

        td {
            vertical-align: middle;
            padding: 2px 0;
        }

        .icon {
            width: ${theme['$font-size-small']};
            height: ${theme['$font-size-small']};
            filter: invert(27%)
            sepia(17%)
            saturate(0%)
            hue-rotate(283deg)
            brightness(91%)
            contrast(80%);
        }

        .empty-header {
            width: 1.5em;
        }
    }

    .expandable-row {
        td {
            font-weight: 600;
        }
        .clickable {
            &:hover {
                cursor: pointer;
            }
        }

        .arrow-icon {
            &.expanded {
                transform: rotate(90deg);
            }
        }
    }

    .expanded-row {
        .name {
            padding-left: 15px;
        }
    }

    .edit-link {
        background: none;
        border: none;
    }

    .sort-toggle {
        border: none;
        border-radius: 0;
        padding: 0 0 0 5px;
        background: none;
    }

    .bold {
        font-weight: bold;
    }

    .pdf-link-container {
        margin-left: 10px;

        .render-report {
            color: ${theme['$color-white']};
            background: ${theme['$color-navy-light-3']};
            font-size: ${theme['$font-size-xsmall']};
            padding: 2px 4px;

            &:hover {
                background: ${theme['$color-navy']};
                text-decoration: none;
            }
        }

        .pdf-links {
            color: ${theme['$color-black']};
            font-size: ${theme['$font-size-small']};
            margin-right: 4px;
            margin-left: 8px;

            .icon {
                margin-right: 2px;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    .error-message {
        color: ${theme['$color-coral-dark-3']};
    }
`;

export default clientListStyles;
