import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const marginSpacingSmall = '1em';

const configurationStyles = css`
    .heading {
        font-size: ${theme['$font-size-large']};
        margin-bottom: ${marginSpacingSmall};

        .bold {
            font-weight: 600;
        }
    }

    .product-row{
        display: block;
        margin-bottom: ${marginSpacingSmall};
        .product-group{
            margin: ${marginSpacingSmall};

            label {
                margin-right: ${marginSpacingSmall}
            }
        }

    }

    .input-row {
        display: flex;
        position: relative;
        margin-bottom: ${marginSpacingSmall};

        .input-group {
            position: absolute;
            right: 0;
            width: auto;

            label {
                margin-right: 2em;
            }
        }

        .province-dropdown {
            right: 2em;
        }
    }

    .service-fee {
        display: inline-block;
        width: 100%;
        text-align: right;
        margin-top: 0.5em;
        margin-bottom: 1.25em;
        padding-right: 2em;

        label {
            margin-right: 0.5em;
        }

        input {
            width: 5em;
            margin-right: 5px;
        }
    }

    .label-input-pair {
        display: inline-block;

        .error-message {
            margin-top: 3px;
        }
    }

     .symbol-input-pair {
        display: inline-block;
    }

    .error-message {
        display: block;
    }
`;

export default configurationStyles;
