/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import t from '../../localization/i18n';
import theme from '../../styles/theme.pdf.json';
import SectionTitle from './SectionTitle';
import * as repr from '../../api/types';
import { isGif } from '../helpers';

interface PieSwatchPropsShape {
    color?: string
}

/**
 * Renders a square with the given color. For use within a pie chart legend.
 */
function PieSwatch(props: PieSwatchPropsShape) {
    const pieSwatchDiameter = '5pt';
    return <span
        css={css`
            display: inline-block;
            backgroundColor: darkgray;
            height: ${pieSwatchDiameter};
            width: ${pieSwatchDiameter};
            margin-right: ${pieSwatchDiameter};
        `}
        className="js-pie-swatch"
        style={{ backgroundColor: props.color }}
    />;
}

interface PropsShape {
    id: string
    title: string
    data: Holding[]
    productType: repr.productType,
    investmentType: repr.investmentTypes
}

interface Holding {
    name: string
    pct: number
}

/**
 * Renders a table displaying holdings data, a script tag containing that
 * holdings data, and a placeholder element for the pie chart. The chart will
 * be rendered when `src/pdf-js/pie-chart.js` is loaded and executed on
 * page load.
 */
export default function PieChart(props: PropsShape) {
    const pieId = props.id;
    const data = props.data.map(o => ({ name: o.name, y: o.pct }));
    const { productType , investmentType } = props;
    return (
        <div
            id={`${pieId}`}
            className="js-pie-tile"
            css={{ marginBottom: '0.2in', clear: 'both', overflow: 'hidden' }}
        >
            <div
                id={`${pieId}-pie-chart`}
                css={({
                    width: '1.25in',
                    height: '1.25in',
                    float: 'left',
                })}
            />
            <div css={({ marginLeft: '1.4in' })}>
                <SectionTitle styles={{ marginBottom: '0.8px' }}>
                    {t(`report.allocation.chartTitles.${props.title}`)}
                </SectionTitle>

                <table id={`${pieId}-table`} css={css`line-height: ${theme['$leading-tight']};`}>
                    <thead className="visually-hidden">
                        <tr className="visually-hidden">
                            <th scope="col" className="visually-hidden">Colour</th>
                            <th scope="col" className="visually-hidden">Name and Weight</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((o, i) => (
                            <tr key={i}>
                                <td>
                                    <PieSwatch />
                                </td>
                                <td css={{
                                    fontFamily: 'Manulife JH Sans',
                                    fontSize: '8pt',
                                }}>
                                    {o.name}&nbsp;
                                    <span css={css`font-weight: ${theme['$font-weight-xbold']};`}>
                                        {t(o.pct, 'pctMax1')}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="copy copy--notes" css={{ marginTop: '8px' }}>
                {(isGif(productType) || investmentType === "MMF") ? t('report.allocation.chartDisclaimerNonMPIP') : t('report.allocation.chartDisclaimer')}
                </div>
            </div>

            <script
                id={`${pieId}-json-data`}
                type="text/json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
            >
            </script>

        </div>
    );
}
