import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const consolidatedViewStyles = css`
    .heading {
        font-size: ${theme['$font-size-large']};
        margin-bottom: 1em;
        font-weight: 600;
    }

    .sub-heading {
        font-size: ${theme['$font-size-large']};
        margin-bottom: 0.5em;
    }

    .section {
        margin-top: 2em;
        margin-bottom: 4em;
    }

    .table-container {
        position: relative;

        .footer {
            position: absolute;
            right: 0;
            margin-top: 0.5em;

            .accounts-total {
                margin-right: 1em;
            }
        }
    }

    .table {
        .second-col{
            text-align: center !important;
        }
        &.holdings {
            margin-bottom: 2em;

            .fund-name {
                width: 40%;

                &.narrow {
                    width: 30%;
                }
            }
    
            .mer,
            .gia-term {
                width: 10%;
            }

            .service-fee {
                width: 12%;
            }
    
            .value {
                width: 15%;
            }
    
            .allocation {
                width: 10%;
            }

            .second-col{
                text-align: center;
            }
        }

        th, td {
            text-align: left;
        }

        .first-account, .first-holding {
            td {
                border-top: 1px solid ${theme['$color-grey']};
            }
        }
    }

    .link {
        color: ${theme['$color-blue']};

        &:hover {
            cursor: pointer;
        }
    }
`;

export default consolidatedViewStyles;
