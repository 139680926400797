/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import t from '../../localization/i18n';
import * as repr from '../../api/types';

interface PropsShape {
    report: repr.Report;
}

export default class FundSpecificDisclosures extends React.Component<PropsShape> {
        private fundWithSeriesDisclaimers = (fundProfiles: repr.FundDetailed[]) : repr.FundDetailed | null  => {
            const fund =  fundProfiles.filter(fund => fund.fundSpecificDisclosures !== null && 
                ((fund.fundSpecificDisclosures.seriesFDisclaimer !== null)) && (fund.fundSpecificDisclosures.seriesCDisclaimer !== null))[0];
            return fund ? fund : null;
        }
        render() {
            const {report} = this.props;
        return (
            <section id="fundSpecificDisclosures" className="page">
                <div className="section main disclosures-section">
                    <h2 className="section-title cover-title">
                        {t('report.disclosures.fundSpecificDisclosures.heading')}
                    </h2>
                    {
                        report.fundProfiles.filter(fund => fund.fundSpecificDisclosures !== null).map((fund, index) => (
                            <div key={fund._id}>
                                <p><sup>{++index}</sup>{fund.fundName}</p>
                                <div className='disclaimers'>
                                    <p>{(fund.fundSpecificDisclosures.paagFootnote !== null) && fund.fundSpecificDisclosures.paagFootnote}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </section>
            
        );
    }
};