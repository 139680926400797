/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import t from '../../localization/i18n';
import * as repr from '../../api/types';
import AllocationChart from '../WebReport/AllocationChart';
import PieChart from '../report/PieChart';
import accountAllocationStyles from './AccountAllocation.styles';
import { isGif } from '../helpers';

interface PropsShape {
    report: repr.Report
    variant: 'web' | 'pdf'
}

const chartTitleKeys = {
    assetAllocations: 'assetClass',
    geographicAllocations: 'country',
    sectorAllocations: 'gicsSector'
};

type PieDataType = 'assetAllocations' | 'geographicAllocations' | 'sectorAllocations';

export default class AccountAllocation extends React.Component<PropsShape> {
    private buildAllocationsForAccount = (
        client: repr.ReportClient,
        account: repr.ReportAccount,
        accountIndex: number
    ) => {
        const { variant } = this.props;
        const pieContexts: PieDataType[] =
            ['assetAllocations', 'geographicAllocations', 'sectorAllocations'];

        return (
            <>
                <h3 className='account-title'>{account.nickname}</h3>

                {pieContexts.map(key => {
                    const id = `${client.clientId}-${accountIndex}-${key}`;
                    const data = account[key as PieDataType];

                    if (data.length > 0) {
                        return variant === 'pdf' ? (
                            <PieChart
                                key={id}
                                id={id}
                                title={chartTitleKeys[key]}
                                data={data}
                                productType={this.props.report.productType}
                                investmentType={this.props.report.investmentType}
                            />
                        ) : (
                            <AllocationChart
                                key={id}
                                allocations={data}
                                title={t(`report.allocation.chartTitles.${chartTitleKeys[key]}`)}
                                size={100}
                                productType={this.props.report.productType}
                                investmentType={this.props.report.investmentType}

                            />
                        );
                    }

                    return null;
                })}
            </>
        );
    }

    private buildAllocationPages = (client: repr.ReportClient, clientIndex: number) => {
        const { report } = this.props;
        const accountLength = client.accounts.length;
        const pages: JSX.Element[] = [];

        const holdingsEffectiveDate = report.fundProfiles.length > 0
            ? report.fundProfiles[0].holdingsEffectiveDate
            : null;

        for (let i = 0; i < accountLength; i++) {
            const account = client.accounts[i];
            let page;

            // If there is an account available after this one, place it on the same
            // page side-by-side
            if (i + 1 < accountLength) {
                const nextAccount = client.accounts[i + 1];

                page = (
                    <div className='two-column-page'>
                        <div className='column left'>
                            {this.buildAllocationsForAccount(client, account, i)}
                        </div>
                        <div className='column right'>
                            {this.buildAllocationsForAccount(client, nextAccount, i + 1)}
                        </div>
                    </div>
                );

                // Increment here to skip the second account added to this page
                i++;
            } else {
                page = (
                    <div className='column'>
                        {this.buildAllocationsForAccount(client, account, i)}
                    </div>
                );
            }

            pages.push(
                <section className="page" key={clientIndex + i}>
                    <div
                        id="account-allocation"
                        css={accountAllocationStyles}
                        className="main section"
                    >
                        {/* Show main heading only on first page of section */}
                        {clientIndex === 0 && i < 2 &&
                            <div className="section-header">
                                <h2
                                    className='section-title cover-title'
                                    css={css`margin: 0.2em 0; font-size: 20pt;`}
                                >
                                    {!isGif(report.productType) ? t(
                                        `report.accountAllocation.heading${report.investmentType}`
                                    ): t(`report.accountAllocation.headingGIF`)}
                                </h2>

                                {holdingsEffectiveDate && (
                                    <span className='as-of-date'>
                                        {t({
                                            key: 'report.dataAsOf',
                                            values: { date: t(holdingsEffectiveDate, 'dateLong') }
                                        })}
                                    </span>
                                )}

                                {report.includeGIA && (
                                    <div className="header-disclaimer">
                                        {t('report.accountAllocation.giaDisclaimer')}
                                    </div>
                                )}
                            </div>
                        }

                        <h2 className="subtitle">
                            {client.fullName}
                        </h2>

                        {page}
                    </div>
                </section>
            );
        }

        return pages;
    }

    render() {
        const { report } = this.props;

        return report.clients.map((c, clientIndex) => this.buildAllocationPages(c, clientIndex));
    }
}
