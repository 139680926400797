import React from 'react';
import Select, { createFilter } from 'react-select';
import theme from '../../styles/theme.web.json';

interface PredictiveSearchProps {
    id: string;
    options: Array<{"value": string, "label": string}>;
    handleChange: (selectedOption: { value: string, label: string }) => void;
    labelName: string;
    selectedValue: {value: string, label: string}
}

export const PredictiveSearchComponent = (props: PredictiveSearchProps) => {
    const {id, options, handleChange, labelName, selectedValue} = props;

    const customStyles = {
        control: (base: any) => ({
            ...base,
            background: `${theme['$color-grey']}`,
            borderRadius: "18px",
            width: "520px",
            height: "30px"
        }),
        valueContainer: (base: any) => ({
            ...base,
            height: "inherit"
        }),
        dropdownIndicator: (base: any) => ({
            ...base,
            color: `${theme['$color-grey']}`
          }),
        placeholder: (base: any) => ({
            ...base,
            display: "none"
        }),
        singleValue: (base: any) => ({
            ...base,
            top: "85%",
            height: "18px"
        }),
        menu: (base: any) => ({
            ...base,
            background: `${theme['$color-grey']}`,
            borderRadius: "0 0 18px 18px",
        }),
        option: (base: any, state: any) => ({
            ...base,
            background: (state.isFocused || state.isSelected) && `${theme['$color-blue-light-2']}`,
            color: (state.isFocused || state.isSelected) && `${theme['$color-white']}`,
            "&:hover": {
                background: `${theme['$color-blue-light-2']}`,
                color: `${theme['$color-white']}`
            }
        }),
        indicator: (base: any) => ({
            ...base,
            display: "none"
        })
    };

    return (
        <div>
            <label className="fund-name-label" htmlFor={id}>
                <span className="icon--search search-icon"></span>
                <span>{labelName}</span>
            </label>
            <Select
                id={id}
                styles={customStyles}
                className="basic-single"
                classNamePrefix="select"
                name="funds"
                isClearable
                options={options}
                value={selectedValue}
                onChange={handleChange}
                filterOption={createFilter({ ignoreAccents: false, matchFrom: 'any', stringify: (option: { label: string; }) => `${option.label}` })}
            />
        </div>
    )
}