import { Component } from 'react';

export default class ConditionalRedirectRoute extends Component {
    render() {
        const redirectUrl = 'https://www.manulifeim.com/retail/';

        // if hostname matches (e.g. reports.*) then redirect, else component
        const shouldRedirect = window.location.hostname.toLocaleLowerCase().startsWith('reports.manulifeim.ca');

        if (shouldRedirect) {
            window.location.replace(redirectUrl);
            return null;
        } else {
            return this.props.children;
        }
    }
}
