import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const programDetailsStyles = css`
    p {
        margin-bottom: 1em;
        line-height: 1.2;
    }

    .subtitle {
        margin-bottom: 0.5em;
    }

    .annotations {
        margin-top: 6em;
    }

    .footer-note {
        margin-left: 62px;
    }

    .footer-note p a {
        color:  ${theme['$color-black']};
    }

    .rr-chart{
        display: flex;
        justify-content: center;
    }

    .annotations.footer-note.aa{
        margin-top: -3em;
    }
`;

export default programDetailsStyles;
