import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const proposalFormStyles = css`
    margin-bottom: 2em;
    padding-bottom: 2em;

    .top-bar {
        display: flex;
        margin: 1em 0 2.5em 0;
        position: relative;

        .title {
            font-size: ${theme['$font-size-xlarge']};

            .bold {
                font-weight: 700;
            }
        }

        .form-controls {
            position: absolute;
            right: 0;
            text-align: right;

            select {
                margin-right: 1em;
                width: 12em;
                height: 35px;
                padding: 0 5px 0 5px;
                vertical-align: middle;
            }

            .save-message {
                padding-top: 0.5em;
            }
        }
    }

    .table {
        width: 100%;

        thead {
            color: ${theme['$color-black']};
            border-bottom: 2px solid ${theme['$color-black']};
        }

        tr td:first-child {
            text-align: left;
        }

        tr td {
            text-align: center;
        }

        th,
        td {
            padding: 0.5em;
            text-align: center;
            font-weight: 400;
            border: none;

            &.left {
                text-align: left;
            }
        }

        thead th {
            vertical-align: middle;
            min-width: 50px;
            font-weight: 600;
        }
 
        tr th:first-child{
            text-align: left;
        }

        tr th{
            text-align: center;
            text-transform: capitalize;
        }

        .align-middle {
            vertical-align: middle;
        }
    }

    .form-container {
        margin-top: 2.5em;
        margin-bottom: 1em;
    }

    .form-section {
        &.hide {
            display: none;
        }
    }

    .submit-buttons-container {
        button {
            margin-right: 1em;
        }
    }

    .error-message {
        font-size: ${theme['$font-size-small']};
        color: ${theme['$color-coral-dark-3']};
    }

    .form-messages {
        margin-top: 1em;
    }

    .form-error-messages {
        margin-top: 1em;
        color: ${theme['$color-coral-dark-3']};

        p {
            margin-bottom: 5px;
        }
    }
`;

export default proposalFormStyles;
