/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import parse from 'html-react-parser';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import theme from '../../styles/theme.pdf.json';
import PieChart from './PieChart';
import { isGif } from '../helpers';

interface chartTitleKeysShape {
    [key: string]: string
}

const chartTitleKeys: chartTitleKeysShape = {
    assetClass: 'assetClass',
    country: 'country',
    gicsSector: 'gicsSectorEquity'
};

interface PropsShape {
    fund: repr.FundDetailed,
    productType: repr.productType,
    investmentType: repr.investmentTypes
}

function SidebarTitle(props: { children: HTMLElement | string }) {
    return (
        <h2
            css={css`
                font-size: 13pt;
                font-weight: ${theme['$font-weight-bold']};
                margin: 1em 0 0.33em;
            `}
        >
            {props.children}
        </h2>
    );
}

function MainColumn(props: PropsShape) {
    const { fund } = props;
    const investorTypeHeading =
            t(`report.fundProfile.investorType${fund.streamType === 'mmf' ? 'MMF' : 'Seg'}`);

    let subAdvisorHeading = 'report.fundProfile.portfolioSubAdvisor';

    // Logic to decide what label to use for seg sub advisor heading
    if (fund.streamType === 'seg') {
        if (fund.isPortfolioFund === 'FOF') {
            subAdvisorHeading = 'report.fundProfile.underlyingFundManager';
        } else if (fund.isPortfolioFund === 'Bundle' || fund.isPortfolioFund === 'direct') {
            subAdvisorHeading = 'report.fundProfile.fundManager';
        } else {
            subAdvisorHeading = 'report.fundProfile.managedBy';
        }
    }

    const subAdvisorText = fund.streamType === 'seg'
        ? fund.underlyingFundManager
        : fund.portfolioSubAdvisor;

    return (
        <div css={css`
            background: ${theme['$color-white']};
            color: ${theme['$color-black']};
            font-size: ${theme['$font-size-small']};
            padding-top: calc(${theme['$page-margin-t']} * 1.6);
            padding-right: ${theme['$page-margin-h']};
            padding-bottom: calc(${theme['$page-margin-b']} + ${theme['$bleed-size']});
            padding-left: calc(${theme['$page-margin-h']} + ${theme['$bleed-size']});
            width: calc(${theme['$page--fund-profile--col-main--width']} + ${theme['$bleed-size']});
            display: flex;
            flex-direction: column;
            border-right: 1px solid #ededed;
        `}>
            <h1
                className="section-title fund-title"
                css={css`
                    font-size: 16pt;
                    font-weight: ${theme['$font-weight-xbold']};
                `}
            >
                {fund.fundName}
            </h1>

            <p>{fund.investmentObjective}</p>

            {subAdvisorText && (
                <>
                    <SidebarTitle>{t(subAdvisorHeading)}</SidebarTitle>
                    <p>{subAdvisorText}</p>
                </>
            )}

            {fund.fundManagers && (
                <>
                    <SidebarTitle>{t('report.fundProfile.portfolioManagers')}</SidebarTitle>
                    <p>{fund.fundManagers}</p>
                </>
            )}

            {fund.investorType && (
                <React.Fragment>
                    <SidebarTitle>{investorTypeHeading}</SidebarTitle>
                    {/* investorType sometimes contains html, which needs to be parsed */}
                    {/* <ul> tags were added since the data sometimes contains <li> tags */}
                    <ul className="investment-list">
                        {(fund.streamType === 'mmf') ? <li>{parse(fund.investorType)}</li> : parse(fund.investorType)}
                    </ul>
                </React.Fragment>
            )}

            <p css={css`
                display: flex;
                flex-direction: column-reverse;
                flex-grow: 1;
                font-weight: ${theme['$font-weight-light']};
                font-size: ${theme['$font-size-xsmall']};
            `}>
                {t('report.pleaseReadImportant')}
            </p>
        </div>
    );
}

function ChartsColumn(props: PropsShape) {
    const { fund, productType, investmentType } = props;
    const elementId = fund._id.includes(".") ? fund._id.replace(".","-") : fund._id;
    const pieContexts: string[] = ['assetClass', 'country', 'gicsSector'];

    return (
        <div css={css`
            padding-top: calc(${theme['$page-margin-t']} * 2 + ${theme['$bleed-size']});
            padding-right: calc(${theme['$page-margin-h']} + ${theme['$bleed-size']});
            padding-bottom: calc(${theme['$page-margin-b']} + ${theme['$bleed-size']});
            padding-left: ${theme['$page-margin-h']};
            width: calc(${theme['$page--fund-profile--col-charts--width']} + ${theme['$bleed-size']});
        `}>
            {pieContexts.map((key) => {
                const id = `${elementId}-${key}`;
                const data = fund.holdings[key as 'country' | 'assetClass' | 'gicsSector'];

                // Skip if no data for this context
                if (!data) return null;

                return (
                    <PieChart
                        key={id}
                        id={id}
                        title={chartTitleKeys[key]}
                        data={data}
                        productType={productType}
                        investmentType={investmentType}
                    />
                );
            })}

            {fund.top5Holdings &&
                <div css={css`font-size: ${theme['$font-size-small']};`}>
                    <SidebarTitle>{t('report.fundProfile.top5Holdings')}</SidebarTitle>

                    <ul>
                        {fund.top5Holdings.map((o, i) => (
                            <li key={i}>{o.name}</li>
                        ))}
                    </ul>

                    <p
                        css={css`
                            font-weight: ${theme['$font-weight-bold']};
                            margin-top: 0.75em;
                        `}
                    >
                        {`${(isGif(productType) || investmentType === "MMF") ? t(
                            'report.fundProfile.pctOfPoolNonMPIP'
                        ): t(
                            'report.fundProfile.pctOfPool'
                        )} ${t(fund.top5Percentage, 'pctMax1')}`}
                    </p>
                </div>
            }

            {fund.holdingsEffectiveDate && (
                <span
                    css={css`
                        display: block;
                        font-size: ${theme['$font-size-xsmall']};
                        margin-top: 2em;
                    `}
                >
                    {t({
                        key: 'report.dataAsOf',
                        values: { date: t(fund.holdingsEffectiveDate, 'dateLong') }
                    })}
                </span>
            )}
        </div>
    );
}

export default function FundProfilePage(props: PropsShape) {
    return (
        <section
            className="page-full"
            css={css`
                page: fundProfiles;
                display: flex;
                flex-direction: row;
            `}
        >
            <MainColumn fund={props.fund} productType={props.productType} investmentType={props.investmentType}/>
            <ChartsColumn fund={props.fund} productType={props.productType} investmentType={props.investmentType}/>
        </section>
    );
}
