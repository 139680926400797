// Polyfills to support older browsers.
// These imports must be at the top of this file!
/* eslint-disable import/no-unassigned-import */
import 'react-app-polyfill/ie11';
import 'core-js/features/array';
import 'core-js/features/string';
import 'core-js/features/set';
import 'core-js/web/url';
/* eslint-enable import/no-unassigned-import */

import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import configureStore from './store';
// import registerServiceWorker from './registerServiceWorker';

import './styles/index.scss';

const baseUrl = '/proposal-builder/';
// || document.getElementsByTagName('base')[0].getAttribute('href');

const initApp = () => {
    const rootElement = document.getElementById('root') as HTMLElement;

    const createHistory = require('history').createBrowserHistory;
    const history = createHistory();
    const store = configureStore(undefined, history);

    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <BrowserRouter basename={baseUrl}>
                    <App />
                </BrowserRouter>
            </ConnectedRouter>
        </Provider>,
        rootElement
    );
};

initApp();

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
// registerServiceWorker();
