import Resource, * as api from '../api/api';
import * as repr from '../api/types';
import makeRequest, { RestSideEffect } from './utils';
import { Action, ActionWithPayload, ActionWithError } from './Action';

export const FETCH_REPORT = 'FETCH_REPORT';
export type FETCH_REPORT = typeof FETCH_REPORT;

export const RECEIVE_REPORT = 'RECEIVE_REPORT';
export type RECEIVE_REPORT = typeof RECEIVE_REPORT;

export const FETCH_REPORT_FAILED = 'FETCH_REPORT_FAILED';
export type FETCH_REPORT_FAILED = typeof FETCH_REPORT_FAILED;

export const GENERATE_PDF_REPORT = 'GENERATE_PDF_REPORT';
export type GENERATE_PDF_REPORT = typeof GENERATE_PDF_REPORT;

export const GENERATE_PDF_REPORT_SUCCESS = 'GENERATE_PDF_REPORT_SUCCESS';
export type GENERATE_PDF_REPORT_SUCCESS = typeof GENERATE_PDF_REPORT_SUCCESS;

export const GENERATE_PDF_REPORT_FAILED = 'GENERATE_PDF_REPORT_FAILED';
export type GENERATE_PDF_REPORT_FAILED = typeof GENERATE_PDF_REPORT_FAILED;

export const FETCH_REPORT_URL = 'FETCH_REPORT_URL';
export type FETCH_REPORT_URL = typeof FETCH_REPORT_URL;

export const RECEIVE_REPORT_URL = 'RECEIVE_REPORT_URL';
export type RECEIVE_REPORT_URL = typeof RECEIVE_REPORT_URL;

export const FETCH_REPORT_URL_FAILED = 'FETCH_REPORT_URL_FAILED';
export type FETCH_REPORT_URL_FAILED = typeof FETCH_REPORT_URL_FAILED;

export const CLEAR_REPORT = 'CLEAR_REPORT';
export type CLEAR_REPORT = typeof CLEAR_REPORT;

type FetchReportAction = Action<FETCH_REPORT>;
type ReceiveReportAction = ActionWithPayload<RECEIVE_REPORT, Resource<repr.Report>>;
type FetchReportFailedAction = ActionWithError<FETCH_REPORT_FAILED, Error>;

type GeneratePDFReportAction = Action<GENERATE_PDF_REPORT>;
type GeneratePDFReportSuccessAction =
    ActionWithPayload<GENERATE_PDF_REPORT_SUCCESS, Resource<repr.PDFReportPayload>>;
type GeneratePDFReportFailedAction = ActionWithError<GENERATE_PDF_REPORT_FAILED, Error>;

type FetchReportUrlAction = Action<FETCH_REPORT_URL>;
type ReceiveReportUrlAction =
    ActionWithPayload<RECEIVE_REPORT_URL, Resource<repr.ReportUrlPayload>>;
type FetchReportUrlFailedAction = ActionWithError<FETCH_REPORT_URL_FAILED, Error>;
type ClearReportAction = Action<CLEAR_REPORT>;

export type ReportActionTypes =
    | FetchReportAction
    | ReceiveReportAction
    | FetchReportFailedAction
    | GeneratePDFReportAction
    | GeneratePDFReportSuccessAction
    | GeneratePDFReportFailedAction
    | FetchReportUrlAction
    | ReceiveReportUrlAction
    | FetchReportUrlFailedAction
    | ClearReportAction

export function fetchReport(
    proposalId: string,
    clientId: string | null,
    locale: 'en-US' | 'fr-CA'
): RestSideEffect<repr.Report> {
    return (
        dispatch => dispatch(
            makeRequest(
                FETCH_REPORT,
                RECEIVE_REPORT,
                FETCH_REPORT_FAILED,
                ({ authToken }) => api.getReport(authToken, proposalId, clientId, locale),
            )
        )
    );
}

export function generatePDFReport(
    proposalId: string,
    clientId: string | null
): RestSideEffect<repr.PDFReportPayload> {
    return (
        dispatch => dispatch(
            makeRequest(
                GENERATE_PDF_REPORT,
                GENERATE_PDF_REPORT_SUCCESS,
                GENERATE_PDF_REPORT_FAILED,
                ({ authToken }) => api.getPDFReportId(authToken, proposalId, clientId)
            )
        )
    );
}

export function fetchPDFReportUrl(
    reportlId: string,
    locale: 'en-US' | 'fr-CA'
): RestSideEffect<repr.ReportUrlPayload> {
    return (
        dispatch => dispatch(
            makeRequest(
                FETCH_REPORT_URL,
                RECEIVE_REPORT_URL,
                FETCH_REPORT_URL_FAILED,
                ({ authToken }) => api.getPDFReportUrl(authToken, reportlId, locale)
            )
        )
    );
}

export function clearReport(){
    return {type: CLEAR_REPORT}
}
