import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { History } from 'history';
import { StateShape, reducers } from './reducers';

export default function configureStore(initialState: StateShape | undefined, history: History) {
    const middleware = [thunk, routerMiddleware(history)];
    let enhance;

    if (process.env.NODE_ENV === 'development') {
        const { logger } = require('redux-logger');
        middleware.push(logger);
        enhance = composeWithDevTools(applyMiddleware(...middleware));
    } else {
        enhance = compose(applyMiddleware(...middleware));
    }

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    return createStore(rootReducer, initialState, enhance);
}
