import { fetchPDFReportUrl } from '../../actions/reports';
import { ThunkDispatch } from '../../actions/utils';
import t from '../../localization/i18n';

const openEmailClient = (
    englishPDFUrl: string,
    frenchPDFUrl: string,
    subjectText: string
) => {
    const subject = `subject=${subjectText}`;

    const lineBreak = '%0D%0A%0D%0A';
    const englishUrl =
        `${t('email.englishPDFUrl')}:${lineBreak}${encodeURIComponent(englishPDFUrl)}`;
    const frenchUrl = `${t('email.frenchPDFUrl')}:${lineBreak}${encodeURIComponent(frenchPDFUrl)}`;

    if (englishPDFUrl && frenchPDFUrl) {
        window.open(
            `mailto:?${subject}&body=${englishUrl}${lineBreak}${frenchUrl}${lineBreak}`,
            '_blank'
        );
    }
};

const fetchReportUrlAndOpenEmail = (
    dispatch: ThunkDispatch,
    latestReportId: string,
    subjectText: string
) => {
    let englishPDFUrl = '';
    let frenchPDFUrl = '';
    dispatch(fetchPDFReportUrl(latestReportId, 'en-US'))
        .then((resp) => {
            englishPDFUrl = resp.data.reportUrl;
        })
        .then(() => dispatch(fetchPDFReportUrl(latestReportId, 'fr-CA')).then((resp) => {
            frenchPDFUrl = resp.data.reportUrl;
        }))
        .then(() => openEmailClient(englishPDFUrl, frenchPDFUrl, subjectText))
        .catch(() => console.error(t('errorMessages.emailGenerationError')));
};

export default fetchReportUrlAndOpenEmail;
