import { css } from '@emotion/core';
import theme from '../../../styles/theme.web.json';

const accountSectionStyles = css`
    margin-bottom: 6em;

    .heading {
        font-size: ${theme['$font-size-large']};
        margin-bottom: 1em;
        font-weight: 600;
    }

    .table-container {
        position: relative;

        .footer {
            position: absolute;
            left: 0;

            .error-message {
                margin-top: 5px;
            }
        }

        .account-total {
            padding-left: 14%;
        }
    }

    input, select {
        height: 35px;
        margin-top: 5px;
        padding: 0 5px 0 5px;
        border: 1px solid ${theme['$color-navy-light-4']};
    }
`;

export default accountSectionStyles;
