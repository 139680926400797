import { css } from '@emotion/core';

const clientSectionStyles = css`
    .section {
        &.hide {
            display: none;
        }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .label-input-pair {
        display: flex;
        flex-direction: column;

        .error-message {
            margin-top: 3px;
        }
    }

    .symbol-input-pair {
        display: inline-block;

        input {
            margin-left: 3px;
            margin-right: 3px;
        }
    }

    .symbol-input-pair--currency {
        input {
            width: 75%;
        }
    }

    .symbol-input-pair--percent {
        input {
            width: 40%;
        }
    }
`;

export default clientSectionStyles;
