/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import React from 'react';
import * as repr from '../../../api/types';
import t from '../../../localization/i18n';
import Button from '../../Button';
import giaSelectorStyles from './GiaSelector.styles';

interface PropsShape {
    client: repr.ClientDetailed
    clientIndex: number
    giaFunds: repr.Fund[]
    serviceFee: number | null
    changeFormValue: (name: string, value?: any) => void
}

interface StateShape {
    giaTypes: { name: string, type: string }[]
    giaTypeSelected: string
    fundId: string
    accountName: string
    amount: number
    percent: number
    successMessage: string
}

const MAX_NUMBER_OF_GIA_FUNDS_PER_ACCOUNT = 15;

export default class GiaSelector extends React.Component<PropsShape, StateShape> {
    state: StateShape = {
        giaTypes: [],
        giaTypeSelected: '',
        fundId: '',
        accountName: '',
        amount: 0,
        percent: 0,
        successMessage: ''
    };

    componentDidMount() {
        const { giaFunds } = this.props;

        const giaTypes = _.uniqWith(
            _.map(giaFunds, fund => ({
                name: fund.giaType,
                type: fund.__GiaType,
            })),
            _.isEqual,
        );

        if (giaTypes.length > 0) {
            this.setState({ giaTypes, giaTypeSelected: giaTypes[0].name || '' });
        } else {
            this.setState({ giaTypes: [], giaTypeSelected: '' });
        }
    }

    componentDidUpdate(prevProps: PropsShape, prevState: StateShape) {
        const { giaFunds, client } = this.props;
        const { amount, percent, accountName } = this.state;

        if (prevProps.giaFunds !== giaFunds) {
            const giaTypes = _.uniqWith(
                _.map(giaFunds, fund => ({
                    name: fund.giaType,
                    type: fund.__GiaType,
                })),
                _.isEqual,
            );

            if (giaTypes.length > 0) {
                this.setState({ giaTypes, giaTypeSelected: giaTypes[0].name || '' });
            } else {
                this.setState({ giaTypes: [], giaTypeSelected: '' });
            }
        }

        const selectedAccount = _.find(client.accounts, { nickname: accountName });

        if (!selectedAccount) {
            return;
        }

        // Must make sure only one changed and not the other to avoid looping
        if (prevState.amount !== amount && prevState.percent === percent) {
            this.setState({ percent: (amount / selectedAccount.amount) * 100 });
        } else if (prevState.percent !== percent && prevState.amount === amount) {
            const roundedAmount = Math.round(selectedAccount.amount * (percent / 100));
            this.setState({ amount: roundedAmount });
        }
    }

    private NaNToZero = (value: number) => isNaN(value) ? 0 : value;

    private addNewGiaFund = (selectedAccount: repr.Account | undefined, accountIndex: number) => {
        const { changeFormValue, client, clientIndex, giaFunds, serviceFee } = this.props;
        const { amount, fundId, percent } = this.state;

        // Must select correct client and correct account using respective indicies
        // so react-final-form knows which attribute to update
        const selectedAccountFormName =
            `clients[${clientIndex}].accounts[${accountIndex}].holdings`;

        const oldHoldings = client.accounts[accountIndex].holdings;
        const selectedGiaFund = _.find(giaFunds, { _id: fundId });

        if (!selectedGiaFund || !selectedAccount) {
            return;
        }

        const existingHolding = _.find(oldHoldings, { fundUniqueId: selectedGiaFund._id });

        let data = oldHoldings;

        const holding: repr.Holding = {
            fundUniqueId: selectedGiaFund._id,
            profileId: selectedGiaFund.profileId,
            webProfileId: selectedGiaFund.webProfileId,
            fundServCode: selectedGiaFund.fundServCode,
            fundName: selectedGiaFund.fundName,
            className: selectedGiaFund.className,
            originalMer: selectedGiaFund.mer,
            serviceFee: serviceFee || 0,
            amount,
            percent: percent / 100,
            isGia: true,
            giaType: selectedGiaFund.giaType,
            giaTerm: selectedGiaFund.giaTerm,
            __GiaType: selectedGiaFund.__GiaType,
            holdingProductType: selectedGiaFund.productCategoryType
        };

        if (existingHolding) {
            holding.amount += existingHolding.amount;
            (holding.percent as number) += existingHolding.percent || 0;

            data = [..._.reject(oldHoldings, { fundUniqueId: selectedGiaFund._id }), holding];
        } else {
            data = [...oldHoldings, holding];
        }

        changeFormValue(selectedAccountFormName, data);

        const successMessage =
            t({
                key: 'proposalForm.giaSelector.successMessage',
                values: { name: client.accounts[accountIndex].nickname }
            });

        this.setState({
            accountName: '',
            amount: 0,
            percent: 0,
            successMessage
        });
    };

    render() {
        const { client, clientIndex, giaFunds } = this.props;
        const {
            accountName,
            fundId,
            giaTypes,
            giaTypeSelected,
            amount,
            percent,
            successMessage
        } = this.state;

        const filteredGiaFunds = _.filter(giaFunds, { giaType: giaTypeSelected });

        const selectedAccount = _.find(client.accounts, { nickname: accountName });
        const accountIndex = _.indexOf(client.accounts, selectedAccount);

        /* -- Check if account nicknames are unique and all are strings -- */
        // create list of all account nicknames
        const accountNamesList = _.map(client.accounts, 'nickname');

        // create a set using the names array which removes the duplicates
        const accountNamesSet = new Set(accountNamesList);

        const areAccountNamesValid =
            // if the set size is same as account names list, then there are no duplicates
            accountNamesSet.size === accountNamesList.length &&
            // check if account list has some element that is NOT a string
            !accountNamesList.some((name) => typeof name !== 'string');

        const maxNumberOfGiaFundsReached =
            selectedAccount &&
            selectedAccount.holdings.length >= MAX_NUMBER_OF_GIA_FUNDS_PER_ACCOUNT;

        const disableAddingGiaFunds =
            !accountName ||
            !selectedAccount ||
            !selectedAccount.amount ||
            !areAccountNamesValid ||
            maxNumberOfGiaFundsReached;

        return (
            <div css={giaSelectorStyles}>
                <h2 className='heading'>{t('proposalForm.giaSelector.heading')}</h2>

                <fieldset>
                    <legend className='sr-only'>
                        {t('proposalForm.giaSelector.heading')}
                    </legend>
                    <div className='input-row'>

                        <div className='label-input-pair'>
                            <label htmlFor={`gia-type-selector-${clientIndex}`}>
                                {t('proposalForm.giaSelector.type')}
                            </label>
                            <select
                                id={`gia-type-selector-${clientIndex}`}
                                value={giaTypeSelected}
                                onChange={v => this.setState({
                                    giaTypeSelected: v.currentTarget.value,
                                    fundId: '',
                                    successMessage: '',
                                })}
                            >
                                {giaTypes.map(t => (
                                    <option key={t.name} value={t.name}>
                                        {t.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className='label-input-pair'>
                            <label htmlFor={`gia-term-selector-${clientIndex}`}>
                                {t('proposalForm.giaSelector.term')}
                            </label>
                            <select
                                id={`gia-term-selector-${clientIndex}`}
                                value={fundId}
                                onChange={v => this.setState({
                                    fundId: v.currentTarget.value,
                                    successMessage: '',
                                })}
                            >
                                <option value=''>-</option>
                                {filteredGiaFunds.map(f => (
                                    <option key={f._id} value={f._id}>
                                        {f.giaTerm}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Select Account */}
                    <div className='input-row'>
                        <div className='label-input-pair'>
                            <label htmlFor={`gia-account-selector-${clientIndex}`}>
                                {t('proposalForm.poolSelector.selectAccount')}
                            </label>
                            <select
                                id={`gia-account-selector-${clientIndex}`}
                                value={accountName}
                                disabled={client.accounts.length === 0 || !areAccountNamesValid}
                                onChange={v => this.setState({
                                    accountName: v.currentTarget.value,
                                    amount: 0,
                                    percent: 0,
                                    successMessage: '',
                                })}
                            >
                                <option value=''>-</option>
                                {client.accounts.map((a, index) => {
                                    const type = a.accountType.toLowerCase();
                                    const accountLabel = t(`proposalForm.accountTypes.${type}`);

                                    return (
                                        <option key={`${index} - ${a.nickname}`} value={a.nickname}>
                                            {`${a.nickname} - ${accountLabel}`}
                                        </option>
                                    );
                                })}
                            </select>

                            {!areAccountNamesValid &&
                                <span className='error-message'>
                                    {t('errorMessages.accountNamesInvalidError')}
                                </span>
                            }

                            {maxNumberOfGiaFundsReached &&
                                <span className='error-message'>
                                    {t('errorMessages.maxGiaFundsError')}
                                </span>
                            }
                        </div>

                        <div className='label-input-pair'>
                            <label htmlFor={`gia-amount-input-${clientIndex}`}>
                                {t('proposalForm.amount')}
                            </label>
                            <input
                                id={`gia-amount-input-${clientIndex}`}
                                type='number'
                                disabled={disableAddingGiaFunds}
                                value={amount === 0 ? '' : amount.toFixed(0)}
                                onChange={e => this.setState({
                                    amount: this.NaNToZero(parseInt(
                                        e.currentTarget.value, 10,
                                    )),
                                    successMessage: '',
                                })}
                            />
                        </div>

                        <div className='label-input-pair'>
                            <label htmlFor={`gia-percent-input-${clientIndex}`}>
                                {t('proposalForm.percent')}
                            </label>
                            <input
                                id={`gia-percent-input-${clientIndex}`}
                                type='number'
                                disabled={disableAddingGiaFunds}
                                value={percent === 0 ? '' : percent.toFixed(0)}
                                onChange={e => this.setState({
                                    percent: this.NaNToZero(parseInt(
                                        e.currentTarget.value, 10,
                                    )),
                                    successMessage: '',
                                })}
                            />
                        </div>

                        <Button
                            type='button'
                            variant="primary"
                            className='add-pool-button'
                            disabled={!fundId || !amount || disableAddingGiaFunds}
                            onClick={() => this.addNewGiaFund(selectedAccount, accountIndex)}
                        >
                            {t('application.add')}
                        </Button>
                    </div>

                    {successMessage && <div>{successMessage}</div>}
                </fieldset>
            </div>
        );
    }
}
