import React from 'react';
import {
    setTranslations,
    setLocale,
    getLocale,
    getTranslations,
    l,
    t as translate,
} from 'react-i18nify';
import parse from 'html-react-parser';
import { baseFormats, frenchFormats } from './formats';

import enResources from './en-US.json';
import frResources from './fr-CA.json';

setTranslations({
    'en-US': {
        ...enResources,
        ...baseFormats,
    },
    'fr-CA': {
        ...frResources,
        ...frenchFormats,
    },
});

export default function t(
    value: string | number | { key: string, values: object },
    key?: string,
    nullguard?: string
) {
    if (!key) {
        if (typeof value === 'object') {
            // This method is used to insert values into the string template
            return translate(value.key, value.values);
        }

        const resourceString = translate(value);

        // if the resource string contains any html,
        // convert it to a React element
        if (/<\/?[a-z][\s\S]*>/i.test(resourceString)) {
            return parse(resourceString);
        }

        // otherwise just return the resource string
        return resourceString;
    }

    const locale = getLocale();
    const translations = getTranslations()[locale];

    if (nullguard && !value) {
        // todo: add better checks
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: translations.nullguards[nullguard],
                }}
            />
        );
    }

    // object from format list
    const options = translations[key];

    if (options.type === 'date' && typeof value === 'string') {
        if (/^\d{4}-\d{2}-\d{2}$/.test(value) ||
            /^\d{4}-\d{2}-\d{2}T00:00:00(\.0+)?Z$/.test(value)
        ) {
            // Formatting a string date like "2019-12-31" or "2019-12-31T00:00:00Z",
            // which are dates in UTC time. These format to Dec 30 in Toronto,
            // which is incorrect -- we actually want to format the date as-is
            // without time zone nonsense. So we need to pull apart the date and
            // set it manually to interpret it in the user's local time.
            // https://github.com/date-fns/date-fns/issues/489
            const matches = /^(\d{4})-(\d{2})-(\d{2})/.exec(value);

            if (matches) {
                const y = parseInt(matches[1]);
                const m = parseInt(matches[2]) - 1;
                const d = parseInt(matches[3]);
                const date = new Date(y, m, d);
                return l(date, options);
            }
        }
    }

    return l(value, options);
}

function getLocaleFromCookie() {
    // `document` can be undefined when doing SSR (e.g. for PDF report)
    if (typeof document !== 'undefined' && document !== null) {
        const cultureKey = '.AspNetCore.Culture';
        const parts = document.cookie.split(`${cultureKey}=`);

        if (parts.length > 1) {
            const culture = parts[1];

            if (culture.match('en-US')) {
                return 'en-US';
            } else if (culture.match('fr-CA')) {
                return 'fr-CA';
            }
        }
    }

    // Fallback locale is en-US
    return 'en-US';
}

setLocale(getLocaleFromCookie());

/** *** EXAMPLES *** **/
// Currency: {t(value, 'curMax2')}
// Date: {t(date, 'dateLong')}
// Nullguards: {t(h.fundPerformance5Yr, 'decMax1', 'dash')}

/** Replacing value in resource string **/
// example resource: "Delete %{holdingName} from account %{accountName}"
/* how to call the t function */
// {t({
//     key: 'proposalForm.alt.deleteAccountHoldingIcon',
//     values: {
//         accountName: account.nickname,
//         holdingName: holding.fundName,
//     }
// })}
