import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const performanceStyles = css`
    /* Highcharts uses tspans for some date labels,
    which end up ignoring their parent's font size */
    tspan {
        font-size: inherit;
    }

    .section-header {
        margin-bottom: 1em;

        .as-of-date {
            margin-bottom: 0.5em;
        }

        .header-disclaimer {
            font-size: ${theme['$font-size-small']};
        }
    }
`;

export default performanceStyles;
