/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import t from '../../localization/i18n';
import giaMarketingSectionStyles from './GiaMarketingSection.styles';

export default class GiaMarketingSection extends React.Component {
    render() {
        return (
            <section id="gia-marketing-section" className="page">
                <div css={giaMarketingSectionStyles} className="section main gia-marketing-section">
                    <h2 className="section-title cover-title">{t('report.giaMarketing.heading')}</h2>

                    <div className="subsection">
                        <h2 className="subtitle">
                            {t('report.giaMarketing.security.heading')}
                        </h2>
                        <p>{t('report.giaMarketing.security.text')}</p>
                    </div>

                    <div className="highlights-container">
                        <div className="highlight">
                            <img
                                src="img/piece-of-mind.png"
                                alt={t('report.giaMarketing.highlights.peaceOfMind.heading')}
                            />
                            <h2 className="subtitle">
                                {t('report.giaMarketing.highlights.peaceOfMind.heading')}
                            </h2>
                            <p>{t('report.giaMarketing.highlights.peaceOfMind.text')}</p>
                        </div>

                        <div className="highlight">
                            <img
                                src="img/simplicity.png"
                                alt={t('report.giaMarketing.highlights.simplicity.heading')}
                            />
                            <h2 className="subtitle">
                                {t('report.giaMarketing.highlights.simplicity.heading')}
                            </h2>
                            <p>{t('report.giaMarketing.highlights.simplicity.text')}</p>
                        </div>

                        <div className="highlight">
                            <img
                                src="img/flexibility.png"
                                alt={t('report.giaMarketing.highlights.flexibility.heading')}
                            />
                            <h2 className="subtitle">
                                {t('report.giaMarketing.highlights.flexibility.heading')}
                            </h2>
                            <p>{t('report.giaMarketing.highlights.flexibility.text')}</p>
                        </div>
                    </div>

                    <div className="subsection">
                        <h2 className="subtitle">
                            {t('report.giaMarketing.options.heading')}
                        </h2>

                        <table className="options-table">
                            <thead>
                                <tr>
                                    <th className="bold">
                                        {t('report.giaMarketing.options.headers.term')}
                                    </th>
                                    <th className="bold">
                                        {t('report.giaMarketing.options.headers.features')}
                                    </th>
                                    <th className="bold">
                                        {t('report.giaMarketing.options.headers.howYouBenefit')}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td className="bold">
                                        {t('report.giaMarketing.options.gia.heading')}
                                    </td>
                                    <td>{t('report.giaMarketing.options.gia.features')}</td>
                                    <td>{t('report.giaMarketing.options.gia.howYouBenefit')}</td>
                                </tr>

                                <tr>
                                    <td className="bold">
                                        {t('report.giaMarketing.options.dia.heading')}
                                    </td>
                                    <td>{t('report.giaMarketing.options.dia.features')}</td>
                                    <td>{t('report.giaMarketing.options.dia.howYouBenefit')}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="annotations">
                            <p>{t('report.giaMarketing.options.annotation')}</p>
                        </div>
                    </div>

                    <div className="subsection">
                        <h2 className="subtitle">
                            {t('report.giaMarketing.volumeBonus.heading')}
                        </h2>
                        <p>{t('report.giaMarketing.volumeBonus.text')}</p>
                    </div>

                    <div className="subsection">
                        <h2 className="subtitle">
                            {t('report.giaMarketing.taxAdvantages.heading')}
                        </h2>
                        <p>{t('report.giaMarketing.taxAdvantages.text')}</p>
                    </div>

                    <p>{t('report.giaMarketing.interestRateInformation')}</p>

                    <div className="annotations disclaimer">
                        <p>{t('report.giaMarketing.disclaimer')}</p>
                    </div>
                </div>
            </section>
        );
    }
}
