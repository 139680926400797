/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import uuidv4 from 'uuid/v4';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-focus';
import { connect } from 'react-redux';
import Spinner from 'reactstrap/lib/Spinner';
import { ThunkDispatch } from '../../actions/utils';
import { fetchProposal } from '../../actions/proposals';
import { getLocale } from 'react-i18nify';
import { StateShape as ReduxStateShape } from '../../reducers';
import t from '../../localization/i18n';
import * as repr from '../../api/types';
import Button from '../Button';
import { FieldTextRequired, WhenFieldChanges } from '../shared/CustomFormFields';
import ModalBase from '../shared/ModalBase';
import clientFormStyles from './ClientDetailsForm.styles';

interface PropsShape {
    isVisible: boolean;
    toggleModal: () => void;
    saveProposal: (proposal: Partial<repr.ProposalDetailed>) => Promise<void>;
    investmentType?: repr.investmentTypes // needed for the payload to create a new client
    proposalId?: string;

    // from store
    dispatch: ThunkDispatch
    proposal: repr.ProposalDetailed | null
}

export interface ClientData {
    proposalType: 'Individual' | 'Household';
    advisorFirstName: string;
    advisorLastName: string;
    proposalName: string;
    clients: repr.ClientDetailed[];
}

const MAX_NUMBER_OF_MEMBERS = 10;

// these are keys used to access translations
const salutations = ['', 'mr', 'mrs', 'miss', 'ms', 'dr'];

const clientDefaultValues = {
    salutation: '',
    firstName: '',
    lastName: '',
    amount: 0,
    latestReportId: '',
    accounts: [],
};

const focusOnError = createDecorator();

const ClientDetailsForm = (props: PropsShape) => {
    const { proposalId, proposal, dispatch } = props;

    // only show loading spinner if a proposal needs to be fetched
    const [isLoading, setIsLoading] = useState(!!proposalId);

    const errorMessages: string[] = [];

    if (proposalId && !proposal) {
        errorMessages.push(t('clientList.clientDetails.fetchDetailsFailed'));
    }

    useEffect(() => {
        if (proposalId) {
            const locale = getLocale();
            dispatch(fetchProposal(proposalId, locale))
                .then(() => setIsLoading(false))
                .catch((e) => {
                    setIsLoading(false);
                    return console.error(`Failed to fetch proposal ${proposalId}, error: ${e}`);
                });
        }
    }, [proposalId, dispatch]);

    let initialValues: ClientData | repr.ProposalDetailed = {
        proposalType: 'Individual',
        advisorFirstName: '',
        advisorLastName: '',
        proposalName: '',
        clients: [{ clientId: uuidv4(), ...clientDefaultValues }],
    };

    // avoid showing data from a different proposal before current one is loaded
    // fields will show default initial values for new client until loaded
    if (proposalId && proposal && proposal.id === proposalId) {
        initialValues = _.cloneDeep(proposal);
    }

    const handleSubmit = (values: ClientData | repr.ProposalDetailed) => {
        let payload = values;

        if (!proposalId) { // if a new proposal/client is being created
            payload = {
                proposalName: values.proposalName,
                advisorFirstName: values.advisorFirstName,
                advisorLastName: values.advisorLastName,
                amount: 0,
                investmentType: props.investmentType,
                proposalType: values.proposalType,
                includeGIA: false,
                isFeeBased: false,
                includeServiceFee: false,
                includeFundProfiles: false,
                overrideMinimums: false,
                clients: values.clients,
            };
        }

        props.saveProposal(payload)
            // close modal if validation is successful AND the api call is successful
            .then(() => props.toggleModal())
            .catch(() => props.proposalId
                ? console.log('editing client faild')
                : console.log('creating new client failed'));
    };

    const memberList = (
        values: ClientData | repr.ProposalDetailed,
        push: (key: string, value: repr.ClientDetailed) => void,
    ) => (
        <>
            <div className="members">
                <FieldArray name="clients">
                    {({ fields }) => fields.map((m, index) => {
                        const member = fields.value[index];
                        const memberFullName = `${member.firstName} ${member.lastName}`;

                        return (
                            <div className="input-row" key={`${m}`}>

                                {/* Salutations dropdown */}
                                {/* The field below needs the parse function because the
                                    saved value can be an empty string and final form removes
                                    empty values by default, setting the field to null */}
                                {/* https://github.com/final-form/react-final-form/issues/130 */}
                                <div className="label-input-pair">
                                    <label htmlFor={`${m}.salutation`}>
                                        {t('clientList.clientDetails.salutation')}
                                    </label>
                                    <Field
                                        name={`${m}.salutation`}
                                        component="select"
                                        parse={value => value}
                                    >
                                        {salutations.map(s => (
                                            <option key={`${m}${s}`} value={s}>
                                                {t(`salutations.${s}`) || '-'}
                                            </option>
                                        ))}
                                    </Field>
                                </div>

                                <FieldTextRequired
                                    name={`${m}.firstName`}
                                    labelKey="application.firstName"
                                />

                                <FieldTextRequired
                                    name={`${m}.lastName`}
                                    labelKey="application.lastName"
                                />

                                {/* if there is onlu one member, not allowed to delete */}
                                {fields.length !== 1 && (
                                    <Button
                                        styles={{ marginTop: '4%', paddingLeft: '0' }}
                                        variant="icon"
                                        type="button"
                                        onClick={() => fields.remove(index)}
                                        tooltip={
                                            {
                                                id: `toolTip--${proposalId}-${index}`,
                                                text: t('application.delete')
                                            }
                                        }
                                    >
                                        <span className="icon icon--delete" aria-hidden="true"/>
                                        <span className="sr-only">
                                            {t({
                                                key: 'clientList.alt.deleteMember',
                                                values: {
                                                    name: memberFullName || '',
                                                },
                                            })}
                                        </span>
                                    </Button>
                                )}
                            </div>
                        );
                    })}
                </FieldArray>
            </div>

            {/* Button to add household members */}
            {values.proposalType === 'Household' && (
                <>
                    <Button
                        type="button"
                        variant="secondary"
                        disabled={values.clients.length >= MAX_NUMBER_OF_MEMBERS}
                        onClick={() => push('clients', {
                            clientId: uuidv4(),
                            ...clientDefaultValues,
                        })}
                    >
                        <span
                            className="btn-icon icon icon--plus-circle-white"
                            aria-hidden="true"
                        />
                        <span className="sr-only">
                            {t('clientList.alt.addMember')}
                        </span>
                        {t('clientList.clientDetails.addMember')}
                    </Button>

                    {values.clients.length >= MAX_NUMBER_OF_MEMBERS && (
                        <span>
                            {' '}{t('clientList.clientDetails.maxMembers')}
                        </span>
                    )}
                </>
            )}
        </>
    );

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            decorators={[focusOnError]}
            render={(
                {
                    handleSubmit,
                    form: {
                        mutators: { push },
                    },
                    // injected from final-form-arrays above
                    pristine,
                    form,
                    submitting,
                    values,
                }) => {
                let headerText = t('clientList.clientDetails.editHeading');

                if (!proposalId && props.investmentType) {
                    // if the proposal ID does not exist, a client is being created
                    headerText = `${t('clientList.clientDetails.createHeading')} ${
                        t(`clientList.${props.investmentType.toLowerCase()}`)}`;
                } else if (!proposalId) {
                    // in case the investment type is not passed
                    headerText = t('clientList.clientDetails.createHeading');
                }

                const header = <React.Fragment>{headerText}</React.Fragment>;

                const formFields = (
                    <form id="add-client-form" onSubmit={handleSubmit}>
                        {/* Client Type */}
                        <div className="client-type-toggle input-section">
                            <label>
                                <Field
                                    name="proposalType"
                                    component="input"
                                    type="radio"
                                    value="Individual"
                                />
                                {t('clientList.individual')}
                            </label>
                            <label>
                                <Field
                                    name="proposalType"
                                    component="input"
                                    type="radio"
                                    value="Household"
                                />
                                {t('clientList.household')}
                            </label>

                            <WhenFieldChanges
                                field="proposalType"
                                becomes="Individual"
                                set="clients"
                                // keep the first client's details when switching to individual
                                to={[values.clients[0]]}
                            />
                        </div>

                        {/* Advisor Information */}
                        <div className="input-section">
                            <h5 className="subtitle">
                                {t('clientList.clientDetails.advisorInformation')}
                            </h5>

                            <div className="input-row">
                                <FieldTextRequired
                                    name="advisorFirstName"
                                    labelKey="application.firstName"
                                />
                                <FieldTextRequired
                                    name="advisorLastName"
                                    labelKey="application.lastName"
                                />
                            </div>
                        </div>

                        {/* Client List */}
                        <div className="input-section">
                            <h5 className="subtitle">
                                {t('clientList.clientDetails.clientInformation')}
                            </h5>

                            {memberList(values, push)}
                        </div>

                        {/* Household Name */}
                        {values.proposalType === 'Household' && (
                            <div className="input-section">
                                <FieldTextRequired
                                    name="proposalName"
                                    labelKey="clientList.clientDetails.householdName"
                                />
                            </div>
                        )}

                        {/* -- uncomment for easier debugging of form values -- */}
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </form>
                );

                const submitButtons = (
                    <>
                        <Button onClick={props.toggleModal}>{t('application.cancel')}</Button>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={submitting || pristine}
                            onClick={() => form.submit()}
                        >
                            {proposalId
                                ? t('clientList.clientDetails.save')
                                : t('clientList.clientDetails.create')
                            }
                        </Button>
                    </>
                );

                let modalBody = null;

                if (isLoading) {
                    modalBody = (
                        <div className="loading-spinner">
                            <Spinner color="dark"/>{'  Loading'}
                        </div>
                    );
                } else if (errorMessages.length) {
                    modalBody = errorMessages.map(e => <span key={e}>{e}</span>);
                }

                return (
                    <ModalBase
                        styles={clientFormStyles}
                        header={header}
                        body={modalBody || formFields}
                        footer={submitButtons}
                        isVisible={props.isVisible}
                        toggleModal={props.toggleModal}
                    />
                );
            }}
        />
    );
};

const mapStateToProps = (state: ReduxStateShape) => {
    const proposal = state.app.proposal;

    return { proposal };
};

export default connect(mapStateToProps)(ClientDetailsForm);
