/** @jsx jsx */
import { jsx } from '@emotion/core';

interface PropsShape {
    title: string | undefined
    children: any
}

// This table wrapper is to ensure that when the contents (`children`)
// overflow across multiple pages, the title is repeated across each page
// and displayed above the contents. PDFreactor automatically repeats table
// headers for tables that break across pages, so that is why this works.
export default function ClientWrapper(props: PropsShape) {
    return (
        <table css={{ width: '100%' }}>
            <thead>
                <tr>
                    <th className='subtitle'>{props.title || ''}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{props.children}</td>
                </tr>
            </tbody>
        </table>
    );
}
