/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as repr from '../../api/types';
import ImportantInfoPage from './ImportantInfoPage';
import Disclosures from '../ReportShared/Disclosures';


interface PropsShape {
    report: repr.Report;
}
export default function EndPage(props: PropsShape) {
    const {investmentType, productType} = props.report
    return (
        <section
            className="page-full"
            css={css`
                page: noRunningElements;
            `}
        >   
            {investmentType === 'Seg' ?  <Disclosures productType={productType} /> : <ImportantInfoPage report={props.report} />}
        </section>
    );
}
