/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import benefitsStyles from './Benefits.styles';

interface PropsShape {
    report: repr.Report;
}

// this types are used in the locale logic and are case sensitive
type fundType = 'investmentPlus' | 'Seg' | 'MMF';

export default class Benefits extends React.Component<PropsShape> {
    private createFootAnnotation(annotationName: string[]) {
        return annotationName.map((ann, index) => {
            return (
                <p key={index}>
                    <sup>{index + 1}</sup> {t(`report.benefits.stats.${ann}.annText`)}
                </p>
            );
        });
    }

    render() {
        const { investmentType, includeGIA } = this.props.report;
        const annNames = ['capitalization', 'assetManagement'];
        const fundType: fundType = includeGIA ? 'investmentPlus' : investmentType;

        return (
            <section id="benefits" className="page">
                <div css={benefitsStyles} className="section main benefits-section">
                    <h2 className="section-title cover-title">{t('report.benefits.heading')}</h2>

                    {includeGIA
                        ? t(`report.benefits.mainTextInvesmentPlus`)
                        : t(`report.benefits.mainText${investmentType}`)}

                    <div className="statistics-container">
                        <div className="stats-row">
                            <div className="statistic">
                                <p className="primary">
                                    {t(`report.benefits.stats.${fundType}.firstColumn.main`)}
                                </p>
                                <p className="secondary">
                                    {t(`report.benefits.stats.${fundType}.firstColumn.bold`)}
                                </p>
                            </div>
                            <div className="statistic">
                                <p className="primary">
                                    {t(`report.benefits.stats.${fundType}.secondColumn.main`)}
                                </p>
                                <p className="secondary">
                                    {t(`report.benefits.stats.${fundType}.secondColumn.bold`)}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="annotations">
                        <p key={'*'}>
                            <sup>{'*'}</sup>manulifeim.com
                        </p>
                        {this.createFootAnnotation(annNames)}
                    </div>
                </div>
            </section>
        );
    }
}
