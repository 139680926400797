/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import React from 'react';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import ClientWrapper from './ClientWrapper';

interface PropsShape {
    report: repr.Report;
    fundDisclosures: repr.FundDetailed[];
}

interface FundDisclosuresMap{
    [key: string] : number
}

export default class FundPerformance extends React.Component<PropsShape> {
    private buildPerformanceTable = (
        accountName: string,
        holdings: repr.ReportHolding[],
        fundDisclosures: repr.FundDetailed[],
        fundDisclosuresMap: FundDisclosuresMap
    ) => {
        return (
        <table className="table">
            <caption>
                <p className='account-title'>{accountName}</p>

                <p className="table-title">
                    {t('report.fundPerf.tableHeading')}
                </p>
            </caption>
            <thead>
                <tr>
                    <th className='fund-name'>{t('application.fund')}</th>
                    <th>{t('report.pytd')}</th>
                    <th>{t('report.p1yr')}</th>
                    <th>{t('report.p3yr')}</th>
                    <th>{t('report.p5yr')}</th>
                    <th>{t('report.psi')}</th>
                    <th>{t('report.inceptionDate')}</th>
                </tr>
            </thead>

            <tbody>
                {holdings.map((h,idx) => (
                    <tr key={h.fundUniqueId}>
                        <td className='left fund-name'>{h.fundName}<sup>{(fundDisclosures.find(f => h.fundName === f.fundName)) && fundDisclosuresMap[h.fundName]}</sup></td>
                        <td>{t(h.fundPerformanceYtd * 100, 'decMax2', 'dash')}</td>
                        <td>{t(h.fundPerformance1Yr * 100, 'decMax2', 'dash')}</td>
                        <td>{t(h.fundPerformance3Yr * 100, 'decMax2', 'dash')}</td>
                        <td>{t(h.fundPerformance5Yr * 100, 'decMax2', 'dash')}</td>
                        <td>{t(h.fundPerformanceSinceInception * 100, 'decMax2', 'dash')}</td>
                        <td>{t(h.fundPerformanceInceptionDate, 'dateLong')}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
                }

    render() {
        const { report, fundDisclosures } = this.props;
        const fundNameReducer = (acc: FundDisclosuresMap, curr: repr.FundDetailed, idx: number) : FundDisclosuresMap => {
            if(curr.fundSpecificDisclosures !== null){
                acc[`${curr["fundName"]}`] = ++idx;
            }
            return acc;
        }
        const fundDisclosuresMap = fundDisclosures.reduce(fundNameReducer, {});

        // Report is always assumed to have at least one client and a valid report
        // will always have holdings for each account, which is why the only check
        // here is for accounts
        const performanceAsOfDate = report.clients[0].accounts.length > 0
            ? report.clients[0].accounts[0].holdings[0].compoundReturnsEffectiveDate
            : null;
        return (
            <section className="page">
                <div id="fund-performance" className="section main">
                    <h2 className='section-title cover-title'>{t('report.fundPerf.heading')}</h2>

                    {performanceAsOfDate && (
                        <span className='as-of-date'>
                            {t({
                                key: 'report.performanceAsOf',
                                values: { date: t(performanceAsOfDate, 'dateLong') }
                            })}
                        </span>
                    )}

                    {report.clients.map((c, i) => {
                        const isGiaClient =
                            !_.some(c.accounts, a => a.holdings.filter(h => !h.isGia).length !== 0);

                        if (isGiaClient) {
                            return null;
                        }

                        return (
                            <ClientWrapper key={c.clientId} title={c.fullName}>
                                {c.accounts.map(a => {
                                    const holdings = a.holdings.filter(h => !h.isGia);

                                    if (holdings.length === 0) {
                                        return null;
                                    }

                                    return (
                                        <div key={a.nickname} className="table-container">
                                            {this.buildPerformanceTable(
                                                a.nickname,
                                                holdings,
                                                fundDisclosures,
                                                fundDisclosuresMap
                                            )}
                                        </div>
                                    );
                                })}

                                {i !== report.clients.length - 1 && (
                                    <div className="divider" />
                                )}
                            </ClientWrapper>
                        );
                    })}
                </div>
            </section>
        );
    }
}
