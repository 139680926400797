import Resource, * as api from '../api/api';
import makeRequest, { RestSideEffect } from './utils';
import { Action, ActionWithPayload, ActionWithError } from './Action';

export const SET_LOCALE = 'SET_LOCALE';
export type SET_LOCALE = typeof SET_LOCALE;

export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export type SET_LOCALE_SUCCESS = typeof SET_LOCALE_SUCCESS;

export const SET_LOCALE_FAILED = 'SET_LOCALE_FAILED';
export type SET_LOCALE_FAILED = typeof SET_LOCALE_FAILED;

type SetLocaleAction = Action<SET_LOCALE>;
type SetLocaleSuccessAction =
    ActionWithPayload<SET_LOCALE_SUCCESS, Resource<{}>>;
type SetLocaleFailedAction = ActionWithError<SET_LOCALE_FAILED, Error>;

export type LocalizationActionTypes =
    | SetLocaleAction
    | SetLocaleSuccessAction
    | SetLocaleFailedAction

export function setLocale(
    locale: string,
    returnUrl?: string
): RestSideEffect<{}> {
    return (
        dispatch => dispatch(
            makeRequest(
                SET_LOCALE,
                SET_LOCALE_SUCCESS,
                SET_LOCALE_FAILED,
                ({ authToken }) => api.postLocalization(authToken, locale, returnUrl)
            )
        )
    );
}
