/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import t from '../../localization/i18n';
import * as repr from '../../api/types';
import programDetailsStyles from './ProgramDetails.styles';
import MMFProgramDetails from './MMFProgramDetails';
import { isGif } from '../helpers';

interface PropsShape {
    investmentType: repr.investmentTypes;
    productType: repr.productType;
    report: repr.Report;
    variant: 'web' | 'pdf';
}

export default class ProgramDetails extends React.Component<PropsShape> {
    render() {
        const { investmentType, productType, report, variant } = this.props;
        let holdings: any = [];
        report.clients.forEach(c => c.accounts.forEach(a => a.holdings.forEach(h => holdings.push(h))));
        const holdingTypes = holdings.map((holding: { holdingProductType: repr.HoldingProductType }) => holding.holdingProductType);

        let subsections;

        if (investmentType === 'MMF') {
            subsections = [];
            (holdingTypes.includes("mpip") && subsections.push("mpip"));
            (holdingTypes.includes("mmf") && subsections.push("mmf"));
            (holdingTypes.includes("etf") && subsections.push("etf"));
            (holdingTypes.includes("aa") && subsections.push("aa"));
        } else {
            if(isGif(productType)){
                subsections = [
                    'exclusiveInvestmentsGIF',
                    'powerOfTwoGIF',
                    'creditorProtectionGIF',
                    'bypassEstateGIF',
                ];
            }else{
                subsections = [
                    'exclusiveInvestmentsSeg',
                    'powerOfTwo',
                    'mfr',
                    'creditorProtection',
                    'bypassEstate',
                    'reporting',
                ];
            }
        }

        return (
            <div id="program-details">
                { investmentType === "MMF" ? subsections.map(s => <MMFProgramDetails variant={variant} holdingProductType={s} />)
                    : (<section id="program-details" className="page">
                    <div css={programDetailsStyles} className="section main program-details-section">
                        <h2 className="section-title cover-title">{isGif(productType) ? t('report.programDetails.headingGIF') : t('report.programDetails.heading')}</h2>

                        {investmentType === "Seg" && 
                            <p className="program-details-summary">
                                {isGif(productType) ? t('report.programDetails.summaryGIF') : t(`report.programDetails.summarySeg`)}
                            </p>
                        }

                        {subsections.map((s: string) => (
                            <div key={s} className="subsection">
                                <h2 className="subtitle">
                                    {t(`report.programDetails.${s}.heading`)}
                                </h2>
                                <p>{t(`report.programDetails.${s}.text`)}</p>
                            </div>
                        ))}

                        {/* Only Seg has the 'The ability to bypass the estate' detail */}
                        {investmentType === 'Seg' && (
                            <div className="annotations">
                                <p>{t('report.programDetails.powerOfTwo.annText')}</p>
                                {(isGif(productType)) ? <>
                                    <p>{t('report.programDetails.mfr.annText')}</p>
                                    <p>{t('report.programDetails.creditorProtection.annText')}</p>
                                    <p>{t('report.programDetails.bypassEstate.annText')}</p>
                                    </> :
                                    <>
                                        <p>{t('report.programDetails.creditorProtectionGIF.annText')}</p>
                                        <p>{t('report.programDetails.bypassEstateGIF.annText')}</p>
                                    </>
                                }
                            </div>
                        )}
                    </div>
                </section>)
                }   
            </div>
        );
    }
}
