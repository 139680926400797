/** @jsx jsx */
import { jsx } from '@emotion/core';
import { getLocale } from 'react-i18nify';

interface PropsShape {
    id: string
    data: {
        x: number;
        y: number;
        marker: {
            enabled: boolean;
        };
    }[]
}

/**
 * Renders a script tag containing holdings data, and a placeholder
 * element for the growth chart. The chart will be rendered when
 * `src/pdf-js/growth-chart.js` is loaded and executed on
 * page load.
 */
export default function GrowthChart(props: PropsShape) {
    const chartId = props.id;

    return (
        <div
            id={`${chartId}`}
            className="growth-chart-container"
            css={{ marginBottom: '0.25in' }}
        >
            <div
                id={`${chartId}-growth-chart`}
                css={({
                    width: '7.5in',
                    height: '2.5in'
                })}
            />

            <script
                id={`${chartId}-growth-json-data`}
                type="text/json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(props.data) }}
            >
            </script>
            <script
                id={`${chartId}-locale`}
                dangerouslySetInnerHTML={{ __html: getLocale() }}
            />

        </div>
    );
}
