/** @jsx jsx */
import { jsx } from '@emotion/core';

interface PropsShape {
    styles?: object
    children: HTMLElement | string
}

/**
 * Renders a  report section title.
 */
export default function SectionTitle(props: PropsShape) {
    const styles = {
        fontFamily: 'Manulife JH Sans',
        fontSize: '12pt',
        fontWeight: 600,
        ...props.styles
    };
    return <h2 css={styles}>{props.children}</h2>;
}
