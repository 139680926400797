import { StateShape as AppStateShape, appReducer } from './app';

export interface StateShape {
    app: AppStateShape;
}

export const reducers = {
    app: appReducer
};

export default reducers;
