/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { getLocale } from 'react-i18nify';
import * as repr from '../../../api/types';
import * as finalForm from 'final-form';
import { StateShape as ReduxStateShape } from '../../../reducers';
import { ThunkDispatch } from '../../../actions/utils';
import { fetchFunds, fetchGiaFunds, fetchEtfFunds } from '../../../actions/funds';
import { WhenFieldChanges } from '../../shared/CustomFormFields';
import AccountSection from './AccountSection';
import PoolSelector from './PoolSelector';
import GiaSelector from './GiaSelector';
import HoldingsSection from './HoldingsSection';
import clientSectionStyles from './ClientSection.styles';
import { isGif } from '../../helpers';

interface PropsShape {
    dispatch: ThunkDispatch;
    values: repr.ProposalDetailed;
    selectedClient: repr.ClientDetailed;
    funds: repr.Fund[];
    etfFunds: repr.Fund[];
    giaFunds: repr.Fund[];
    errors: { [k: string]: string };
    changeFormValue: (name: string, value?: any) => void;
    save: (values: repr.ProposalDetailed, form: finalForm.FormApi<repr.ProposalDetailed>) => any;
    form: finalForm.FormApi<repr.ProposalDetailed>
}

interface StateShape {
    currentLocale: string;
}

const THRESHOLD_ACCOUNT_AMOUNT = 250000;
const MINIMUM_AMOUNT_BELOW_THRESHOLD = 100000;
const MINIMUM_SEG_AMOUNT_ABOVE_THRESHOLD = 1000;
const MINIMUM_MMF_AMOUNT_ABOVE_THRESHOLD = 0;
const MINIMUM_ACCOUNT_AMOUNT_GIF = 2500;
const MINIMUM_ACCOUNT_AMOUNT_GIF_OVERRIDE = 1000;
const MINIMUM_POOL_AMOUNT_GIF_OVERRIDE = 100;
const MINIMUM_POOL_AMOUNT_GIF = 500;

class ClientSpecificFormSection extends React.Component<PropsShape, StateShape> {
    state: StateShape = {
        currentLocale: '',
    };

    componentDidMount() {
        const { dispatch, values } = this.props;
        const locale = getLocale();
        dispatch(fetchFunds(values.investmentType, values.isFeeBased, locale));
        dispatch(fetchEtfFunds(locale));
        dispatch(fetchGiaFunds(locale));

        // Set component's locale to app's locale on load
        this.setState({ currentLocale: locale });
    }

    componentDidUpdate(prevProps: PropsShape) {
        const { dispatch, values, save, form } = this.props;
        const { currentLocale } = this.state;
        const locale = getLocale();

        // Refetch funds if fee based property changes or if app's locale changes
        if (
            prevProps.values.isFeeBased !== values.isFeeBased ||
            currentLocale !== locale ||
            prevProps.values.productType !== values.productType
        ) {
            dispatch(fetchFunds(values.investmentType, values.isFeeBased, locale));
            dispatch(fetchEtfFunds(locale));
            this.setState({ currentLocale: locale });
        }

        if(prevProps.values.overrideMinimums !== values.overrideMinimums){
            save(values, form);
        }

        if (currentLocale !== locale) {
            dispatch(fetchGiaFunds(locale));
            this.setState({ currentLocale: locale });
        }
    }

    private validateAccountOrPoolValue = (value: number, isPoolValue: boolean) => {
        const { values } = this.props;

        let totalAccountValue = values.amount;
        let minimumValue;

        if (values.investmentType === 'MMF') {
            /*Logic for considering only MPIP allocation total TBD after confirming with client*/

            totalAccountValue = _.sum(
                _.map(values.clients, (c) =>
                    _.sum(
                        _.map(c.accounts, (a) =>
                            _.sum(
                                _.map(
                                    a.holdings.filter(
                                        (h) =>
                                            h.holdingProductType === 'mpip' ||
                                            h.holdingProductType === 'mpip75' ||
                                            h.holdingProductType === 'mpip100'
                                    ),
                                    'amount'
                                )
                            )
                        )
                    )
                )
            );
            const hasMPIPHoldings = _.some(values.clients, (c) =>
                _.some(c.accounts, (a) =>
                    _.some(a.holdings, 
                        (h) =>
                            h.holdingProductType === 'mpip' ||
                            h.holdingProductType === 'mpip75' ||
                            h.holdingProductType === 'mpip100'
                    )
                )
            );
            //Use || (values.amount >= THRESHOLD_ACCOUNT_AMOUNT) if both cases need to be checked
            minimumValue = MINIMUM_AMOUNT_BELOW_THRESHOLD;
            if (values.overrideMinimums || totalAccountValue >= THRESHOLD_ACCOUNT_AMOUNT) {
                minimumValue = MINIMUM_MMF_AMOUNT_ABOVE_THRESHOLD;
            }
            if (!hasMPIPHoldings) {
                minimumValue = 0;
            }
        } else {
            if (!isGif(values.productType)) {
                minimumValue = MINIMUM_AMOUNT_BELOW_THRESHOLD;
                if (values.overrideMinimums || totalAccountValue >= THRESHOLD_ACCOUNT_AMOUNT) {
                    minimumValue = MINIMUM_SEG_AMOUNT_ABOVE_THRESHOLD;
                }
            } else {
                const fundMinimum = values.overrideMinimums
                    ? MINIMUM_POOL_AMOUNT_GIF_OVERRIDE
                    : MINIMUM_POOL_AMOUNT_GIF;
                const accountMinimum = values.overrideMinimums
                    ? MINIMUM_ACCOUNT_AMOUNT_GIF_OVERRIDE
                    : MINIMUM_ACCOUNT_AMOUNT_GIF;
                minimumValue = isPoolValue ? fundMinimum : accountMinimum;
            }
        }
        return { isValid: value >= minimumValue, minimumAmount: minimumValue };
    };

    render() {
        const {
            values,
            selectedClient,
            funds,
            giaFunds,
            etfFunds,
            changeFormValue,
            errors
        } = this.props;

        return (
            <div css={clientSectionStyles}>
                {values.clients.map((c, clientIndex) => (
                    <div
                        key={c.clientId}
                        className={`section ${
                            selectedClient && c.clientId === selectedClient.clientId ? '' : 'hide'
                        }`}
                    >
                        <AccountSection
                            client={c}
                            clientIndex={clientIndex}
                            validateAccountValue={this.validateAccountOrPoolValue}
                            changeFormValue={changeFormValue}
                            investmentType={values.investmentType}
                        />

                        {/* Update proposal amount when any client's amount changes */}
                        <WhenFieldChanges
                            field={`clients[${clientIndex}].amount`}
                            becomes={c.amount}
                            set={'amount'}
                            to={_.sum(_.map(values.clients, 'amount'))}
                        />

                        <PoolSelector
                            client={c}
                            serviceFee={values.serviceFee}
                            clientIndex={clientIndex}
                            funds={funds}
                            etfFunds={etfFunds}
                            changeFormValue={changeFormValue}
                            investmentType={values.investmentType}
                            productType={values.productType}
                            isFeeBased={values.isFeeBased}
                        />
                        <p></p>

                        {values.includeGIA && (
                            <>
                                <GiaSelector
                                    client={c}
                                    serviceFee={values.serviceFee}
                                    clientIndex={clientIndex}
                                    giaFunds={giaFunds}
                                    changeFormValue={changeFormValue}
                                />
                            </>
                        )}

                        <HoldingsSection
                            client={c}
                            clientIndex={clientIndex}
                            includeServiceFee={values.includeServiceFee}
                            includeGia={values.includeGIA}
                            serviceFee={values.serviceFee}
                            errors={errors}
                            validatePoolValue={this.validateAccountOrPoolValue}
                            investmentType={values.investmentType}
                        />
                    </div>
                ))}
            </div>
        );
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const funds = state.app.funds;
    const giaFunds = state.app.giaFunds;
    const etfFunds = state.app.etfFunds;
    return { funds, giaFunds, etfFunds };
};

export default connect(mapStateToProps)(ClientSpecificFormSection);
