import * as repr from '../api/types';
import { Action } from './Action';

export const SET_INVESTMENT_TYPE_TO_MMF = 'SET_INVESTMENT_TYPE_TO_MMF';
export type SET_INVESTMENT_TYPE_TO_MMF = typeof SET_INVESTMENT_TYPE_TO_MMF;

export const SET_INVESTMENT_TYPE_TO_SEG = 'SET_INVESTMENT_TYPE_TO_SEG';
export type SET_INVESTMENT_TYPE_TO_SEG = typeof SET_INVESTMENT_TYPE_TO_SEG;

type SetInvestmentTypeToMMF = Action<SET_INVESTMENT_TYPE_TO_MMF>;
type SetInvestmentTypeToSeg = Action<SET_INVESTMENT_TYPE_TO_SEG>;

export type AppActionTypes =
    | SetInvestmentTypeToMMF
    | SetInvestmentTypeToSeg

export function setInvestmentType(investmentType: repr.investmentTypes) {
    const action = investmentType === 'Seg'
        ? SET_INVESTMENT_TYPE_TO_SEG
        : SET_INVESTMENT_TYPE_TO_MMF;
    return { type: action };
}
