import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const giaMarketingSectionStyles = css`
    p {
        margin-bottom: 1em;
        line-height: 1.2;
    }

    .subtitle {
        margin-bottom: 0.5em;
    }

    .highlights-container {
        display: flex;

        .highlight {
            flex-basis: 0;
            flex-grow: 1;
            padding: 0 0.25em;

            img {
                height: 3.25em;
                width: 3.25em;
                margin-bottom: 0.5em;
            }
        }
    }

    .options-table {
        margin-bottom: 1em;

        th, td {
            text-align: left;
        }

        thead {
            tr {
                border-bottom: 2px solid ${theme['$color-grey']};
            }

            th {
                padding-bottom: 5px;
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid black;
            }

            td {
                padding: 1em 2em 1em 0;
                vertical-align: middle;
            }
        } 
    }

    .annotations.disclaimer{
        bottom: 20px;
    }
`;

export default giaMarketingSectionStyles;
