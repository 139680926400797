import { css } from '@emotion/core';
// import theme from '../../styles/theme.web.json';

const comparableFundsStyles = css`
    .disclaimer-container {
        margin-top: 3em;

        .annotations {
            margin-top: 3.5em;
            
            p {
                margin-bottom: 10px;
            }
        }
    }
`;

export default comparableFundsStyles;
