/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import t from '../../localization/i18n';
import * as repr from '../../api/types';
import comparableFundsStyles from './ComparableFunds.styles';
import { isGif } from '../helpers';

interface PropsShape {
    report: repr.Report;
}

interface AnnotatedProxyFund extends repr.ReportCompoundReturns {
    annText?: string
    annMarker?: number
}

const orderOfReturns: ('pytd' | 'p1yr' | 'p3yr' | 'p5yr' | 'psi')[] =
    ['pytd', 'p1yr', 'p3yr', 'p5yr', 'psi'];

export default class ComparableFunds extends React.Component<PropsShape> {
    private buildCompoundReturnsTable = (returns: AnnotatedProxyFund[]) => (
        <table className="table">
            <thead>
                <tr>
                    <th />
                    {orderOfReturns.map(r => (
                        <th key={r}>{t(`report.${r}`)}</th>
                    ))}
                    <th>{t('report.inceptionDate')}</th>
                </tr>
            </thead>

            <tbody>
                {returns.map(fund => (
                    <tr key={fund.id}>
                        <td className='left fund-name'>
                            {fund.name}{fund.annMarker && <sup>{fund.annMarker}</sup>}
                        </td>
                        {orderOfReturns.map(r => (
                            <td key={r}>
                                {t(fund[r] * 100, 'decMax2', 'dash')}
                            </td>
                        ))}
                        <td>{t(fund.inceptionDate, 'dateLong')}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    render() {
        const { proxyFundsCompoundReturns, fundProfiles, productType } = this.props.report;

        // no tables to show so this page is not required
        if (proxyFundsCompoundReturns.length < 1) {
            return null;
        }

        // the proxyFundsCompoundReturns objects do no have the disclaimer for the funds
        // need to get them from 'report.fundProfiles'
        let annCounter = 0;
        const proxyFundsWithAnnotations = proxyFundsCompoundReturns.map(fund => {
            const fundProfile = fundProfiles.find(profile => profile._id === fund.id);
            let annText = '';

            if (fundProfile && fundProfile.proxyFundFootNote) {
                annText = fundProfile.proxyFundFootNote;

                // can't use index in case a proxy fund does not have an annotation
                // only when an annotation is added, update the counter
                annCounter++;

                return {
                    annText,
                    annMarker: annCounter,
                    ...fund,
                };
            }

            // if no annotation found
            return fund as AnnotatedProxyFund;
        });

        const performanceAsOfDate = proxyFundsCompoundReturns[0].compoundReturnsEffectiveDate;

        return (
            <section id="comparable-funds" className="page">
                <div css={comparableFundsStyles} className="section main">
                    <h2 className="section-title cover-title">{t('report.proxyPerf.heading')}</h2>

                    {performanceAsOfDate && (
                        <span className='as-of-date'>
                            {t({
                                key: 'report.performanceAsOf',
                                values: { date: t(performanceAsOfDate, 'dateLong') }
                            })}
                        </span>
                    )}

                    <h3 className="table-title">{isGif(productType) ? t('report.proxyPerf.proxyReturnsGIF') : t('report.proxyPerf.proxyReturns')}</h3>

                    {this.buildCompoundReturnsTable(proxyFundsWithAnnotations)}

                    <div className='disclaimer-container'>
                        <div className='disclaimers'>{isGif(productType) ? t('report.proxyPerf.textGIF') : t('report.proxyPerf.text')}</div>

                        <div className="annotations">
                            {proxyFundsWithAnnotations.map(
                                (fund, index) => fund.annText
                                    ? <p key={index}><sup>{fund.annMarker}</sup>{fund.annText}</p>
                                    : null,
                            )}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
