import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const fundProfileStyles = css`
    display: flex;
    flex-flow: row wrap;

    .section-title {
        flex: 1 100%;
    }

    .sidebar {
        width: 40%;
        background-color: ${theme['$color-green-dark-1']};
        color: ${theme['$color-white']};
        padding: 2em 1em;

        .title {
            font-size: ${theme['$font-size-xlarge']};
            font-weight: 600;
            margin-bottom: 1em;
        }

        .subsection {
            margin-bottom: 1.25em;

            .heading {
                font-weight: 600;
                margin-bottom: 8px;
            }

            .text {
                font-size: ${theme['$font-size-small']};

                ul{
                    list-style: disc;
                }
            }

            .top-five {
                font-size: ${theme['$font-size-small']};
            }
        }
    }

    .new-sidebar{
        background-color: ${theme['$color-white']};
        color: ${theme[`$color-black`]};
        padding: 2em 2em 2em 0;
        border-right: 1px solid #ededed;
    }

    .allocations-container {
        width: 60%;
        padding-left: 1em;
    }

    .allocation-table {
        .chart-title {
            font-size: ${theme['$font-size-regular']};
            margin-top: 0;
        }

        .allocation-row {
            span {
                font-size: ${theme['$font-size-xsmall']};
            }

            .allocation-color {
                height: 10px;
                width: 10px;
            }
        }

        .chart-disclaimer {
            font-size: ${theme['$font-size-xsmall']};
        }
    }

    .fund-profile-as-of-date {
        display: block;
        font-size: ${theme['$font-size-small']};
        margin-top: 3em;
        margin-bottom: 10px;
    }

    .fund-link-container {
        font-size: ${theme['$font-size-small']};
        margin-bottom: 1em;
    }
`;

export default fundProfileStyles;
