import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import t from '../../localization/i18n';
import StatefulInput from './StatefulInput';

/* --- Validation functions --- */
const requiredField = (value: any) => value ? undefined : t('errorMessages.requiredError');
// const mustBeNumber = (value: any) => isNaN(value) ? 'Must be a number' : undefined;
// const NaNToZero = (value: number) => isNaN(value) ? 0 : value;
const moreThanZero = (value: number) => value < 0
    ? t('errorMessages.negativeValuesError')
    : undefined;
const lessThan100 = (value: number) => value > 1 ? t('errorMessages.moreThan100Error') : undefined;

// From https://final-form.org/docs/react-final-form/examples/field-level-validation
// NOTE: Order of validators matters. Defines priority of the validate functions.
const composeValidators = (
    ...validators: ((value: any) => any)[]
) => (value: any) => validators.reduce((error, validator) => error || validator(value), undefined);

// -----

// radio inputs by default only do string values,
// this custom field allows it to handle boolean values
export const FieldBooleanRadio = ({ name, value }: { name: string; value: boolean }) => (
    <Field
        name={name}
        component="input"
        type="radio"
        value={value} // even though a bool is passed, a radio input will convert it to a string
        parse={value => value === 'true'} // parse is used to get the final value on submit
    />
);

// -----

interface FieldCurrencyRequired {
    name: string;
    labelKey?: string;
    hiddenLabelKey?: string
    validator?: (value: any) => any
}

export const FieldCurrencyRequired = (
    {
        name,
        labelKey,
        hiddenLabelKey,
        validator
    }: FieldCurrencyRequired) => {
    const validators: ((value: any) => any)[] = [requiredField, moreThanZero];

    if (validator) {
        validators.push(validator);
    }

    // Convert from string to number for the data
    const parseCurrency = (value: string) => {
        // Prepare the value for parseInt
        // - delete all commas incase the value is in english
        // - delete all spaces incase the value is in french
        // - leave the decimals/points, parseInt will ignore everything after a decimal point
        const cleanedValue = value.replace(/[,\s]/g, '');

        // if invalid value, set the amount to 0
        return isNaN(parseInt(cleanedValue)) ? 0 : parseInt(cleanedValue);
    };

    // Convert from number to string for the field
    const formatCurrency = (value: number) => t(value, 'dec');

    return (
        <Field
            name={name}
            validate={composeValidators(...validators)}
            parse={parseCurrency}
            format={formatCurrency}
        >
            {({ input, meta: { error, touched, submitFailed } }) => (
                <div className="label-input-pair">
                    {labelKey && (
                        <label htmlFor={name}>{t(labelKey)}</label>)
                    }
                    {hiddenLabelKey && (
                        <label className='sr-only' htmlFor={name}>{t(hiddenLabelKey)}</label>
                    )}

                    <div className="symbol-input-pair symbol-input-pair--currency">
                        {t('application.currency')}
                        <StatefulInput id={name} input={input} />
                    </div>

                    {error && (
                        <span className="error-message">{error}</span>
                    )}
                </div>
            )}
        </Field>
    );
};

// -----

interface FieldPercent {
    name: string;
    labelKey?: string;
    hiddenLabelKey?: string
    required?: boolean
    decPlaces?: 0 | 1 | 2
}

export const FieldPercent = (
    {
        name,
        labelKey,
        hiddenLabelKey,
        required = false,
        decPlaces = 2,
    }: FieldPercent) => {
    const validators: ((value: any) => any)[] = [moreThanZero, lessThan100];

    if (required) {
        validators.push(requiredField);
    }

    /* Parse is for the value coming out of the field that is stored in the form object */
    /* Value is a localized string, needs to be converted to a decimal number */
    const parse = (value: string) => {
        // get en value - replace any commas with decimal points in case the value is in fr
        const enValue = value.replace(/,/g, '.');

        return isNaN(parseFloat(enValue))
            ? 0
            : Math.round(Math.abs(parseFloat(enValue)) * (10 ** decPlaces)) /
            (100 * (10 ** decPlaces));
        // 10 to the power of decimal places rounds the number to the correct number of places
        // the extra 100 is to convert the value back to less than 1 which the data expects
    };
    // parseFloat - convert the string to a number
    // Math.abs - convert to postivie number, negative percentages are not allowed
    // Math.round - rounding the value to the required number of decimal places

    // ---

    /* Formatting value going into a text field, originally a decimal number always <= 1 */
    /* Needs to be converted to a string */
    const format = (value: number) => t(value * 100, 'dec');
    // t - convert the decimal number to a localized string

    // --

    return (
        <Field
            name={name}
            parse={parse}
            format={format}
            validate={composeValidators(...validators)}
        >
            {({ input, meta: { error, touched, submitFailed } }) => (
                <div className="label-input-pair">
                    {labelKey && (
                        <label htmlFor={name}>{t(labelKey)}</label>
                    )}
                    {hiddenLabelKey && (
                        <label className='sr-only' htmlFor={name}>{t(hiddenLabelKey)}</label>
                    )}

                    <div className="symbol-input-pair symbol-input-pair--percent">
                        <StatefulInput id={name} input={input} /> {t('application.pct')}
                    </div>

                    {error && (
                        <span className="error-message">{error}</span>
                    )}
                </div>
            )}
        </Field>
    );
};

// -----

interface FieldTextRequired {
    name: string;
    labelKey?: string;
    hiddenLabelKey?: string
    type?: 'number' | 'text';
    validator?: (value: any) => any
    maxCharacters?: number
}

export const FieldTextRequired = (
    {
        name,
        labelKey,
        hiddenLabelKey,
        type = 'text',
        validator,
        maxCharacters
    }: FieldTextRequired) => {
    const validators: ((value: any) => any)[] = [requiredField];

    if (validator) {
        validators.push(validator);
    }

    // if maxCharacters is set then limit the string to that many characters
    const parse = (value: string) => maxCharacters !== undefined
        ? value.slice(0, maxCharacters)
        : value;

    return (
        <Field name={name} parse={parse} validate={composeValidators(...validators)}>
            {({ input, meta: { error, touched, submitFailed } }) => (
                <div className="label-input-pair">
                    {labelKey && <label htmlFor={name}>{t(labelKey)}</label>}
                    {hiddenLabelKey && (
                        <label className='sr-only' htmlFor={name}>{t(hiddenLabelKey)}</label>
                    )}
                    <input id={name} {...input} type={type}/>

                    {error &&
                        <span className="error-message">{error}</span>
                    }
                </div>
            )}
        </Field>
    );
};

// -----

interface WhenFieldChangesPropsShape {
    field: string; // field that changes
    becomes: any; // value of field that changes
    set: string; // field to change
    to: any; // new value of field to change
}

// use 'React Final Form Listeners' to listen to the change of one field
// to then update the value of other fields.
// https://medium.com/@erikras/declarative-form-rules-c5949ea97366
export const WhenFieldChanges = ({ field, becomes, set, to }: WhenFieldChangesPropsShape) => (
    <Field name={set} subscription={{}}>
        {(
            // No subscription. We only use Field to get to the change function
            { input: { onChange } },
        ) => (
            <FormSpy subscription={{}}>
                {() => (
                    <OnChange name={field}>
                        {(value: any) => {
                            if (value === becomes) {
                                onChange(to);
                            }
                        }}
                    </OnChange>
                )}
            </FormSpy>
        )}
    </Field>
);
