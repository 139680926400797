/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import React from 'react';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import consolidatedViewStyles from './ConsolidatedView.styles';

interface PropsShape {
    values: repr.ProposalDetailed
    selectClient: (clientId: string) => void
}

interface StateShape {}

export default class ConsolidatedView extends React.Component<PropsShape, StateShape> {
    state: StateShape = {}

    private get accountsSection() {
        const { values, selectClient } = this.props;
        const accountsTotal =
            _.sum(_.map(values.clients, c => _.sum(_.map(c.accounts, a => a.amount))));

        return <>
            <table className='table'>
                <thead>
                    <tr>
                        <th>{t('proposalForm.accounts.name')}</th>
                        <th>{t('proposalForm.accounts.nickname')}</th>
                        <th>{t('proposalForm.accounts.type')}</th>
                        <th>{t('proposalForm.accounts.value')}</th>
                    </tr>
                </thead>

                <tbody>
                    {_.map(values.clients, c => _.map(c.accounts, (a, accountIndex) => {
                        const isFirstAccount = accountIndex === 0;

                        return (
                            <tr
                                key={c.clientId + accountIndex}
                                className={isFirstAccount ? 'first-account' : ''}
                            >
                                {isFirstAccount && (
                                    <td rowSpan={c.accounts.length}>
                                        <span
                                            className='link'
                                            onClick={() => selectClient(c.clientId)}
                                        >
                                            {`${c.firstName} ${c.lastName}`}
                                        </span>
                                    </td>
                                )}
                                <td className="second-col">{a.nickname}</td>
                                <td>
                                    {t(`proposalForm.accountTypes.${a.accountType.toLowerCase()}`)}
                                </td>
                                <td>{t(a.amount, 'cur')}</td>
                            </tr>
                        );
                    }))}
                </tbody>
            </table>

            <div className='footer'>
                <span className='accounts-total'>
                    {t('application.total')}: {t(accountsTotal || 0, 'cur')}
                </span>
            </div>
        </>;
    }

    private buildHoldingsTable(client: repr.ClientDetailed, includeServiceFee: boolean) {
        return (
            <table className='table holdings'>
                <thead>
                    <tr>
                        <th>{t('consolidatedView.accountName')}</th>
                        <th>{t('application.fundCode')}</th>
                        <th className={`fund-name ${includeServiceFee ? 'narrow' : ''}`}>
                            {t('application.fund')}
                        </th>
                        <th className="mer">{t('application.mer')}</th>
                        {includeServiceFee && (
                            <th className="service-fee">{t('application.serviceFee')}</th>
                        )}
                        <th className="value">{t('proposalForm.amount')}</th>
                        <th className="allocation">{t('proposalForm.percent')}</th>
                    </tr>
                </thead>

                <tbody>
                    {_.map(client.accounts, (a, accountIndex) => _.map(
                        a.holdings.filter(h => !h.isGia),
                        h => {
                            const holdings = a.holdings.filter(h => !h.isGia);
                            const isFirstHolding = _.indexOf(holdings, h) === 0;
                            return (
                                <tr
                                    key={client.clientId + accountIndex + h.fundUniqueId}
                                    className={isFirstHolding ? 'first-holding' : ''}
                                >
                                    {isFirstHolding &&
                                        <td rowSpan={holdings.length}>
                                            {a.nickname}
                                        </td>
                                    }
                                    <td className="second-col">{h.fundServCode || h.webProfileId}</td>
                                    <td
                                        className={`fund-name ${includeServiceFee ? 'narrow' : ''}`}
                                    >
                                        {h.fundName}
                                    </td>
                                    <td className="mer">{t(h.originalMer, 'pctMax2')}</td>
                                    {includeServiceFee &&
                                        <td className="service-fee">
                                            {t(h.serviceFee, 'pctMax2')}
                                        </td>
                                    }
                                    <td className="value">{t(h.amount, 'cur')}</td>
                                    <td className="allocation">
                                        {t(h.percent as number, 'pctMax1')}
                                    </td>
                                </tr>
                            );
                        }
                    ))}
                </tbody>
            </table>
        );
    }

    private buildGiaTable(client: repr.ClientDetailed, includeServiceFee: boolean) {
        return (
            <table className='table holdings'>
                <thead>
                    <tr>
                        <th>{t('consolidatedView.accountName')}</th>
                        <th />
                        <th className={`fund-name ${includeServiceFee ? 'narrow' : ''}`}>
                            {t('proposalForm.giaSelector.type')}
                        </th>
                        <th className="gia-term">{t('proposalForm.giaSelector.term')}</th>
                        {includeServiceFee && (
                            <th className="service-fee" />
                        )}
                        <th className="value">{t('proposalForm.amount')}</th>
                        <th className="allocation">{t('proposalForm.percent')}</th>
                    </tr>
                </thead>

                <tbody>
                    {_.map(client.accounts, (a, accountIndex) => _.map(
                        a.holdings.filter(h => h.isGia),
                        h => {
                            const holdings = a.holdings.filter(h => h.isGia);
                            const isFirstHolding = _.indexOf(holdings, h) === 0;
                            return (
                                <tr
                                    key={client.clientId + accountIndex + h.webProfileId}
                                    className={isFirstHolding ? 'first-holding' : ''}
                                >
                                    {isFirstHolding &&
                                        <td rowSpan={holdings.length}>
                                            {a.nickname}
                                        </td>
                                    }
                                    <td />
                                    <td
                                        className={`fund-name ${includeServiceFee ? 'narrow' : ''}`}
                                    >
                                        {h.giaType}
                                    </td>
                                    <td className="gia-term">{h.giaTerm}</td>
                                    {includeServiceFee && (
                                        <td className="service-fee" />
                                    )}
                                    <td className="value">{t(h.amount, 'cur')}</td>
                                    <td className="allocation">
                                        {t(h.percent as number, 'pctMax1')}
                                    </td>
                                </tr>
                            );
                        }
                    ))}
                </tbody>
            </table>
        );
    }

    private get holdingsSection() {
        const { values, selectClient } = this.props;

        return _.map(values.clients, c => {
            const hasHoldings = _.some(c.accounts, a => a.holdings.length !== 0);
            const hasRegularHoldings =
                _.some(c.accounts, a => a.holdings.filter(h => !h.isGia).length !== 0);
            const hasGiaHoldings =
                _.some(c.accounts, a => a.holdings.filter(h => h.isGia).length !== 0);

            if (!hasHoldings) {
                return;
            }

            return (
                <React.Fragment key={c.clientId}>
                    <h4 className='sub-heading link' onClick={() => selectClient(c.clientId)}>
                        {`${c.firstName} ${c.lastName}`}
                    </h4>

                    {hasRegularHoldings && this.buildHoldingsTable(c, values.includeServiceFee)}
                    {hasGiaHoldings && this.buildGiaTable(c, values.includeServiceFee)}
                </React.Fragment>
            );
        });
    }

    render() {
        const { values } = this.props;

        return (
            <div css={consolidatedViewStyles}>
                <div className='section'>
                    <h3 className='heading'>
                        {t(`proposalForm.accounts.heading${values.investmentType}`)}
                    </h3>
                    <div className='table-container'>{this.accountsSection}</div>
                </div>

                <div className='section'>
                    <h3 className='heading'>{t('proposalForm.poolSelector.heading')}</h3>
                    <span>{t('consolidatedView.editPoolMessage')}</span>
                </div>

                {values.includeGIA && (
                    <>
                        <div className='section'>
                            <h3 className='heading'>{t('proposalForm.giaSelector.heading')}</h3>
                            <span>{t('consolidatedView.editPoolMessage')}</span>
                        </div>
                    </>
                )}

                <div className='section'>
                    <h3 className='heading'>{t('proposalForm.holdings.heading')}</h3>
                    {this.holdingsSection}
                </div>
            </div>
        );
    }
}
