/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getLocale, setLocale } from 'react-i18nify';
import { getReport } from '../../api/api';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import authService from '../api-authorization/AuthorizeService';
import AccountAllocation from '../ReportShared/AccountAllocation';
import AccountPerformance from '../ReportShared/AccountPerformance';
import Benefits from '../ReportShared/Benefits';
import ComparableFunds from '../ReportShared/ComparableFunds';
import Fees from '../ReportShared/Fees';
import FundPerformance from '../ReportShared/FundPerformance';
import FundSpecificDisclosures from '../ReportShared/FundSpecificDisclosures';
import GiaMarketingSection from '../ReportShared/GiaMarketingSection';
import InvestmentOverview from '../ReportShared/InvestmentOverview';
import ProgramDetails from '../ReportShared/ProgramDetails';
import CoverPage from './CoverPage';
import EndPage from './EndPage';
import FundProfilePage from './FundProfilePage';
import FundProfileTitlePage from './FundProfileTitlePage';
import ImportantInfoPage from './ImportantInfoPage';
import IntroductionPage from './IntroductionPage';
import reportStyles from './Report.styles';
// import '../../styles/pdf.scss';

function getUrlParams(location: Location) {
    // `location.search` is "" when no URL params, and "?foo=bar" when
    // there are params. First, we slice off the starting "?", then split
    // on "&" to get key/value pairs. Resulting array always has at least
    // one string in it.
    const urlParamsArray = location.search.slice(1).split('&');
    const urlParams = urlParamsArray.reduce((memo, s) => {
        const [k, v] = s.split('=');
        return { ...memo, [k]: v };
    }, {});
    return urlParams;
}

interface PropsShape {
    model?: repr.Report
    locale?: string
    reportId?: string
    clientId?: string
    location: Location
}

export default function Report(props: PropsShape) {
    const { location } = props;
    const urlParams = typeof location === 'object'
        ? getUrlParams(location) as { locale: string, proposalId: string, clientId: string }
        : null;

    // When rendering through client-side app, this component will fetch
    // proposal data. When rendering via SSR, this component will receive a
    // model directly from props, and won't attempt to fetch data on its own.
    const [model, setModel] = useState(props.model);

    // Ensure we don't attempt to set app locale on every render. This is
    // relevant only when rendering report through client-side app -- when
    // rendering via SSR, this component only renders once.
    const [hasLocaleBeenSet, markLocaleAsSet] = useState(false);

    if (!hasLocaleBeenSet) {
        if (urlParams && urlParams.locale) {
            // The fact that we have url params means we're rendering this
            // through the client-side app!
            setLocale(urlParams.locale);
            markLocaleAsSet(true);
        } else if (props.locale) {
            // If there's a locale from props, we're probably rendering via CLI.
            setLocale(props.locale);
            markLocaleAsSet(true);
        }
    }

    // If rendering through client-side app, this is executed by the useEffect
    // hook below to get the model.
    async function fetchReport(proposalId: string, clientId: string | null) {
        const authToken = await authService.getAccessToken();
        const locale = getLocale();
        const response = await getReport(authToken, proposalId, clientId, locale);
        setModel(response.data as repr.Report);
    };

    useEffect(() => {
        if (urlParams && urlParams.proposalId) {
            fetchReport(urlParams.proposalId, urlParams.clientId || null).then();
        }
    }, [urlParams]);

    // This Loading message only gets rendered in the client-side app, while
    // the model is being fetched. When rendering server-side, we always
    // create this component with the model already available.
    if (!model) {
        return <p>Loading...</p>;
    }

    // If a valid client id is provided, it means we're producing an individual
    // report (and not a household report).
    const clientId = props.clientId || (urlParams ? urlParams.clientId : null);
    let client = null;

    if (clientId) {
        client = model.clients.find(c => clientId === c.clientId);

        // TODO: For now, just remove all the other clients from the model.
        // If we actually have other logic we need to do for indiv vs household,
        // we might need to do a flag or something instead of just removing
        // irrelevant individuals like this.
        if (client) {
            model.clients = [client];
        } else {
            throw new Error(`Could not find client with "${clientId}" in model`);
        }
    }

    model.clients.forEach(c => {
        // Name with localized salution
        c.fullName = `${t(`salutations.${c.salutation}`)} ${c.firstName} ${c.lastName}`.trim();
    });

    let proposalName = '';

    if (model.proposalType === 'Household' && !client) {
        proposalName = model.proposalName;
    } else {
        proposalName = `${model.clients[0].fullName}`;
    }

    // Check if report only contains GIA funds
    const isGiaReport = model.includeGIA && !_.some(
        model.clients,
        c => _.some(c.accounts, a => a.holdings.filter(h => !h.isGia).length !== 0)
    );

    const fundDisclosures = model.fundProfiles.filter(fund => fund.fundSpecificDisclosures !== null);
    

    return (
        <article
            className="report"
            data-running-footer-text={t('report.pleaseReadImportant')}
            css={reportStyles}
        >
            <header className="running-header">
                <span className="proposal-type">{t('report.investmentProposal')}</span>
                <span className="proposal-name">{proposalName}</span>
            </header>

            <header className="running-header--inverse">
                <span className="proposal-type">{t('report.investmentProposal')}</span>
                <span className="proposal-name">{proposalName}</span>
            </header>

            <CoverPage
                proposalName={proposalName}
                advisorFirstName={model.advisorFirstName}
                advisorLastName={model.advisorLastName}
                report={model}
            />
            <IntroductionPage report={model} />
            <InvestmentOverview report={model} />

            {!isGiaReport && (
                <FundPerformance report={model} fundDisclosures={fundDisclosures} />
            )}

            <ComparableFunds report={model} />
            <AccountPerformance report={model} variant='pdf' />
            <AccountAllocation report={model} variant='pdf' />

            {/* Pool Details */}
            {model.fundProfiles.length > 0 && <FundProfileTitlePage report={model} />}
            {model.fundProfiles.map(f => (
                <FundProfilePage key={f._id} fund={f} productType={model.productType} investmentType={model.investmentType}/>
            ))}

            {model.includeGIA && <GiaMarketingSection />}
            <Fees report={model} />
            <ProgramDetails investmentType={model.investmentType} productType={model.productType} report={model} variant="pdf"/>
            <Benefits report={model} />
            {model.fundProfiles.some(fund => fund.fundSpecificDisclosures !== null) && <FundSpecificDisclosures report={model} />}
            {model.investmentType === 'Seg' && <ImportantInfoPage report={model} />}
            <EndPage report={model} />
        </article>
    );
}
