import React, { Component } from 'react';
import  authService  from './api-authorization/AuthorizeService';
import { getCookieFromLocalStorage} from '../api/api';

export default class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
      super(props);
      this.state = { forecasts: [], loading: true };
  }

  componentDidMount() {
      this.populateTestData();
  }

  static renderForecastsTable(forecasts) {
      return (
          <table className='table table-striped'>
              <thead>
                  <tr>
                      <th>Date</th>
                      <th>Temp. (C)</th>
                      <th>Temp. (F)</th>
                      <th>Summary</th>
                  </tr>
              </thead>
              <tbody>
                  {forecasts.map(forecast => <tr key={forecast.date}>
                      <td>{forecast.date}</td>
                      <td>{forecast.temperatureC}</td>
                      <td>{forecast.temperatureF}</td>
                      <td>{forecast.summary}</td>
                  </tr>
                  )}
              </tbody>
          </table>
      );
  }

  render() {
      const contents = this.state.loading
          ? <p><em>Loading...</em></p>
          : FetchData.renderForecastsTable(this.state.forecasts);

      return (
          <div>
              <h1>Test forecast</h1>
              <p>This component demonstrates fetching data from the server.</p>
              {contents}
          </div>
      );
  }

  async populateTestData() {
      const token = await authService.getAccessToken();
      const xsrfToken = getCookieFromLocalStorage('XSRF-TOKEN');
      const response = await fetch('/proposal-builder/testforecast', {
          headers: !token ? { 'X-XSRF-TOKEN': xsrfToken } : { Authorization: `Bearer ${token}`, 'X-XSRF-TOKEN': xsrfToken }
      });
      const data = await response.json();
      this.setState({ forecasts: data, loading: false });
  }
}
