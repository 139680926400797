import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
    NavItem,
    NavLink,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';
import t from '../../localization/i18n';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            name: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            name: user && user.fullName,
            // fullName defaults to the email if the user
            // does not have at least a first or last name
        });
    }

    render() {
        const { isAuthenticated } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(profilePath, logoutPath);
        }
    }

    authenticatedView(profilePath, logoutPath) {
        const { currentUser } = this.props;

        if (!currentUser) {
            return null;
        }

        let name = currentUser.userName;

        if (currentUser.firstName || currentUser.lastName) {
            name = `${currentUser.firstName || ''} ${currentUser.lastName || ''}`.trim();
        }

        return <Fragment>
            <UncontrolledDropdown nav inNavbar className="navbar--user-menu">
                <DropdownToggle nav caret>
                    <span className="icon--user user-icon" aria-hidden="true" />
                    <span className="userName">{name}</span>
                </DropdownToggle>
                <DropdownMenu right>
                    {/* By default a DropdownItem is a button */}
                    {/* Change the tag to make the link clickable in IE11 */}
                    {/* https://github.com/reactstrap/reactstrap/issues/1143 */}
                    <DropdownItem tag="div">
                        <NavItem tag={Link} to={profilePath} className="nav-link">
                        {t('application.profile')}
                        </NavItem>
                    </DropdownItem>
                    <DropdownItem tag="div">
                        <NavItem tag={Link} to={logoutPath} className="nav-link">
                        {t('application.logout')}
                        </NavItem>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </Fragment>;
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
