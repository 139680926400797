/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import t from '../../localization/i18n';
import programDetailsStyles from './ProgramDetails.styles';
interface PropsShape {
    holdingProductType: string;
    variant: 'web' | 'pdf';
}

export default class MMFProgramDetails extends React.Component<PropsShape>{
    render(){
        const {holdingProductType, variant} = this.props;
        let subsections: string[] = [];
        const mpipSections = [
            'aboutMPIPFunds',
            'exclusiveInvestmentsMMF',
            'lowerFees',
            'taxEfficiency',
            'reporting'
        ];
        const etfSections = [
            'aboutEtfs',
            'performance',
            'choicesETF',
            'exclusiveInvestmentsETF'
        ];
        const aaSections = [
            'aboutAA',
            'assetMgtStyle',
            'investmentProcess',
            'popularInvestments',
            'choicesAA'
        ];
        const mmfSections = [
            'aboutMMF',
            'invSolutions',
            'approach',
            'mfrMMF',
            'taxEfficency',
            'enhancedReporting'
        ];

        const setSections = (holdingProductType: string) => {
            switch (holdingProductType) {
                case 'mpip':
                  subsections = mpipSections;
                  break;
                case 'mmf':
                    subsections = mmfSections;
                    break;
                case 'etf':
                    subsections = etfSections;
                    break;
                case 'aa':
                  subsections = aaSections;
                  break;
                default:
                    break;
              }
        }

        setSections(holdingProductType);
        const riskReturnImg = t('report.programDetails.riskReturnAA.imgSrc')
        return (
            <section css={programDetailsStyles} id="program-details" className="page">
                <div className="section main program-details-section">
                    <h2 className="section-title cover-title">{t('report.programDetails.heading')}</h2>

                    {subsections.map((s: string) => (
                        <div key={s} className="subsection">
                            <h2 className="subtitle">
                                {t(`report.programDetails.${s}.heading`)}
                            </h2>
                            <p>{t(`report.programDetails.${s}.text`)}</p>
                        </div>
                    ))}
                    {holdingProductType === 'aa' && (
                        <div className="rr-chart"> 
                            <img width="50%" src={`img/mmf/${riskReturnImg}`} alt="Risks and Returns" />
                        </div>
                    )}
                </div>
                {holdingProductType === `aa` &&<div className={`annotations footer-note ${variant === 'web' ? 'aa' : ''}`} >
                        <p>
                            <sup>2 </sup> Source: <a title="https://www.manulifeim.com/retail/ca/en/landing-page/manulife-asset-allocation-portfolios#team-and-process" 
                            href="https://www.manulifeim.com/retail/ca/en/landing-page/manulife-asset-allocation-portfolios#team-and-process" 
                            target="" rel="noopener noreferrer nofollow">
                                {t('report.programDetails.assetMgtStyle.annText')}
                            </a> as at December 31, 2020.
                        </p>
                </div>}
                {holdingProductType === `mmf` &&<div className={`annotations footer-note ${variant === 'web' ? 'aa' : ''}`} >
                        <p>
                            <sup>1 </sup> Source: 
                            <a title="https://www.manulifeim.com/retail/ca/en/landing-page/performance-matters" 
                            href="https://www.manulifeim.com/retail/ca/en/landing-page/performance-matters" 
                            target="" rel="noopener noreferrer nofollow">
                                 {t('report.programDetails.approach.annText')}
                            </a>
                        </p>
                </div>}
            </section>
        )
    }
}