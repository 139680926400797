/** @jsx jsx */
import { jsx, InterpolationWithTheme } from '@emotion/core';
import { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface PropsShape {
    header?: JSX.Element;
    body?: JSX.Element | JSX.Element[];
    footer?: JSX.Element;
    isVisible: boolean;
    toggleModal: () => void;
    styles?: InterpolationWithTheme<any>
}

export default class ModalBase extends Component<PropsShape, ClientData> {
    private closeModal = () => {
        this.props.toggleModal();
    };

    render() {
        const { header, body, footer, isVisible, styles } = this.props;

        return (
            <Modal css={styles} isOpen={isVisible} toggle={this.closeModal}>
                {header && <ModalHeader>{header}</ModalHeader>}
                {body && <ModalBody>{body}</ModalBody>}
                {footer && <ModalFooter>{footer}</ModalFooter>}
            </Modal>
        );
    }
}
