import { css } from '@emotion/core';
import theme from '../../styles/theme.pdf.json';

const accountAllocationStyles = css`
    .two-column-page {
        width: 100%;
        display: flex;
    }

    .column {
        width: 50%;

        &.left {
            padding-right: 1em;
            border-right: 1px solid ${theme['$color-grey']}
        }

        &.right {
            padding-left: 1em;
        }
    }

    .allocation-row {
        font-size: ${theme['$font-size-small']}
    }

    .chart-title {
        font-size: ${theme['$font-size-regular']}
    }

    .section-header {
        margin-bottom: 0.75em;

        .as-of-date {
            margin-bottom: 0.25em;
        }

        .header-disclaimer {
            font-size: ${theme['$font-size-small']};
        }
    }

    .subtitle {
        margin-bottom: 0.5em;
    }
`;

export default accountAllocationStyles;
