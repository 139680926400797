/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as H from 'history';
import _ from 'lodash';
import React from 'react';
import { getLocale } from 'react-i18nify';
import { connect } from 'react-redux';
import { match } from 'react-router';
import Spinner from 'reactstrap/lib/Spinner';
import { fetchReport, clearReport } from '../../actions/reports';
import { ThunkDispatch } from '../../actions/utils';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import { StateShape as ReduxStateShape } from '../../reducers';
import AccountAllocation from '../ReportShared/AccountAllocation';
import AccountPerformance from '../ReportShared/AccountPerformance';
import Benefits from '../ReportShared/Benefits';
import ComparableFunds from '../ReportShared/ComparableFunds';
import Disclosures from '../ReportShared/Disclosures';
import Fees from '../ReportShared/Fees';
import FundPerformance from '../ReportShared/FundPerformance';
import FundSpecificDisclosures from '../ReportShared/FundSpecificDisclosures';
import GiaMarketingSection from '../ReportShared/GiaMarketingSection';
// report components that are used by the pdf too
import InvestmentOverview from '../ReportShared/InvestmentOverview';
import ProgramDetails from '../ReportShared/ProgramDetails';
import FundProfile from './FundProfile';
import ImportantInfo from './ImportantInfo';
// web report sections that are not shared with the PDF
import Introduction from './Introduction';
import ScrollNav from './ScrollNav';
import TopBar from './TopBar';
import webReportStyles from './WebReport.styles';




interface PropsShape {
    dispatch: ThunkDispatch
    report: repr.Report | null
    match: match<{ id: string }>
    history: H.History,
    locale: 'en-US' | 'fr-CA'
}

interface StateShape {
    currentLocale: string,
    loading: boolean
}

class WebReport extends React.Component<PropsShape> {
    state: StateShape = {
        currentLocale: '',
        loading: false, // used to show spinner when waiting for data
    };

    componentDidMount() {
        const { match, dispatch } = this.props;
        const proposalId = match.params.id;
        const locale = getLocale();
        const clientId = this.getClientId() || null;
        dispatch(fetchReport(proposalId, clientId, locale)).then();

        // Set component's locale to app's locale on load
        this.setState({ currentLocale: locale });
    }

    componentDidUpdate(prevProps: PropsShape) {
        const { match, dispatch } = this.props;
        const proposalId = match.params.id;
        const clientId = this.getClientId() || null;
        const { currentLocale } = this.state;
        const locale = getLocale();

        // Refetch report if the app's locale changes
        if (currentLocale !== locale) {
            this.setState({ loading: true });

            dispatch(fetchReport(proposalId, clientId, locale)).then(() => {
                this.setState({ loading: false });
            });

            this.setState({ currentLocale: locale });
        }
    }

    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(clearReport());
    }

    private getClientId = () => {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('clientId');
    };

    render() {
        const { report, dispatch, history, match } = this.props;

        if (!report || report.proposalId !== match.params.id) {
            return null;
        }

        const clientId = this.getClientId() || '';
        const client = _.find(report.clients, { clientId });

        const processedReport = _.cloneDeep(report);

        // If this is an individual report for a household member, then we keep the report
        // object as is but replace the clients list with that one client
        if (client) {
            processedReport.clients = [{ ...client }];
        }

        processedReport.clients.forEach(c => {
            // Name with localized salution
            c.fullName = `${t(`salutations.${c.salutation}`)} ${c.firstName} ${c.lastName}`.trim();
        });

        const loadingOverlay = (
            <div className="loading-report-overlay section">
                <div className="spinner-container">
                    <Spinner color="light" style={{ width: '3rem', height: '3rem' }} />
                </div>
            </div>
        );

        // Check if report only contains GIA funds
        const isGiaReport = processedReport.includeGIA && !_.some(
            processedReport.clients,
            c => _.some(c.accounts, a => a.holdings.filter(h => !h.isGia).length !== 0)
        );

        const fundDisclosures = processedReport.fundProfiles.filter(fund => fund.fundSpecificDisclosures !== null);

        return (
            <div id="web-report-container" css={webReportStyles} className="">
                {this.state.loading && loadingOverlay}

                <TopBar
                    client={client}
                    dispatch={dispatch}
                    history={history}
                    report={processedReport}
                />

                <div className="report-container tm-card">
                    <div className="scroll-nav-container">
                        <ScrollNav
                            investmentType={processedReport.investmentType}
                            proxyFundsLength={processedReport.proxyFundsCompoundReturns.length}
                            fundProfiles={processedReport.fundProfiles}
                            isGiaReport={isGiaReport}
                            includeGIA={processedReport.includeGIA}
                            productType={processedReport.productType}
                        />
                    </div>

                    <div className="report">
                        <Introduction
                            investmentType={processedReport.investmentType}
                            advisorName={
                                `${processedReport.advisorFirstName}
                                  ${processedReport.advisorLastName}`
                            }
                            productType={processedReport.productType}
                        />

                        <InvestmentOverview report={processedReport} />

                        {!isGiaReport && (
                            <FundPerformance report={processedReport} fundDisclosures={fundDisclosures}/>
                        )}

                        <ComparableFunds report={processedReport} />
                        <AccountPerformance report={processedReport} variant='web' />
                        <AccountAllocation report={processedReport} variant='web' />

                        {/* Need the extra div for the scroll navigation */}
                        <div id="pool-details">
                            {/* Pool Details - different from the fund profiles that */}
                            {/* will be attached to the report PDF */}
                            {processedReport.fundProfiles && processedReport.fundProfiles.map(f => (
                                <FundProfile
                                    key={f._id}
                                    fund={f}
                                    includeFundProfiles={processedReport.includeFundProfiles}
                                    report={processedReport}
                                />
                            ))}
                        </div>

                        {processedReport.includeGIA && <GiaMarketingSection />}
                        <Fees report={processedReport} />
                        <ProgramDetails investmentType={processedReport.investmentType} productType={processedReport.productType} report={processedReport} variant="web"/>
                        <Benefits report={processedReport} />
                        {processedReport.fundProfiles.some(fund => fund.fundSpecificDisclosures !== null) && <FundSpecificDisclosures report={processedReport} />}
                        <ImportantInfo report={processedReport} />
                        {processedReport.investmentType === 'Seg' && <Disclosures productType={processedReport.productType}/>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const report = state.app.report;

    return { report };
};

export default connect(mapStateToProps)(WebReport);
