import React from 'react';
import _ from 'lodash';
import * as H from 'history';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import * as repr from '../../api/types';
import { ThunkDispatch } from '../../actions/utils';
import t from '../../localization/i18n';
import fetchReportUrlAndOpenEmail from '../shared/EmailReport';
import Button from '../Button';
import ModalBase from '../shared/ModalBase';
import ClientDetailsForm from './ClientDetailsForm';

interface PropsShape {
    dispatch: ThunkDispatch;
    history: H.History
    proposal: repr.ProposalBare
    deleteProposal: (proposalId: string) => void
    saveProposal: (proposal: Partial<repr.ProposalDetailed>) => Promise<void>
}

interface StateShape {
    isExpanded: boolean
    clientDetailsModalState: 'edit' | 'delete' | null
    pdfModal: {
        isVisible: boolean
        pdfOrEmail?: 'pdf' | 'email'
        clientId?: string
        locale?: 'en-US' | 'fr-CA'
        latestReportId?: string | null
    }
}

const downloadPdfUrlBase = '/proposal-builder/api/Reports';
const downloadPDFUrls = {
    'en-US': '/en-US/download-pdf',
    'fr-CA': '/fr-CA/download-pdf'
};

export default class ClientTableRow extends React.Component<PropsShape, StateShape> {
    state: StateShape = {
        isExpanded: false,
        clientDetailsModalState: null,
        pdfModal: { isVisible: false }
    };

    private closeClientDetailsModal = () => {
        this.setState({
            clientDetailsModalState: null,
        });
    };

    private closepdfModal = () => {
        this.setState({ pdfModal: { isVisible: false } });
    }

    private getEmailSubjectText = (clientId?: string) => {
        const { proposal } = this.props;

        let client;

        if (clientId) {
            client = _.find(proposal.clientSummaries, { clientId });

            if (client) {
                return `${t('email.proposalForClient')} ${client.firstName} ${client.lastName}`;
            }
        }

        const clients = proposal.clientSummaries;
        return (
            clients.length > 1
                ? `${t('email.proposalForHousehold')} ${proposal.proposalName}`
                : `${t('email.proposalForClient')} ${clients[0].firstName} ${clients[0].lastName}`
        );
    }

    private get pdfModal() {
        const { proposal, dispatch, history } = this.props;
        const { pdfModal } = this.state;

        if (!pdfModal.latestReportId) {
            return null;
        }

        const header =
            <React.Fragment>{t('clientList.useExistingPDFWarning')}</React.Fragment>;

        const body =
            <React.Fragment>{t('clientList.useExistingPDFConfirmation')}</React.Fragment>;

        const downloadPdfUrl =
            `${downloadPdfUrlBase}/${pdfModal.latestReportId}${
                downloadPDFUrls[pdfModal.locale || 'en-US']
            }`;

        const proposalFormUrl = `/proposals/${proposal.id}/edit${
            pdfModal.clientId
                ? `?clientId=${pdfModal.clientId}`
                : ''
        }`;

        const submitButtons = (
            <>
                <Button
                    onClick={() => {
                        pdfModal.pdfOrEmail === 'pdf'
                            ? window.open(downloadPdfUrl, '_blank')
                            : fetchReportUrlAndOpenEmail(
                                dispatch,
                                pdfModal.latestReportId as string,
                                this.getEmailSubjectText(pdfModal.clientId)
                            );

                        this.closepdfModal();
                    }}
                >
                    {t('clientList.useExisting')}
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    onClick={() => {
                        history.push(proposalFormUrl);
                        this.closepdfModal();
                    }}
                >
                    {t('clientList.generateNewReport')}
                </Button>
            </>
        );

        return (
            <ModalBase
                header={header}
                body={body}
                footer={submitButtons}
                isVisible={pdfModal.isVisible}
                toggleModal={this.closepdfModal}
            />
        );
    }

    private renderPDFLinks = (clientId?: string) => {
        const { proposal, dispatch } = this.props;

        let latestReportId: string | null | undefined = proposal.latestReportId;
        let isReportUpToDate: boolean | undefined = proposal.isReportUpToDate;

        let client: repr.Client | undefined;

        if (clientId) {
            client = _.find(proposal.clientSummaries, { clientId });

            if (client) {
                latestReportId = client.latestReportId;
                isReportUpToDate = client.isReportUpToDate;
            }
        }

        let buttons;

        // If we have a report Id and it is up to date then show the direct links to
        // the pdf reports
        if (latestReportId && isReportUpToDate) {
            buttons = (
                <>
                    <a
                        className='pdf-links'
                        href={`${downloadPdfUrlBase}/${latestReportId}${downloadPDFUrls['en-US']}`}
                        target='_blank'
                        rel="noopener noreferrer"
                    >
                        <span className="icon icon--document" aria-hidden="true" />
                        <span className="sr-only">{t('application.viewEnPDF')}</span>
                        {t('application.en')}
                    </a>
                    <a
                        className='pdf-links'
                        href={`${downloadPdfUrlBase}/${latestReportId}${downloadPDFUrls['fr-CA']}`}
                        target='_blank'
                        rel="noopener noreferrer"
                    >
                        <span className="icon icon--document" aria-hidden="true" />
                        <span className="sr-only">{t('application.viewFrPDF')}</span>
                        {t('application.fr')}
                    </a>
                    <Button
                        className='pdf-links'
                        variant='icon'
                        onClick={() => fetchReportUrlAndOpenEmail(
                            dispatch,
                            latestReportId as string,
                            this.getEmailSubjectText(clientId)
                        )}
                    >
                        <span className="icon icon--email" aria-hidden="true" />
                        <span className="sr-only">{t('application.emailReport')}</span>
                    </Button>
                </>
            );

        // If we only have a report Id and it is not up to date then warn user that they are
        // using outdated data, and provide option to continue or generate new pdfs
        } else if (latestReportId) {
            buttons = (
                <>
                    <Button
                        className='pdf-links'
                        variant='icon'
                        onClick={() => this.setState({
                            pdfModal: {
                                isVisible: true,
                                pdfOrEmail: 'pdf',
                                locale: 'en-US',
                                clientId,
                                latestReportId
                            }
                        })}
                    >
                        <span className="icon icon--document" aria-hidden="true" />
                        <span className="sr-only">{t('application.viewEnPDF')}</span>
                        {t('application.en')}
                    </Button>
                    <Button
                        className='pdf-links'
                        variant='icon'
                        onClick={() => this.setState({
                            pdfModal: {
                                isVisible: true,
                                pdfOrEmail: 'pdf',
                                locale: 'fr-CA',
                                clientId,
                                latestReportId
                            }
                        })}
                    >
                        <span className="icon icon--document" aria-hidden="true" />
                        <span className="sr-only">{t('application.viewFrPDF')}</span>
                        {t('application.fr')}
                    </Button>
                    <Button
                        className='pdf-links'
                        variant='icon'
                        onClick={() => this.setState({
                            pdfModal: {
                                isVisible: true,
                                pdfOrEmail: 'email',
                                clientId,
                                latestReportId
                            }
                        })}
                    >
                        <span className="icon icon--email" aria-hidden="true" />
                        <span className="sr-only">{t('application.emailReport')}</span>
                    </Button>
                </>
            );

        // Since no pdf report exists, take user to form in order to submit and generate pdf
        } else {
            buttons = (
                <Link
                    className='render-report'
                    to={`/proposals/${proposal.id}/edit${clientId ? `?clientId=${clientId}` : ''}`}
                >
                    {t('clientList.renderReport')}
                </Link>
            );
        }

        return buttons;
    }

    private addCommonEntries = (
        advFirstName: string,
        advLastName: string,
        date: string,
        amount: number,
        id: string,
        clientId?: string
    ) => (
        <>
            <td>{`${advLastName}, ${advFirstName}`}</td>
            <td>{t(date, 'dateLongWithTime')}</td>
            <td>{t(amount, 'cur')}</td>
            <td>
                <Link
                    className='edit-link'
                    to={`/proposals/${id}/edit${clientId ? `?clientId=${clientId}` : ''}`}
                    id={`toolTip--${id}${clientId ? `-${clientId}` : ''}`}
                >
                    <span className="icon icon--form-empty" aria-hidden="true" />
                    <span className="sr-only">{t('clientList.editProposal')}</span>
                    <UncontrolledTooltip
                        placement="right"
                        target={`toolTip--${id}${clientId ? `-${clientId}` : ''}`}
                    >
                        {t('clientList.editProposal')}
                    </UncontrolledTooltip>
                </Link>
                <span className='pdf-link-container'>{this.renderPDFLinks(clientId)}</span>
            </td>
        </>
    );

    renderClientActionButton = (action: 'edit' | 'delete') => (
        <Button
            variant="icon"
            onClick={(e) => {
                e.stopPropagation(); // stops the rows from expanding on click
                this.setState({
                    clientDetailsModalState: action,
                });
            }}
            tooltip={{
                id: `toolTip--${action}${this.props.proposal.id}`,
                text: t(`clientList.${action === 'edit' ? 'editClient' : 'deleteProposal'}`)
            }}
        >
            <span className={`icon icon--${action}`} aria-hidden="true"/>
            <span className="sr-only">{t(`clientList.alt.${action}ClientIcon`)}</span>
        </Button>
    );

    private get expandableRow() {
        const { proposal } = this.props;
        const { isExpanded } = this.state;

        return (
            <>
                <tr
                    key={proposal.id}
                    className='expandable-row'
                >
                    <td
                        className='clickable'
                        onClick={() => this.setState({ isExpanded: !isExpanded })}
                    >
                        <Button
                            variant="icon"
                            aria-hidden="true"
                            tooltip={
                                {
                                    id: `toolTip-${proposal.id}`,
                                    text: `${isExpanded
                                        ? t('clientList.collapse')
                                        : t('clientList.expand')
                                    }`
                                }
                            }
                            styles={{ marginLeft: '0px' }}
                        >
                            <span
                                className={
                                    `icon--arrow-right arrow-icon ${
                                        isExpanded ? 'expanded' : ''
                                    }`
                                }
                            />
                            <span className="sr-only">{isExpanded ? 'Collapse' : 'Expand'}</span>
                        </Button>
                    </td>
                    <td
                        className='clickable'
                        onClick={() => this.setState({ isExpanded: !isExpanded })}
                    >
                        <span className="bold">{`${proposal.proposalName}`}</span>
                        {this.renderClientActionButton('edit')}
                        {this.renderClientActionButton('delete')}
                    </td>
                    {this.addCommonEntries(
                        proposal.advisorFirstName,
                        proposal.advisorLastName,
                        proposal.dateModified,
                        proposal.amount,
                        proposal.id,
                    )}
                </tr>
                {isExpanded && _.map(proposal.clientSummaries, c => (
                    <tr key={c.clientId} className='expanded-row'>
                        <td />
                        <td className='name'>
                            {`${c.lastName}, ${c.firstName}`}
                        </td>
                        {this.addCommonEntries(
                            proposal.advisorFirstName,
                            proposal.advisorLastName,
                            proposal.dateModified,
                            c.amount,
                            proposal.id,
                            c.clientId
                        )}
                    </tr>
                ))}
            </>
        );
    }

    private get regularRow() {
        const { proposal } = this.props;
        return (
            <tr key={proposal.id}>
                <td />
                <td>
                    {
                        `${proposal.clientSummaries[0].lastName},
                        ${proposal.clientSummaries[0].firstName}`
                    }
                    {this.renderClientActionButton('edit')}
                    {this.renderClientActionButton('delete')}
                </td>
                {this.addCommonEntries(
                    proposal.advisorFirstName,
                    proposal.advisorLastName,
                    proposal.dateModified,
                    proposal.amount,
                    proposal.id
                )}
            </tr>
        );
    }

    get deleteClientModal() {
        const { proposal, deleteProposal } = this.props;

        const modalHeader = t('clientList.clientDetails.deleteClient');
        const modalContent = t('application.deleteConfirmation');
        const modalFooter = (
            <>
                <Button onClick={this.closeClientDetailsModal}>
                    {t('application.cancel')}
                </Button>
                <Button onClick={() => deleteProposal(proposal.id)} variant="danger">
                    {t('application.delete')}
                </Button>
            </>
        );

        return (
            <ModalBase
                header={modalHeader}
                body={modalContent}
                footer={modalFooter}
                isVisible
                toggleModal={this.closeClientDetailsModal}
            />
        );
    }

    get editClientModal() {
        return (
            <ClientDetailsForm
                isVisible
                toggleModal={this.closeClientDetailsModal}
                proposalId={this.props.proposal.id}
                saveProposal={this.props.saveProposal}
            />
        );
    }

    render() {
        const { proposal } = this.props;
        const { clientDetailsModalState } = this.state;

        return (
            <>
                {clientDetailsModalState === 'edit' && this.editClientModal}
                {clientDetailsModalState === 'delete' && this.deleteClientModal}
                {this.pdfModal}
                {proposal.clientSummaries.length > 1 ? this.expandableRow : this.regularRow}
            </>
        );
    }
}
