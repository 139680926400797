/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { match, Prompt } from 'react-router';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import * as H from 'history';
import * as finalForm from 'final-form';
import * as repr from '../../api/types';
import { StateShape as ReduxStateShape } from '../../reducers';
import { ThunkDispatch } from '../../actions/utils';
import { getLocale } from 'react-i18nify';
import { fetchProposal, saveProposal } from '../../actions/proposals';
import t from '../../localization/i18n';
import Button from '../Button';
import Configuration from './Configuration';
import ConsolidatedView from './ConsolidatedView';
import ClientSpecificFormSection from './ClientSpecificFormSection';
import proposalFormStyles from './ProposalForm.styles';

interface PropsShape {
    dispatch: ThunkDispatch
    proposal: repr.ProposalDetailed | null
    match: match<{ id: string }>
    history: H.History
}

interface StateShape {
    selectedClientId: string | 'consolidated' | undefined
    freshView: boolean
    generatingReport: boolean
    locale: string
}

class ProposalForm extends React.Component<PropsShape, StateShape> {
    state: StateShape = {
        selectedClientId: undefined,
        freshView: true,
        generatingReport: false,
        locale: ''
    };

    componentDidMount() {
        const { match, dispatch } = this.props;
        const proposalId = match.params.id;

        // get the clientId from the route and show that part of the proposal
        // this allows us to refresh or go to url and stay at the client in the URL
        const queryParams = new URLSearchParams(window.location.search);
        const queryClientId = queryParams.get('clientId');
        const locale = getLocale();
        // fetch the whole proposal, including all clients
        dispatch(fetchProposal(proposalId, locale)).then((resp) => {
            let newClientId;

            if (queryClientId) {
                newClientId = queryClientId;
            } else {
                newClientId = resp.data.clients.length > 1
                    ? 'consolidated'
                    : resp.data.clients[0].clientId;
            }

            this.setState({
                freshView: true,
                selectedClientId: newClientId,
                locale: locale
            });
        });
    }

    componentDidUpdate(prevProps: PropsShape, prevState: StateShape) {
        // only if route changed
        const { match } = this.props;
        const queryParams = new URLSearchParams(window.location.search);
        const queryClientId = queryParams.get('clientId');

        if (match !== prevProps.match) {
            const { proposal } = this.props;

            let newClientId;

            if (proposal) {
                if (queryClientId) {
                    newClientId = queryClientId;
                } else {
                    newClientId = proposal.clients.length > 1
                        ? 'consolidated'
                        : proposal.clients[0].clientId;
                }
            }
            if (prevState.selectedClientId !== newClientId) {
                this.setState({
                    selectedClientId: newClientId,
                });
            }
        }
    }

    componentWillUnmount() {
        // Make sure this event listener is removed when you leave this page
        window.removeEventListener('beforeunload', this.handleLeavingForm);
    }

    handleLeavingForm = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
    };

    updateSelectedClient = (clientId: string) => {
        // update the 'clientId' query parameter when
        // a new client is selected from the dropdown
        this.props.history.push({
            search: clientId === 'consolidated' ? '' : `clientId=${clientId}`,
        });
    };

    prepareInitialFormValues = () => {
        const { proposal } = this.props;

        const initialValues = _.cloneDeep(proposal as repr.ProposalDetailed);

        // Add percent property to each holding if the account amount is not 0
        // The percent property is used by the form to fill the fields
        _.forEach(initialValues.clients, c => _.forEach(c.accounts, a => _.forEach(
            a.holdings, h => {
                if (a.amount) {
                    h.percent = h.amount / a.amount;
                }
            }
        )));

        return initialValues;
    };

    validate = (values: repr.ProposalDetailed) => {
        const errors: { [k: string]: string } = {};

        // Check if all holding values add up to total account value
        _.forEach(values.clients, (c, clientIndex) => _.forEach(c.accounts, (a, accountIndex) => {
            const holdingsTotal = _.sum(_.map(a.holdings, 'amount'));

            if (holdingsTotal !== a.amount) {
                errors[`clients[${clientIndex}].accounts[${accountIndex}].holdings`] =
                    t('errorMessages.holdingTotalNot100');
            }
        }));

        return errors;
    };

    saveProposal = (
        values: repr.ProposalDetailed,
        form: finalForm.FormApi<repr.ProposalDetailed>,
    ) => {
        const { dispatch } = this.props;
        // Loop through all accounts and holdings to remove percent properties
        const processedValues = {
            ...values,
            clients: _.forEach(values.clients, c => _.forEach(c.accounts, a => _.forEach(
                a.holdings, h => delete h.percent,
            ))),
        };
        const locale = getLocale();
        return dispatch(saveProposal(processedValues))
            .then(() => dispatch(fetchProposal(values.id, locale)).then((resp) => {
                const initialValues = this.prepareInitialFormValues();

                // form.reset() doesn't work with keepDirtyOnReinitialize. Workaround from:
                // https://github.com/final-form/final-form/issues/151#issuecomment-425867172
                form.setConfig('keepDirtyOnReinitialize', false);
                // reset form so it updates with the fresh values from the api
                form.reset(initialValues);
                form.setConfig('keepDirtyOnReinitialize', true);

                return resp; // form data
            }));
    };

    viewHouseholdReport = (
        values: repr.ProposalDetailed,
        form: finalForm.FormApi<repr.ProposalDetailed>,
    ) => {
        // Save the proposal and fetch it again because
        // the isValid property is needed to check if a report can be generated
        this.saveProposal(values, form)
            .then(resp => {
                if (resp.data.isValid) {
                    this.setState({ generatingReport: true });
                    this.props.history.push(`/proposals/${resp.data.id}`);
                } else {
                    this.setState({
                        freshView: false,
                    });

                    form.submit(); // Submit to show validation errors
                }
            });
    };

    viewIndividualReport = (
        values: repr.ProposalDetailed,
        form: finalForm.FormApi<repr.ProposalDetailed>,
    ) => {
        const { selectedClientId } = this.state;

        // Save the proposal and refetch to check if individual proposal is valid
        this.saveProposal(values, form)
            .then(resp => {
                const client = _.find(resp.data.clients, { clientId: selectedClientId });

                // If client is valid redirect to report page for that client
                if (client && client.isValid) {
                    this.setState({ generatingReport: true });

                    this.props.history.push(`/proposals/${resp.data.id}${
                        values.proposalType === 'Household'
                            ? `?clientId=${client.clientId}`
                            : ''
                    }`);
                } else {
                    this.setState({
                        freshView: false,
                    });

                    form.submit(); // If client is invalid submit to show validation
                }
            });
    };

    render() {
        const { proposal } = this.props;
        const { selectedClientId } = this.state;

        if (!proposal || !selectedClientId) {
            return null;
        }

        const pageTitle = ` ${t('proposalForm.proposalFor')} ${
            proposal.proposalType === 'Household'
                ? proposal.proposalName
                : `${proposal.clients[0].firstName} ${proposal.clients[0].lastName}`}`;
            
        const currentLocale = getLocale();

        return (
            <Form
                // We submit the form only when proposal or client is not valid so we can
                // check for validation errors (no logic needed if there are no errors)
                onSubmit={() => {}}
                initialValues={this.prepareInitialFormValues()}
                mutators={{ ...arrayMutators }}
                validate={this.validate}
                keepDirtyOnReinitialize={true}
                render={(
                    {
                        handleSubmit,
                        form,
                        values,
                        submitFailed,
                        submitSucceeded,
                        errors,
                        dirty,
                    }) => {
                    const selectedClient = _.find(values.clients, { clientId: selectedClientId });

                    if (selectedClientId !== 'consolidated' && !selectedClient) {
                        return null;
                    }

                    const feedbackMessages: Set<string> = new Set();

                    // if form fails to submit due to validation errors
                    if (submitFailed) {
                        feedbackMessages.add('fixFormErrors');
                    }

                    // if submit succeeded but no report is being generated,
                    // the proposal or client isn't valid
                    if (submitSucceeded && !this.state.generatingReport) {
                        feedbackMessages.delete('fixFormErrors');

                        if (selectedClientId === 'consolidated' && !values.isValid) {
                            feedbackMessages.add('invalidHouseholdProposal');
                        } else if (selectedClient && !selectedClient.isValid) {
                            feedbackMessages.add('invalidIndividualProposal');
                        }
                    }

                    // if viewing a new client, don't show any messages
                    if (this.state.freshView) {
                        feedbackMessages.clear();
                    }

                    // if generating a report, everything is valid and saved
                    if (this.state.generatingReport) {
                        feedbackMessages.clear();
                        feedbackMessages.add('generateReport');
                    }

                    const saveButton = (
                        <Button
                            type='button'
                            variant='secondary'
                            onClick={() => this.saveProposal(values, form)}
                            disabled={!dirty}
                        >
                            {values.proposalType === 'Individual'
                                ? t('proposalForm.save')
                                : t('proposalForm.saveHousehold')
                            }
                        </Button>
                    );

                    if (dirty) {
                        // Warn user of unsaved changes when trying to reload or close tab
                        window.addEventListener('beforeunload', this.handleLeavingForm);
                    } else {
                        // Remove listener when form is saved
                        window.removeEventListener('beforeunload', this.handleLeavingForm);
                    }
                    if(this.state.locale !== currentLocale){
                        this.saveProposal(values, form);
                        this.setState({locale: currentLocale});
                    }
                    return (
                        <form css={proposalFormStyles} onSubmit={handleSubmit} className="tm-card">
                            <div className='top-bar'>
                                <h1 className='title'>
                                    <span className='bold'>
                                        {t(`clientList.${proposal.investmentType.toLowerCase()}`)}
                                    </span>{pageTitle}
                                </h1>

                                <div className='form-controls'>
                                    {proposal.clients.length > 1 && (
                                        <>
                                            <label
                                                htmlFor='household-member-selector'
                                                className='sr-only'
                                            >
                                                {t('application.selectMember')}
                                            </label>
                                            <select
                                                id='household-member-selector'
                                                value={selectedClientId}
                                                onChange={v => this.updateSelectedClient(
                                                    v.currentTarget.value,
                                                )}
                                            >
                                                {proposal.clients.map(c => (
                                                    <option key={c.clientId} value={c.clientId}>
                                                        {`${c.firstName} ${c.lastName}`}
                                                    </option>
                                                ))}
                                                <option
                                                    key='consolidated-view'
                                                    value='consolidated'
                                                >
                                                    {proposal.proposalName}
                                                </option>
                                            </select>
                                        </>
                                    )}

                                    {saveButton}

                                    {!dirty && (
                                        <div className="save-message">
                                            {t('errorMessages.changesAreSaved')}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='form-container'>
                                <Configuration values={values} changeFormValue={form.change}/>

                                <div className={`form-section ${
                                    selectedClientId !== 'consolidated' ? 'hide' : ''
                                }`}>
                                    <ConsolidatedView
                                        values={values}
                                        selectClient={(id: string) => this.updateSelectedClient(id)}
                                    />
                                </div>

                                <div
                                    className={
                                        `form-section ${
                                            selectedClientId === 'consolidated' ? 'hide' : ''
                                        }`
                                    }
                                >
                                <ClientSpecificFormSection
                                        values={values}
                                        selectedClient={selectedClient as repr.ClientDetailed}
                                        changeFormValue={form.change}
                                        errors={errors}
                                        save={this.saveProposal}
                                        form={form}
                                    />
                                </div>
                            </div>

                            <div className='submit-buttons-container'>
                                {saveButton}

                                {selectedClientId === 'consolidated'
                                    ? (
                                        <Button
                                            type="button"
                                            variant="primary"
                                            onClick={() => this.viewHouseholdReport(values, form)}
                                        >
                                            {t('proposalForm.viewHouseholdReport')}
                                        </Button>
                                    )
                                    : (
                                        <Button
                                            type="button"
                                            variant="primary"
                                            onClick={() => this.viewIndividualReport(values, form)}
                                        >
                                            {t('proposalForm.viewIndividualReport')}
                                        </Button>
                                    )
                                }
                            </div>

                            {!dirty && (
                                <div className="form-messages">
                                    {t('errorMessages.changesAreSaved')}
                                </div>
                            )}

                            <div className="form-error-messages">
                                {Array.from(feedbackMessages)
                                    .map(e => (<p key={e}>{t(`errorMessages.${e}`)}</p>))}
                            </div>

                            {/* Warn user of unsaved changes if navigating away from form */}
                            <Prompt
                                // only prompt if form is dirty or
                                // user is leaving form (not switching clients)
                                message={
                                    location => !dirty ||
                                    location.pathname.endsWith(`proposals/${values.id}/edit`)
                                        ? true
                                        : t('proposalForm.unsavedChangesPrompt')}
                            />
                        </form>
                    );
                }}
            />
        );
    }
}

const mapStateToProps = (state: ReduxStateShape) => {
    const proposal = state.app.proposal;

    return { proposal };
};

export default connect(mapStateToProps)(ProposalForm);
