/** @jsx jsx */
import { jsx, InterpolationWithTheme } from '@emotion/core';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

interface PropsShape {
    styles?: InterpolationWithTheme<any>
    children: JSX.Element[] | JSX.Element | string
    disabled?: boolean
    onClick?: (e: React.FormEvent<EventTarget>) => void
    variant?: 'primary' | 'secondary' | 'danger' | 'link' | 'icon'
    className?: string
    // type is used for forms
    type?: 'submit' | 'button' | 'reset'
    tooltip?: { id: string, text: string }
}

/**
 * Renders a button.
 */
export default function Button(props: PropsShape) {
    const {
        disabled = false,
        onClick,
        styles,
        variant,
        type,
        tooltip
    } = props;

    const className = `btn ${variant ? `btn-${variant}` : ''} ${props.className}`;
    const buttonProps = { className, disabled, onClick, type };

    return (
        <button
            css={styles}
            {...buttonProps}
            id={tooltip ? tooltip.id : ''}
        >
            {props.children}
            {tooltip &&
                <UncontrolledTooltip placement="right" target={tooltip.id}>
                    {tooltip.text}
                </UncontrolledTooltip>
            }
        </button>
    );
}
