import { css } from '@emotion/core';
import theme from '../../../styles/theme.web.json';

const holdingsSectionStyles = css`
    margin-bottom: 4em;
    margin-top: 3em;

    .heading {
        font-size: ${theme['$font-size-large']};
        margin-bottom: 1em;
        font-weight: 600;
    }

    .table-container {
        position: relative;
        margin-bottom: 5.5em;

        .account-heading {
            font-size: ${theme['$font-size-large']};
            margin-bottom: 0.5em;
        }

        .footer {
            position: absolute;
            right: 0;
            margin-top: 1.5em;

            .accounts-total {
                margin-right: 1em;
            }
        }

        .fund-code {
            width: 10%;
        }

        .mer,
        .service-fee,
        .gia-term {
            width: 12%;
        }

        .value {
            width: 18%;
        }

        .allocation {
            width: 15%;
        }

        .delete {
            width: 5%;
        }
    }

    .error-message {
        display: block;
        margin-top: 5px;
    }
`;

export default holdingsSectionStyles;
