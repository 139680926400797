import Resource, * as api from '../api/api';
import * as repr from '../api/types';
import makeRequest, { RestSideEffect } from './utils';
import { Action, ActionWithPayload, ActionWithError } from './Action';

export const FETCH_FUNDS = 'FETCH_FUNDS';
export type FETCH_FUNDS = typeof FETCH_FUNDS;

export const RECEIVE_FUNDS = 'RECEIVE_FUNDS';
export type RECEIVE_FUNDS = typeof RECEIVE_FUNDS;

export const FETCH_FUNDS_FAILED = 'FETCH_FUNDS_FAILED';
export type FETCH_FUNDS_FAILED = typeof FETCH_FUNDS_FAILED;

export const FETCH_ETF_FUNDS = 'FETCH_ETF_FUNDS';
export type FETCH_ETF_FUNDS = typeof FETCH_ETF_FUNDS;

export const RECEIVE_ETF_FUNDS = 'RECEIVE_ETF_FUNDS';
export type RECEIVE_ETF_FUNDS = typeof RECEIVE_ETF_FUNDS;

export const FETCH_ETF_FUNDS_FAILED = 'FETCH_ETF_FUNDS_FAILED';
export type FETCH_ETF_FUNDS_FAILED = typeof FETCH_ETF_FUNDS_FAILED;

export const FETCH_GIA_FUNDS = 'FETCH_FUNDS';
export type FETCH_GIA_FUNDS = typeof FETCH_GIA_FUNDS;

export const RECEIVE_GIA_FUNDS = 'RECEIVE_GIA_FUNDS';
export type RECEIVE_GIA_FUNDS = typeof RECEIVE_GIA_FUNDS;

export const FETCH_GIA_FUNDS_FAILED = 'FETCH_GIA_FUNDS_FAILED';
export type FETCH_GIA_FUNDS_FAILED = typeof FETCH_GIA_FUNDS_FAILED;

type FetchFundsAction = Action<FETCH_FUNDS>;
type ReceiveFundsAction =
    ActionWithPayload<RECEIVE_FUNDS, Resource<repr.FundsPayload>>;
type FetchFundsFailedAction = ActionWithError<FETCH_FUNDS_FAILED, Error>;

type FetchEtfFundsAction = Action<FETCH_ETF_FUNDS>;
type ReceiveEtfFundsAction =
    ActionWithPayload<RECEIVE_ETF_FUNDS, Resource<repr.FundsPayload>>;
type FetchEtfFundsFailedAction = ActionWithError<FETCH_ETF_FUNDS_FAILED, Error>;

type FetchGiaFundsAction = Action<FETCH_GIA_FUNDS>;
type ReceiveGiaFundsAction =
    ActionWithPayload<RECEIVE_GIA_FUNDS, Resource<repr.FundsPayload>>;
type FetchGiaFundsFailedAction = ActionWithError<FETCH_GIA_FUNDS_FAILED, Error>;

export type FundActionTypes =
    | FetchFundsAction
    | ReceiveFundsAction
    | FetchFundsFailedAction
    | FetchEtfFundsAction
    | ReceiveEtfFundsAction
    | FetchEtfFundsFailedAction
    | FetchGiaFundsAction
    | ReceiveGiaFundsAction
    | FetchGiaFundsFailedAction

export function fetchFunds(
    streamType: repr.investmentTypes,
    isFeeBased: boolean,
    locale: string
): RestSideEffect<repr.FundsPayload> {
    return (
        dispatch => dispatch(
            makeRequest(
                FETCH_FUNDS,
                RECEIVE_FUNDS,
                FETCH_FUNDS_FAILED,
                ({ authToken }) => api.getFunds(authToken, streamType, isFeeBased, locale)
            )
        )
    );
}

export function fetchEtfFunds(
    locale: string
): RestSideEffect<repr.FundsPayload> {
    return (
        dispatch => dispatch(
            makeRequest(
                FETCH_ETF_FUNDS,
                RECEIVE_ETF_FUNDS,
                FETCH_ETF_FUNDS_FAILED,
                ({ authToken }) => api.getEtfFunds(authToken, locale)
            )
        )
    );
}

export function fetchGiaFunds(
    locale: string
): RestSideEffect<repr.FundsPayload> {
    return (
        dispatch => dispatch(
            makeRequest(
                FETCH_GIA_FUNDS,
                RECEIVE_GIA_FUNDS,
                FETCH_GIA_FUNDS_FAILED,
                ({ authToken }) => api.getGiaFunds(authToken, locale)
            )
        )
    );
}
