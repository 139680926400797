import { css } from '@emotion/core';
import theme from '../../styles/theme.pdf.json';

const reportStyles = css`
    .bold {
        font-weight: 700;
    }

    .section {
        margin-bottom: 7em;
    }

    .subsection {
        margin-bottom: 1.5em;
    }

    .subtitle {
        font-size: ${theme['$font-size-large']};
        font-weight: 600;
        margin-bottom: 1em;
    }

    // For when the subtitle has to be a table heading to repeat across pages...
    th.subtitle {
        text-align: left;
        font-weight: 600;
        padding-bottom: 1em;
    }

    .account-title {
        text-align: left;
        margin-bottom: 0.5em;
        break-after: avoid;
    }

    .table-title {
        text-align: left;
        font-size: ${theme['$font-size-small']};
        font-weight: 600;
        margin: 1em 0 0.5em;
        break-after: avoid;
    }

    .table-container {
        margin-bottom: 1.5em;
    }

    #account-performance {
        .table-container {
            .disclaimers {
                margin-top: 1em;
            }
        }

        .historical-returns-container {
            margin-top: 2.5em;
        }
    }

    .disclaimers {
        font-size: ${theme['$font-size-small']};
    }

    .annotations {
        font-size: ${theme['$font-size-xsmall']};
        position: absolute;
        bottom: 0;
        width: 70em;
    }
    .annotations.impInfo{
        bottom: 30px;
        width: 400px;
    }
    .disclaimers,
    .annotations {
        p {
            margin-bottom: 1em;
        }
    }

    .benefits-section {
        .section-title {
            font-size: 18pt;
        }
        ul {
            padding: 1em;

            li {
                list-style-type: disc;
                padding: 1em;
            }
        }
    }

    .disclosures-section {
        margin-top: 5em;
    }

    .info-sub-heading {
        font-weight: bold;
    }

    .add-page-break {
        page-break-after: always;
    }

    .add-page-padding{
        margin-top: 7em;
    }

    .as-of-date {
        display: block;
        font-size: ${theme['$font-size-small']};
        margin-top: -0.75em;
        margin-bottom: 2em;
        break-after: avoid;
    }

    #account-allocation,
    #account-performance {
        .as-of-date {
            margin-top: -0.15em;
            margin-bottom: 0.5em;
        }
    }

    .program-details-section {
        .subsection p {
            font-size: ${theme['$font-size-small']};
        }

        .program-details-summary {
            font-size: ${theme['$font-size-small']};
        }
    }

    .gia-marketing-section {
        p, th, td {
            font-size: ${theme['$font-size-small']};
        }
    
        .annotations p {
            font-size: ${theme['$font-size-xsmall']};
        }
    }

    .investment-list{
        list-style: disc;
        ul {
            list-style: disc;
        }
    }

    .fund-title{
        color: ${theme[`$color-black`]};
    }
`;

export default reportStyles;
