import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { I18n } from 'react-i18nify';
import Layout from './components/Layout';
import ClientList from './components/ClientList';
import Report from './components/report/Report';
import FetchData from './components/FetchData';
import ProposalForm from './components/ProposalForm';
import WebReport from './components/WebReport';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ConditionalRedirectRoute from './components/api-authorization/ConditionalRedirectRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

// eslint-disable-next-line import/no-unassigned-import
import './localization/i18n';

interface PropsShape {}

export default class App extends Component<PropsShape> {
    static displayName = App.name;

    render() {
        return (
            <I18n
                render={() => (
                    <Switch>
                        <Redirect exact from='/' to='/proposals' />
                        <Redirect
                            exact
                            from='/authentication/logged-out'
                            to='/authentication/login'
                        />
                        <ConditionalRedirectRoute>
                            <AuthorizeRoute exact path="/report" component={Report} />
                            <Layout>
                                <AuthorizeRoute exact path="/proposals" component={ClientList} />
                                <AuthorizeRoute exact path='/proposals/:id' component={WebReport} />
                                <AuthorizeRoute path='/proposals/:id/edit' component={ProposalForm} />
                                <AuthorizeRoute path="/fetch-data" component={FetchData} />
                                <Route
                                    path={ApplicationPaths.ApiAuthorizationPrefix}
                                    component={ApiAuthorizationRoutes}
                                />
                            </Layout>
                        </ConditionalRedirectRoute>
                    </Switch>
                )}
            />
        );
    }
}
