/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { getLocale } from 'react-i18nify';
import parse from 'html-react-parser';
import * as repr from '../../api/types';
import t from '../../localization/i18n';
import AllocationChart from './AllocationChart';
import fundProfileStyles from './FundProfile.styles';
import { isGif } from '../helpers';

interface PropsShape {
    includeFundProfiles: boolean
    fund: repr.FundDetailed
    report: repr.Report
}

const fundUrls = {
    mmf: {
        en: 'https://www.manulifeim.com/retail/ca/en/investments/mutual',
        fr: 'https://www.manulifeim.com/retail/ca/fr/investments/mutual'
    },
    seg: {
        en: 'https://www.manulifeim.com/retail/ca/en/investments/profiles',
        fr: 'https://www.manulifeim.com/retail/ca/fr/investments/profiles'
    },
    etf: { 
        en: 'https://www.manulifeim.com/retail/ca/en/investments/etfs',
        fr: 'https://www.manulifeim.com/retail/ca/fr/investments/etfs'
    }
};

export default class FundProfile extends React.Component<PropsShape> {
    render() {
        const { fund, includeFundProfiles, report } = this.props;
        const investorTypeHeading =
            t(`report.fundProfile.investorType${fund.streamType === 'mmf' ? 'MMF' : 'Seg'}`);

        const lang = getLocale() === 'en-US' ? 'en' : 'fr';
        const fundUrl = `${fundUrls[fund.streamType][lang]}/${fund.webProfileId}`;
        const fundFact = report.includeFundFacts ? report.fundFacts.find(ele => ele.fundIdentifier === (fund.fundServCode || fund.webProfileId))
        : null;

        let subAdvisorHeading = 'report.fundProfile.portfolioSubAdvisor';

        // Logic to decide what label to use for seg sub advisor heading
        if (fund.streamType === 'seg') {
            if (fund.isPortfolioFund === 'FOF') {
                subAdvisorHeading = 'report.fundProfile.underlyingFundManager';
            } else if (fund.isPortfolioFund === 'Bundle' || fund.isPortfolioFund === 'direct') {
                subAdvisorHeading = 'report.fundProfile.fundManager';
            } else {
                subAdvisorHeading = 'report.fundProfile.managedBy';
            }
        }

        const subAdvisorText = fund.streamType === 'seg'
            ? fund.underlyingFundManager
            : fund.portfolioSubAdvisor;

        return (
            <div css={fundProfileStyles} className='section'>
                <h2 className='section-title new-section-title'>{isGif(report.productType) ? t('report.fundProfile.headingGIF') : (report.investmentType === "MMF") ? 
                    t('report.fundProfile.headingMMF'): t('report.fundProfile.heading')}</h2>
                <div className='sidebar new-sidebar'>
                    <h3 className='title'>{fund.fundName}</h3>

                    <div className='subsection investment-objective'>
                        {fund.investmentObjective}
                    </div>

                    {subAdvisorText && (
                        <div className='subsection'>
                            <h4 className='heading'>{t(subAdvisorHeading)}</h4>
                            <p className='text'>{subAdvisorText}</p>
                        </div>
                    )}

                    {fund.fundManagers && (
                        <div className='subsection'>
                            <h4 className='heading'>{t('report.fundProfile.portfolioManagers')}</h4>
                            <p className='text'>{fund.fundManagers}</p>
                        </div>
                    )}

                    {fund.investorType && (
                        <div className='subsection'>
                            <h4 className='heading'>
                                {investorTypeHeading}
                            </h4>

                            {/* investorType sometimes contains html, which needs to be parsed */}
                            <div className='text'>{parse(fund.investorType)}</div>
                        </div>
                    )}

                    {fund.top5Holdings && (
                        <div className='subsection'>
                            <h4 className='heading'>{t('report.fundProfile.top5Holdings')}</h4>

                            <ul className='top-five'>
                                {fund.top5Holdings.map(h => <li key={h.name}>{h.name}</li>)}
                                <li>
                                    {`${(isGif(report.productType) || report.investmentType === "MMF") ? t(
                                        'report.fundProfile.pctOfPoolNonMPIP'
                                    ): t(
                                        'report.fundProfile.pctOfPool'
                                    )} ${t(fund.top5Percentage, 'pctMax1')}`}
                                </li>
                            </ul>
                        </div>
                    )}
                </div>

                <div className='allocations-container'>
                    {fund.holdings.assetClass && fund.holdings.assetClass.length > 0 && (
                        <div>
                            <AllocationChart
                                allocations={fund.holdings.assetClass}
                                title={t('report.allocation.chartTitles.assetClass')}
                                size={150}
                                productType={report.productType}
                                investmentType={report.investmentType}
                            />
                        </div>
                    )}

                    {fund.holdings.country && fund.holdings.country.length > 0 && (
                        <div>
                            <AllocationChart
                                allocations={fund.holdings.country}
                                title={t('report.allocation.chartTitles.country')}
                                size={150}
                                productType={report.productType}
                                investmentType={report.investmentType}
                            />
                        </div>
                    )}

                    {fund.holdings.gicsSector && fund.holdings.gicsSector.length > 0 && (
                        <div>
                            <AllocationChart
                                allocations={fund.holdings.gicsSector}
                                title={t('report.allocation.chartTitles.gicsSectorEquity')}
                                size={150}
                                productType={report.productType}
                                investmentType={report.investmentType}
                            />
                        </div>
                    )}

                    {fund.holdingsEffectiveDate && (
                        <span className='fund-profile-as-of-date'>
                            {t({
                                key: 'report.dataAsOf',
                                values: { date: t(fund.holdingsEffectiveDate, 'dateLong') }
                            })}
                        </span>
                    )}

                    {includeFundProfiles &&
                        <div className='fund-link-container'>
                            {t('report.fundProfile.toLearnMoreText')}{' '}
                            <a
                                href={fundUrl}
                                target='_blank'
                                rel="noopener noreferrer"
                            >
                                {t('report.fundProfile.fundProfile')}
                            </a>
                            .
                        </div>}
                        {report.includeFundFacts &&
                        <div className='fund-link-container'>
                            {t('report.fundProfile.toLearnMoreTextFF')}{' '}
                            <a
                                href={fundFact!.fundFactUrl}
                                target='_blank'
                                rel="noopener noreferrer"
                            >
                                {t('report.fundProfile.fundFact')}
                            </a>
                            .
                        </div>}
                </div>

            </div>
        );
    }
}
