import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const clientFormStyles = css`
    .subtitle {
        margin-bottom: 10px;
        margin-top: 30px;
        font-size: ${theme['$font-size-regular']};
        font-weight: bold;
    }

    label {
        font-size:${theme['$font-size-small']};
    }

    .modal-body {
        padding: 1rem 2.5rem .5rem;


        .input-section {
            margin-bottom: 2rem;
        }

        .members {
            max-height: 20vh;
            overflow-y: auto;
            overflow-x: hidden;
            margin-bottom: .5rem;
        }

        .input-row {
            display: flex;
            margin-bottom: 0.75rem;
        }

        .label-input-pair {
            display: flex;
            flex-direction: column;
            margin-right: 15px;

            input {
                height: 35px;
                margin-top: 5px;
                padding: 0 5px 0 5px;
                border: 1px solid ${theme['$color-navy-light-4']};
            }

            select {
                margin-top: 5px;
                height: 35px;
                border: 1px solid ${theme['$color-navy-light-4']};
            }

            .error-message {
                font-size:${theme['$font-size-small']};
                color: ${theme['$color-coral-dark-3']};
                margin-top: 3px;
            }
        }

        .delete-client-button {
            margin-top: 4%
        }

        .client-type-toggle {
            input {
                margin-right: 5px;
            }

            label {
                margin-right: 15px;
            }
        }
    }
`;

export default clientFormStyles;
