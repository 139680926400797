import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const introductionOverviewStyles = css`
    .advisor-name {
        font-weight: 600;
    }

    p {
        font-weight: 300;
        font-size: ${theme['$font-size-large']};
        line-height: 1.4;
    }
`;

export default introductionOverviewStyles;
