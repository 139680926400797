import { css } from '@emotion/core';
import theme from '../../styles/theme.web.json';

const feesStyles = css`
    thead td {
        border: none;
    }

    .definitions {
        margin-top: 2em;

        li {
            font-size: ${theme['$font-size-small']};
            margin-bottom: 15px;

            .definition-subtitle {
                font-weight: 600;
            }
        }
    }

    .table {
        margin-bottom: 1em;
    }

    .mfr-table-header {
        margin-bottom: 15px;
    }

    .first{
        bottom: 80px;
    }

    .french-ann{
        bottom: 95px;
    }
`;

export default feesStyles;
