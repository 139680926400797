/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import * as repr from '../../../api/types';
import Button from '../../Button';
import t from '../../../localization/i18n';
import {
    FieldCurrencyRequired,
    FieldTextRequired,
    WhenFieldChanges
} from '../../shared/CustomFormFields';
import accountSectionStyles from './AccountSection.styles';

interface PropsShape {
    client: repr.ClientDetailed
    clientIndex: number
    validateAccountValue: (value: number, isPoolValue: boolean) => { isValid: boolean, minimumAmount: number }
    changeFormValue: (name: string, value?: any) => void
    investmentType: repr.investmentTypes
}

const accountTypeList = ['NonRegistered', 'Registered', 'RRSP', 'TFSA', 'RRIF', 'Other'];
const defaultAccount: Partial<repr.Account> = {
    accountType: accountTypeList[0],
    amount: 0,
    holdings: []
};

const MAX_NUMBER_OF_ACCOUNTS = 6;

export default class AccountSection extends React.Component<PropsShape> {
    private generateAccountName = (numberOfAccounts: number) => {
        const { client, investmentType } = this.props;

        // accounts have diff labels for MMF and Seg
        const accountLabel = t(`application.account${investmentType}`);

        let accountNumber = numberOfAccounts + 1;

        let newAccountName = `${accountLabel} ${accountNumber}`;
        let existingAccountName = _.find(client.accounts, { nickname: newAccountName });

        // if the account name is already in use, keep increasing the number till it is unique
        while (existingAccountName) {
            newAccountName = `${accountLabel} ${++accountNumber}`;
            existingAccountName = _.find(client.accounts, { nickname: newAccountName });
        }

        return newAccountName;
    };

    private checkIfNicknameExists = (name: string, accountIndex: number) => {
        const { client } = this.props;

        // check if there is any account using the same name except the account itself
        return client.accounts.some((a, index) => a.nickname === name && accountIndex !== index);
    };

    private buildTableRow = (name: string, index: number, remove: (index: number) => void) => {
        const { client, clientIndex, validateAccountValue, changeFormValue } = this.props;

        if (!client.accounts[index]) {
            return null;
        }

        const accountNickname = client.accounts[index].nickname;
        const accountHasMpipHoldings = client.accounts[index].holdings.length > 0 && 
        client.accounts[index].holdings.some(holding => holding.holdingProductType === 'mpip');

        return (
            <tr key={index}>
                <td>
                    <FieldTextRequired
                        name={`${name}.nickname`}
                        validator={(value: string) => this.checkIfNicknameExists(value, index)
                            ? t('errorMessages.accountNameExistsError')
                            : undefined
                        }
                        hiddenLabelKey='proposalForm.accounts.nickname'
                        maxCharacters={60}
                    />
                </td>
                <td>
                    <label className='sr-only' htmlFor={`${name}.accountType`}>
                        {t('proposalForm.accounts.type')}
                    </label>
                    <Field
                        id={`${name}.accountType`}
                        name={`${name}.accountType`}
                        component="select"
                    >
                        {accountTypeList.map(type => (
                            <option key={type} value={type}>
                                {t(`proposalForm.accountTypes.${type.toLowerCase()}`)}
                            </option>
                        ))}
                    </Field>
                </td>
                <td>
                    <FieldCurrencyRequired
                        name={`${name}.amount`}
                        validator={(value: number) => {
                            const validation = validateAccountValue(value, false);
                            return accountHasMpipHoldings ? (validation.isValid
                                ? undefined
                                : t({
                                    key: 'errorMessages.accountMinimumNotMet',
                                    values: { value: t(validation.minimumAmount, 'cur') },
                                })
                            ) : undefined
                        }}
                        hiddenLabelKey='proposalForm.accounts.value'
                    />

                    {/* Update client's total amount when any of their account amounts change */}
                    <WhenFieldChanges
                        field={`${name}.amount`}
                        becomes={client.accounts[index].amount}
                        set={`clients[${clientIndex}].amount`}
                        to={_.sum(_.map(client.accounts, 'amount'))}
                    />
                </td>
                <td className='align-middle'>
                    <Button
                        type='button'
                        variant="icon"
                        onClick={() => {
                            changeFormValue(
                                `clients[${clientIndex}].amount`,
                                client.amount - client.accounts[index].amount,
                            );
                            remove(index);
                        }}
                    >
                        <span className="icon icon--delete" aria-hidden="true"/>
                        <span className="sr-only">
                            {t({
                                key: 'proposalForm.alt.deleteAccountIcon',
                                values: { accountName: accountNickname },
                            })}
                        </span>
                    </Button>
                </td>
            </tr>
        );
    }

    render() {
        const { clientIndex, client, investmentType } = this.props;
        const accountsTotal = _.sum(_.map(client.accounts, 'amount'));

        return (
            <div css={accountSectionStyles}>
                <h2 className='heading'>
                    {t(`proposalForm.accounts.heading${investmentType}`)}
                </h2>

                {/* Use the client's index to access correct client */}
                <FieldArray name={`clients[${clientIndex}].accounts`}>
                    {({ fields }) => (
                        <div className='table-container'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>{t('proposalForm.accounts.nickname')}</th>
                                        <th>{t('proposalForm.accounts.type')}</th>
                                        <th>{t('proposalForm.accounts.value')}</th>
                                        <td />
                                    </tr>
                                </thead>

                                <tbody>
                                    {fields.map((name, index) => this.buildTableRow(
                                        name, index, fields.remove
                                    ))}

                                    {fields.length === 0
                                        ? (
                                            <tr>
                                                <td colSpan={4}>
                                                    {t('proposalForm.accounts.noAccountsMessage')}
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan={2} />
                                                <td className='left'>
                                                    <span className='account-total'>
                                                        {t('application.total')}: {t(
                                                            accountsTotal || 0, 'cur'
                                                        )}
                                                    </span>
                                                </td>
                                                <td />
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>

                            <div className='footer'>
                                <Button
                                    variant="primary"
                                    type='button'
                                    disabled={client.accounts.length >= MAX_NUMBER_OF_ACCOUNTS}
                                    onClick={() => fields.push({
                                        ...defaultAccount,
                                        nickname: this.generateAccountName(fields.length || 0),
                                    })}
                                >
                                    {t('proposalForm.accounts.addAccount')}
                                </Button>

                                {client.accounts.length >= MAX_NUMBER_OF_ACCOUNTS &&
                                    <div className='error-message'>
                                        {t('errorMessages.maxAccountsError')}
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                </FieldArray>
            </div>
        );
    }
}
